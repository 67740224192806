<template>
	<div class="gallery-wrapper">
		<div
			class="gallery"
			:class="[classes.gallery, galleryClasses]"
		>
			<template v-if="hasRows || showCreateButton">
				<div
					v-if="showCreateButton"
					class="gallery-item"
				>
					<a
						class="item-button"
						@click="onCreateButtonClick"
					>
						<div class="button-inner">
							<FontAwesomeIcon
								:icon="['fal', 'plus-circle']"
								class="button-icon"
							/>
							<p>{{ createButtonLabel }}</p>
						</div>
					</a>
				</div>
				<div
					v-for="item in transformedRows"
					:key="item.id"
					v-click-pulse
					class="gallery-item"
					:class="{ 'is-selected': isSelected(item) }"
					@click="onGalleryItemClick(item)"
				>
					<div class="item-thumbnail">
						<video
							v-if="isVideoFile(item)"
							class="image"
						>
							<source :src="item.image">
						</video>
						<ProgressiveImage
							v-if="isImageFile(item)"
							:src="item.image"
							class="image"
						/>
					</div>
					<p class="item-title">
						{{ item.title }}
					</p>
					<p class="item-subtitle">
						{{ item.subtitle }}
					</p>
				</div>
			</template>
			<template v-else>
				{{ $t('gallery.noItemsAvailable') }}
			</template>
		</div>
		<div
			class="gallery-footer"
			:class="classes.pagination"
		>
			<div class="footer-item">
				<AppSelect
					v-model="serverParams.take"
					size="small"
					color="white"
					:label="{
						name: this.$t('input.label.perPage'),
						position: 'left',
						style: 'none'
					}"
					:width="70"
					:options="itemsPerPageOptions"
					:append-to-body="true"
					:calculate-position="withPopper"
					@input="onPerPageChange"
				/>
			</div>
			<TablePagination
				:pagination="serverPagination"
				@change-page="onChangePage"
			/>
		</div>
	</div>
</template>

<script>
import ApiToDataMixin from '@/mixins/ApiToDataMixin';
import TablePagination from '@/components/TablePagination.vue';
import ProgressiveImage from '@/components/ProgressiveImage.vue';
import i18n from '@/plugins/i18n';
import { getFileTypeByURL } from '@/utilities/Helpers';

export default {
	name: 'CreativeGallery',

	components: {
		TablePagination,
		ProgressiveImage,
	},

	mixins: [ApiToDataMixin],

	props: {
		value: {
			type: Array,
			default: () => [],
		},
		items: {
			type: Array,
			default: () => [],
		},
		showCreateButton: {
			type: Boolean,
			default: true,
		},
		createButtonLabel: {
			type: String,
			default: i18n.t('gallery.createButtonLabel'),
		},
		transform: {
			type: Function,
			default: (items) => items,
		},
		classes: {
			type: Object,
			default: () => ({ gallery: '', pagination: '' }),
		},
		selectable: {
			type: [Boolean, Number],
			default: false,
		},
	},

	computed: {
		transformedRows() {
			return this.transform(this.rows);
		},
		isSelectable() {
			return !!this.selectable;
		},
		selectedItems: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		galleryClasses() {
			return {
				'has-items-selected': this.selectedItems.length > 0,
			};
		},
	},

	watch: {
		selectedItems(value) {
			this.$emit('input', value);
		},
	},

	async mounted() {
		this.serverParams.take = this.perPage;

		if (this.showCreateButton) {
			this.itemsPerPageOptions = this.itemsPerPageOptions.map((option) => (option - 1));
		}

		await this.loadData();
	},

	methods: {
		onCreateButtonClick() {
			this.$emit('create-button-click');
		},
		onGalleryItemClick(item) {
			if (this.selectable) {
				this.toggleItemSelection(item);
			}

			this.$emit('item-clicked', item);
		},
		toggleItemSelection(item) {
			const selectedIndex = this.selectedItems.findIndex((selectedItem) => selectedItem[this.trackBy] === item[this.trackBy]);

			if (selectedIndex !== -1) {
				this.selectedItems.splice(selectedIndex, 1);
				return;
			}

			if (this.selectable === true) {
				this.selectedItems.push(item);
				return;
			}

			if (this.selectable === 1) {
				this.selectedItems = [item];
				return;
			}

			if (this.selectedItems.length < this.selectable) {
				this.selectedItems.push(item);
			}
		},
		isSelected(item) {
			const selectedIndex = this.selectedItems.findIndex((selectedItem) => selectedItem[this.trackBy] === item[this.trackBy]);
			return selectedIndex !== -1;
		},

		isImageFile(item) {
			return getFileTypeByURL(item.image) === 'image';
		},

		isVideoFile(item) {
			return getFileTypeByURL(item.image) === 'video';
		},
	},
};
</script>

<style lang="scss" scoped>
.gallery-footer {
	display: flex;
	justify-content: flex-end;

	.footer-item {
		color: color('gray', 500);

		&.has-no-padding {
			padding-top: 0;
			padding-bottom: 0;
		}

		&.is-fullwidth {
			width: 100%;
		}

		&.is-centered {
			display: flex;
			justify-content: center;
		}

		&:not(:last-child) {
			margin-right: $gap-25;
		}
	}

	&.has-gap {
		border-top: 1px solid color('gray', 100);
		margin-top: $gap-3;
		padding-top: $gap-2;
	}
}

.gallery {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: $gap-4;

	.gallery-item {
		cursor: pointer;

		.item-thumbnail {
			position: relative;
			border: 1px solid color('gray', 200);
			border-radius: $border-radius;
			overflow: hidden;
			transition: $transition-default;

			.image {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			&:after {
				content: "";
				display: block;
				padding-bottom: 100%;
			}

			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				border: 2px solid color('primary', 400);
				position: absolute;
				pointer-events: none;
				border-radius: $border-radius;
				z-index: 2;
				opacity: 0;
				transition: $transition-default;
			}
		}

		.item-title {
			color: color('gray', 700);
			margin-top: $gap-1;
			font-weight: 600;
		}

		.item-subtitle {
			color: color('gray', 500);
			font-size: $size-8;
			font-weight: 600;
		}

		.item-button {
			display: block;
			position: relative;
			border: 1px solid color('gray', 200);
			border-radius: $border-radius;
			background-color: color('gray', 50);
			color: color('gray', 500);
			font-weight: 600;
			font-size: $size-8;
			transition: $transition-default;
			cursor: pointer;

			.button-inner {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			.button-icon {
				color: color('gray', 300);
				font-size: 40px;

				&:not(:last-child) {
					margin-bottom: $gap-15;
				}
			}

			&:after {
				content: "";
				display: block;
				padding-bottom: 100%;
			}

			&:hover {
				box-shadow: $shadow-box-xnarrow;
			}
		}

		&:hover {
			.item-thumbnail {
				box-shadow: $shadow-box-xnarrow;
			}
		}

		&.is-selected .item-thumbnail:before {
			opacity: 1;
		}
	}

	&.has-items-selected {
		.gallery-item:not(.is-selected) .item-thumbnail {
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}
		}
	}
}
</style>
