<template>
	<div id="app">
		<transition
			name="fade"
			mode="out-in"
		>
			<component :is="layout">
				<router-view />
			</component>
		</transition>
		<ModalWrapper />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import NProgress from 'nprogress';
import axios from 'axios';
import store from '@/store';
import router from '@/router';

axios.interceptors.request.use((config) => {
	if (config.params.timestamp) {
		store.dispatch('load/addLoadingSegment', config.params.timestamp);
	}
	return config;
});

axios.interceptors.response.use(
	(response) => {
		if (response.config.params.timestamp) {
			store.dispatch('load/removeLoadingSegment', response.config.params.timestamp);
		}
		return response;
	},
	(error) => {
		if (error.config.params.timestamp) {
			store.dispatch('load/removeLoadingSegment', error.config.params.timestamp);
		}
		if (error.response.status === 403 && store.getters['auth/isAuthenticated']) {
			store.dispatch('auth/logoutUser');
			router.push({ name: 'login' });
		}
		return error;
	},
);

export default {
	computed: {
		...mapGetters(['layout']),
	},

	created() {
		NProgress.configure({
			speed: 300,
			trickleSpeed: 200,
			minimum: 0.1,
			showSpinner: false,
		});
	},
};
</script>

<style lang="scss">
@import "@sunlabde/sunlab-ui/base.scss";
@import "@/scss/base.scss";
@import "~nprogress/nprogress.css";

#nprogress {
	.bar {
		background: color('primary');
		box-shadow: 0 0 12px color('primary');
	}

	.peg,
	.spinner {
		display: none;
	}
}
</style>
