<template>
	<transition name="fade">
		<div
			v-if="show"
			:class="{ 'loader-wrapper': wrapper }"
			:style="{ 'background-color': backgroundColor ? backgroundColor : '' }"
		>
			<div
				:class="{ 'is-success': isSuccess }"
				class="loader"
			>
				<svg
					class="circular-loader"
					viewBox="0 0 42 42"
				>
					<g class="loader-group">
						<circle
							class="loader-background"
							cx="21"
							cy="21"
							r="20"
							fill="none"
							stroke-width="2"
						/>
						<circle
							class="loader-path"
							cx="21"
							cy="21"
							r="20"
							fill="none"
							stroke-width="2"
						/>
						<path
							class="loader-check"
							d="M951,540.91l5.37,5.44L969,533.65"
							transform="translate(-939 -519)"
							fill="none"
						/>
					</g>
				</svg>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'LoaderCheckmark',
	props: {
		isSuccess: {
			type: Boolean,
			required: false,
		},
		wrapper: {
			type: Boolean,
			required: false,
		},
		backgroundColor: {
			type: String,
			required: false,
			default: 'rgba(255,255,255,0.8)',
		},
		threshold: {
			type: Number,
			required: false,
			default: 0,
		},
	},

	data() {
		return {
			show: false,
			timeout: null,
		};
	},

	mounted() {
		this.timeout = setTimeout(() => {
			this.show = true;
		}, this.threshold);
	},

	beforeDestroy() {
		clearTimeout(this.timeout);
	},
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
}

.loader {
	position: relative;
	margin: 0 auto;
	width: 40px;
	height: 40px;

	&.is-success {
		.loader-group {
			animation: none;
		}

		.loader-path {
			animation: path-fill cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
		}

		.loader-background {
			animation: fill 0.1s ease-in-out 0.8s forwards;
		}

		.loader-check {
			display: block;
		}

		.loader-logo {
			opacity: 0;
		}
	}
}

.loader-check {
	display: none;
	stroke: $color-white;
	stroke-width: 2;
}

.loader-group {
	animation: rotate 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
}

.loader-path {
	stroke-dasharray: 150, 200;
	stroke-dashoffset: -10;
	animation: dash 1.5s ease-in-out infinite;
	stroke-linecap: round;
	stroke: color('primary');
}

.loader-background {
	stroke: color('gray', 300);
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}

.loader-check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes fill {
	100% {
		fill: color('primary');
		stroke: color('primary');
	}
}

@keyframes path-fill {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
		stroke-dasharray: 150, 200;
		stroke: color('primary');
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}
</style>
