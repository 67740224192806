<template>
	<span
		v-if="creativeType"
		class="tag tag--gray"
	>
		<InlineSvg
			v-if="icon"
			:src="icon"
			class="item-icon"
			width="18"
			height="18"
		/>
		{{ label }}
	</span>
</template>

<script>
import CreativeTypes from '@/utilities/CreativeTypes';

export default {
	name: 'CreativeTypeTag',

	props: {
		type: {
			type: String,
			required: false,
			default: 'n/a',
		},
	},

	computed: {
		creativeType() {
			return CreativeTypes[this.type] || null;
		},
		label() {
			return this.creativeType.label;
		},
		icon() {
			return this.creativeType.icon;
		},
	},
};
</script>

<style lang="scss" scoped>
.item-icon {
	margin-right: $gap-1;
}
</style>
