<template>
	<AppModal
		ref="modal"
		size="wide"
		fixed
		:before-cancel="beforeCancel"
		@afterClose="onAfterClose"
	>
		<template #title>
			{{ $t('modal.selectStores.title') }}
		</template>
		<template #body>
			<div class="columns is-vcentered">
				<div class="column">
					{{ $t('amountSelected', [selectedCount]) }}
				</div>
				<div class="column is-flex">
					<AppInput
						v-model="searchValue"
						class="is-pulled-right"
						:placeholder="$t('input.label.searchStoresByParameters')"
						:width="350"
						:icon-after="{
							src: ['far', 'search']
						}"
						@input="loadTableData(500)"
					/>
				</div>
			</div>
			<AppTable
				ref="apptable"
				v-model="selectedStores"
				:columns="tableColumns"
				:api-path="ENDPOINTS.LocationImport"
				data-path="Objects"
				pagination-path="Pagination"
				:append-params="appendParams"
				api-caller="dwh"
				:show-pagination="true"
				:selectable="true"
				track-by="Id"
			/>
		</template>
		<template #footer>
			<div class="buttons is-row is-right">
				<SunButton
					width="condensed"
					appearance="borderless"
					color="gray"
					@click="cancel"
				>
					{{ $t('button.cancel') }}
				</SunButton>
				<SunButton
					width="condensed"
					@click="saveSelection"
				>
					{{ $t('button.confirmSelection') }}
				</SunButton>
			</div>
		</template>
	</AppModal>
</template>

<script>
import CustomModalMixin from '@/plugins/CustomModals/mixin';
import DetailedStoresColumns from '@/utilities/DetailedStoresColumns';
import ModalConfirm from '@/components/ModalConfirm.vue';
import { mapActions } from 'vuex';
import ENDPOINTS from '@/utilities/Endpoints';

export default {
	name: 'ModalSelectStores',

	mixins: [CustomModalMixin],

	props: {
		value: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			tableColumns: DetailedStoresColumns,
			selectedStores: [],
			searchValue: '',
			ENDPOINTS,
		};
	},

	computed: {
		selectedCount() {
			return this.selectedStores.length;
		},

		appendParams() {
			const appendParams = {};

			if (this.searchValue) appendParams.predicates = `Name=%${this.searchValue}% or City=%${this.searchValue}% or zip=${this.searchValue}% or region=${this.searchValue}%`;

			return appendParams;
		},

		hasChanges() {
			return this.selectedStores.some((selectedStore) => !this.value.includes(selectedStore));
		},
	},

	mounted() {
		this.selectedStores = [...this.value];
	},

	methods: {
		...mapActions('products', [
			'fetchProductCategories',
		]),
		removeItem(item) {
			this.$refs.apptable.unselectRow(item);
		},
		removeAllItems() {
			this.$refs.apptable.unselectAllRows();
		},
		beforeCancel() {
			if (this.hasChanges) {
				return new Promise((resolve) => {
					this.$modal({
						component: ModalConfirm,
						props: {
							title: this.$t('modal.selectStores.cancelConfirmModal.title'),
							text: this.$t('modal.selectStores.cancelConfirmModal.text'),
							onConfirm: () => resolve(true),
							onCancel: () => resolve(false),
						},
					});
				});
			}

			return true;
		},
		loadTableData(timeout = 0) {
			clearTimeout(this.loadDataTimeout);

			this.loadDataTimeout = setTimeout(() => {
				this.$refs.apptable.reloadData();
			}, timeout);
		},
		saveSelection() {
			this.$emit('success', this.selectedStores);
			this.$refs.modal.close();
		},
	},
};
</script>

<style lang="scss" scoped>
.selected-list {
	.list-item {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: $gap-25;
		}
	}

	.item-remove {
		cursor: pointer;
		margin-left: auto;
	}
}

.buttons {
	width: 100%;
}
</style>
