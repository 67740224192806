<template>
	<div class="progressive-image-wrapper">
		<transition
			name="fade"
			@after-enter="onAnimationEnd"
		>
			<img
				v-show="loaded"
				class="image"
				:src="src"
				@load="onLoad"
			>
		</transition>
		<div
			v-if="!animationEnded"
			class="loader"
		/>
	</div>
</template>

<script>
export default {
	name: 'ProgressiveImage',

	props: {
		src: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			loaded: false,
			animationEnded: false,
		};
	},

	mounted() {
	},

	methods: {
		onLoad() {
			this.loaded = true;
		},
		onAnimationEnd() {
			this.animationEnded = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.progressive-image-wrapper {
	position: relative;
	overflow: hidden;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.loader {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: color('gray', 100);
	left: 0;

	&:after {
		content: "";
		display: block;
		position: absolute;
		left: -150px;
		top: 0;
		height: 100%;
		width: 150px;
		background: linear-gradient(
			to right,
			transparent 0%,
			#ffffff 50%,
			transparent 100%
		);
		animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
	}
}

@keyframes load {
	from {
		left: -150px;
	}

	to {
		left: 100%;
	}
}
</style>
