import axios from 'axios';
import CallAPI from '@/plugins/CallAPI';

const AUTH_TOKEN_NAME = 'LUPAuthToken';

export default {
	namespaced: true,
	state: {
		token: localStorage.getItem(AUTH_TOKEN_NAME) || '',
		status: '',
	},
	mutations: {
		REQUEST_AUTHENTICATION(state) {
			state.status = 'loading';
		},
		AUTH_SUCCESS(state, payload) {
			state.status = 'success';
			state.token = payload;
		},
		AUTH_ERROR(state) {
			state.status = 'error';
			localStorage.removeItem(AUTH_TOKEN_NAME);
			state.token = '';
		},
		AUTH_LOGOUT(state) {
			state.status = 'loggedout';
			localStorage.removeItem(AUTH_TOKEN_NAME);
			state.token = '';
		},
	},
	actions: {
		requestPasswordReset(context, { email }) {
			return CallAPI.callX2X({
				method: 'post',
				path: '/user/forgotpassword',
				data: {
					email,
				},
			});
		},
		async validatePasswordToken(context, token) {
			const response = await CallAPI.callX2X({
				method: 'post',
				path: '/user/validatetoken',
				data: {
					token,
				},
			});

			return response.data;
		},
		resetPassword(context, { token, password }) {
			return CallAPI.callX2X({
				method: 'post',
				path: '/user/genratepassword',
				data: {
					token,
					password,
				},
			});
		},

		async requestAuthentication({ commit }, { userName, password, saveToken }) {
			try {
				commit('REQUEST_AUTHENTICATION');

				const response = await CallAPI.callX2X({
					method: 'POST',
					path: '/login',
					data: {
						userName,
						password,
					},
				});

				const { AccessToken } = response.data.Objects[0];

				axios.defaults.headers.user_token = AccessToken;

				commit('AUTH_SUCCESS', AccessToken);

				if (saveToken) {
					localStorage.setItem(AUTH_TOKEN_NAME, AccessToken);
				}
			} catch (error) {
				commit('AUTH_ERROR', error);
				throw error;
			}
		},

		async logoutUser({ dispatch }) {
			return dispatch('removeAuthentication');
		},
		removeAuthentication(context) {
			axios.defaults.headers.user_token = '';
			context.commit('AUTH_LOGOUT');
		},
		initAuthentication({ state }) {
			const { token } = state;

			if (token) {
				axios.defaults.headers.user_token = token;
			}
		},
	},
	getters: {
		isAuthenticated: (state) => !!state.token,
		authStatus: (state) => state.status,
	},
};
