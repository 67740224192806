<template>
	<div class="section-bottom">
		<div class="buttons is-row">
			<SunButton
				width="condensed"
				:state="{ disabled: !valid }"
				@click="submit"
			>
				<template v-if="isOnLastStep">
					{{ $t('button.seeCampaignPreview') }}
				</template>
				<template v-else>
					{{ $t('button.nextStep') }}
				</template>
			</SunButton>
			<SunButton
				width="condensed"
				appearance="borderless"
				color="gray"
				@click="cancel"
			>
				{{ $t('button.cancel') }}
			</SunButton>
		</div>
		<slot />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'BookCampaignFooter',

	props: {
		valid: {
			type: Boolean,
			default: false,
		},
		step: {
			type: Object,
			required: true,
		},
	},

	computed: {
		...mapGetters('book', [
			'currentStepCount',
			'steps',
		]),
		stepCount() {
			const index = this.steps.findIndex((step) => step === this.step);
			return index + 1;
		},
		isOnLastStep() {
			return this.stepCount === this.steps.length;
		},
	},

	methods: {
		submit() {
			this.$emit('submit', { showPreview: this.isOnLastStep });
		},
		cancel() {
			this.$emit('cancel');
		},
	},
};
</script>

<style lang="scss" scoped>
.section-bottom {
	margin-top: $gap-4;
}
</style>
