<template>
	<div class="table-filter">
		<div class="filter-list">
			<div class="list-item">
				<p class="filter-title">
					Filter by:
				</p>
			</div>
			<div
				v-for="(filter, $index) in filters"
				:key="$index"
				class="list-item"
			>
				<component
					:is="mapTypeToComponent(filter.type)"
					v-model="filter.value"
					size="medium"
					:width="180"
					v-bind="mapBindings(filter)"
					@input="onFilterInput"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TableFilter',

	props: {
		filters: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			inputTimeout: null,
		};
	},

	methods: {
		mapTypeToComponent(type) {
			switch (type) {
				case 'toggle':
					return 'AppToggle';
				case 'select':
					return 'AppSelect';
				default:
					return 'AppInput';
			}
		},
		mapBindings(filter) {
			if (filter.type === 'toggle') {
				return {
					label: filter.label,
				};
			}

			if (filter.type === 'select') {
				return {
					placeholder: filter.label,
					options: filter.options,
					clearable: true,
					searchable: true,
				};
			}

			return {
				placeholder: filter.label,
			};
		},
		onFilterInput() {
			clearTimeout(this.inputTimeout);

			this.inputTimeout = setTimeout(() => {
				this.$emit('filter-change');
			}, 300);
		},
	},
};
</script>

<style lang="scss" scoped>
.table-filter {
	height: 64px;
	background-color: color('gray', 200);
	border-top: 1px solid color('gray', 400);
	border-left: 1px solid color('gray', 400);
	border-right: 1px solid color('gray', 400);
	flex-shrink: 0;
	padding-left: $gap-4;
	display: flex;
	align-items: center;

	.filter-title {
		font-weight: 600;
		font-size: $size-7;
	}
}

.filter-list {
	display: flex;
	align-items: center;

	.list-item:not(:first-child) {
		margin-left: $gap-3;
	}
}
</style>
