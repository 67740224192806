<template>
	<span
		class="tag"
		:class="classObject"
	>
		{{ label }}
	</span>
</template>

<script>
import { getCampaignState, campaignState, stateLabelMap } from '@/utilities/CampaignStates';

export default {
	name: 'CampaignStatusTag',

	props: {
		data: {
			type: Object,
			default: null,
		},
	},

	computed: {
		state() {
			return getCampaignState(this.data);
		},
		classObject() {
			const colorClass = () => {
				switch (this.state) {
					case campaignState.ACTIVE:
						return 'tag--positive';
					case campaignState.PAUSED:
						return 'tag--negative';
					case campaignState.PENDING:
						return 'tag--info';
					default:
						return 'tag--gray';
				}
			};

			return [
				colorClass(),
			];
		},
		label() {
			return stateLabelMap[this.state];
		},
	},
};
</script>

<style>

</style>
