<template>
	<ValidationObserver
		ref="observer"
		v-slot="{ valid }"
		tag="div"
		class="box-section is-flex is-column is-gapless is-fullheight"
	>
		<BookCampaignHeader :step="step" />
		<div class="section-middle">
			<div class="input-fields">
				<AppSelect
					v-model="form.adPlatform"
					option-value="id"
					option-label="name"
					:label="$t('input.label.adPlatform')"
					:options="adPlatformOptions"
					required
				/>
				<div class="input-fields is-row">
					<AppSelect
						v-if="featureFlags.CAMPAIGN_NAME_PREFIX"
						v-model="form.NamePrefix"
						:label="$t('input.label.campaignNamePrefix')"
						:options="campaignNamePrefixOptions"
						required
						:width="150"
					/>
					<AppInput
						v-model="form.Name"
						:label="$t('input.label.campaignName')"
						:rules="{ required: true }"
					/>
				</div>
				<AppInput
					v-model="form.Budget"
					:label="$t('input.label.budgetPerStore')"
					type="number"
					:rules="{ required: true }"
				/>
				<StoresSelection
					v-model="form.stores"
					required
				/>
				<div class="input-fields is-row">
					<DatePicker
						v-model="dateRange"
						:label="$t('input.label.campaignDateRange')"
						:min-date="new Date()"
						mode="range"
						required
					/>
				</div>
				<AppCheckbox v-model="form.UseOpeningHours">
					{{ $t('input.label.openingHoursOnly') }}
				</AppCheckbox>
			</div>
		</div>
		<BookCampaignFooter
			:step="step"
			:valid="valid"
			v-on="$listeners"
		/>
	</ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from '@/components/DatePicker.vue';
import ENDPOINTS from '@/utilities/Endpoints';
import CampaignBookStepsMixin from '@/mixins/CampaignBookStepsMixin';
import StoresSelection from '@/components/StoresSelection.vue';
import creativeTypes from '@/utilities/CreativeTypes';
import CallAPI from '@/plugins/CallAPI';

export default {
	name: 'GeneralInfo',

	components: {
		DatePicker,
		StoresSelection,
	},

	mixins: [CampaignBookStepsMixin],

	data() {
		return {
			form: {
				NamePrefix: '',
				Name: '',
				Budget: 0,
				adPlatform: '',
				stores: [],
				StartDate: '',
				EndDate: '',
				UseOpeningHours: false,
			},
			adPlatformOptions: [],
			campaignNamePrefixOptions: [
				'Prospekt',
				'Stellenanzeige',
				'Individuell',
				'Standard',
			],
			storesOptions: [],
		};
	},

	computed: {
		...mapGetters('user', [
			'featureFlags',
		]),
		...mapGetters('book', [
			'campaign',
		]),
		dateRange: {
			get() {
				return {
					start: this.form.StartDate,
					end: this.form.EndDate,
				};
			},
			set(dateRange) {
				this.form.StartDate = dateRange.start;
				this.form.EndDate = dateRange.end;
			},
		},
	},

	watch: {
		form: {
			deep: true,
			async handler(form) {
				this.updateCampaign(form);
			},
		},
	},

	mounted() {
		this.adPlatformOptions = Object.values(creativeTypes).map((creativeType) => ({ id: creativeType.id, name: creativeType.label }));
		this.syncForm();
		this.fetchStores();
	},

	methods: {
		...mapActions('book', [
			'setAdPlatform',
			'updateCampaign',
		]),
		syncForm() {
			Object.keys(this.form).forEach((key) => {
				if (Object.prototype.hasOwnProperty.call(this.campaign, key)) {
					this.form[key] = this.campaign[key];
				}
			});
		},
		async fetchStores() {
			const response = await CallAPI.callDWH({
				method: 'GET',
				path: ENDPOINTS.LocationImport,
			});

			this.storesOptions = response.data.Objects;
		},
	},
};
</script>

<style lang="scss" scoped>
.section-middle {
	max-width: 570px;
}

.store-item {
	.button {
		margin-left: $gap-1;
	}
}

.store-selection-icon {
	width: 16px;
}
</style>
