<template>
	<div
		class="icon-wrapper"
		:class="classObject"
	>
		<div
			class="icon-box"
			:style="{ 'background-color': isHexColor ? color : '' }"
		>
			<FontAwesomeIcon
				:icon="icon"
				class="icon"
			/>
		</div>
		<div class="icon-label">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: 'AppIcon',

	props: {
		icon: {
			type: [String, Array],
			required: true,
		},
		color: {
			type: String,
			required: false,
			default: 'primary',
		},
		size: {
			type: String,
			required: false,
			default: 'medium',
			validator: (value) => ['small', 'medium', 'large'].includes(value),
		},
	},

	computed: {
		isHexColor() {
			return this.color[0] === '#';
		},
		classObject() {
			const colorClass = this.isHexColor ? '' : `is-${this.color}`;
			const sizeClass = `is-${this.size}`;

			return [
				colorClass,
				sizeClass,
			];
		},
	},
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
	display: flex;
	align-items: center;

	&.is-small {
		.icon-label {
			font-size: $size-8;
			font-weight: 600;
		}

		.icon-box {
			font-size: $size-9;
			width: 24px;
			height: 24px;
		}
	}

	&.is-medium .icon-box {
		width: 40px;
		height: 40px;
		font-size: $size-6;
	}

	&.is-large {
		.icon-label {
			font-size: $size-4;
			font-weight: 600;
		}

		.icon-box {
			font-size: $size-5;
			width: 40px;
			height: 40px;
		}
	}

	&.is-primary .icon-box {
		background-color: color('primary', 400);
	}

	&.is-secondary .icon-box {
		background-color: color('secondary', 400);
	}

	&.is-tertiary .icon-box {
		background-color: color('tertiary', 300);
	}

	&.is-quaternary .icon-box {
		background-color: color('quaternary', 500);
	}
}

.icon-label {
	display: flex;
	align-items: center;
	font-weight: 600;
}

.icon-box {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius;
	margin-right: $gap-15;
	color: $color-white;
}
</style>
