<template>
	<div class="field-wrapper">
		<CampaignStatusTag
			v-placeholder-loader="isPlaceholder"
			:data="row"
		/>
	</div>
</template>

<script>
import CampaignStatusTag from '@/components/CampaignStatusTag.vue';
import TableFieldMixin from '@/mixins/TableFieldMixin';

export default {
	name: 'TableFieldStatusTag',

	components: {
		CampaignStatusTag,
	},

	mixins: [TableFieldMixin],
};
</script>

<style lang="scss" scoped>
.field-wrapper {
	display: flex;
	align-items: center;
}

.warning {
	margin-left: $gap-1;

	.warning-icon {
		color: color("senary", 800)
	}
}
</style>
