import Vue from 'vue';
import ModalNewStore from '@/components/ModalNewStore.vue';
import i18n from '@/plugins/i18n';

function openCreateStoreModal({
	store = null,
	onSuccess = () => { },
	onError = () => { },
	showSuccessNotification = true,
}) {
	Vue.modal({
		component: ModalNewStore,
		props: {
			store,
		},
		events: {
			success: (newStore) => {
				onSuccess(newStore);

				if (showSuccessNotification) {
					if (store) {
						Vue.notify({
							type: 'success',
							text: i18n.t('notification.success.updatedStore'),
						});
					} else {
						Vue.notify({
							type: 'success',
							text: i18n.t('notification.success.addedNewStore'),
						});
					}
				}
			},
			error: onError,
		},
	});
}

export default {
	methods: {
		$_openCreateStoreModal: openCreateStoreModal,
	},
};
