<template>
	<div class="section-top">
		<p class="box-title">
			{{ stepCount }}. {{ step.title }}
		</p>
		<slot />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'BookCampaignHeader',

	props: {
		step: {
			type: Object,
			required: true,
		},
	},

	computed: {
		...mapGetters('book', [
			'steps',
		]),
		stepCount() {
			const index = this.steps.findIndex((step) => step === this.step);
			return index + 1;
		},
	},
};
</script>

<style lang="scss" scoped>
.section-top {
	display: flex;
}
</style>
