<template>
	<div
		:class="notification.type"
		:data-cy="`${cypressPrefix}_box`"
		class="notification-box"
		@click="close"
	>
		<div class="box-icon">
			<FontAwesomeIcon :icon="notificationIcon" />
		</div>
		<div class="box-body">
			<p>
				<span class="box-title">
					{{ notification.title }}
				</span>
				<span
					v-if="notification.text"
					:data-cy="`${cypressPrefix}_text`"
					v-html="notification.text"
				/>
				<slot />
			</p>
		</div>
		<div class="box-actions">
			<template v-if="notification.data">
				<a
					v-for="(action, index) in notification.data.actions"
					:key="index"
					class="box-action"
					@click="onClick(action)"
				>
					{{ action.text }}
				</a>
			</template>
			<a
				v-if="closeable"
				class="close"
				@click="close"
			>
				<FontAwesomeIcon :icon="['fal', 'times']" />
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NotificationItem',

	props: {
		cypressPrefix: {
			type: String,
			default: 'notification',
		},
		notification: {
			type: Object,
			default: () => ({}),
		},
		close: {
			type: Function,
			default: () => false,
		},
		closeable: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		notificationIcon() {
			switch (this.notification.type) {
				case 'warning':
					return ['fas', 'exclamation-triangle'];
				case 'success':
				case 'secure':
					return ['fas', 'check-circle'];
				case 'error':
					return ['fas', 'times-circle'];
				default:
					return ['fas', 'info-circle'];
			}
		},
	},

	methods: {
		onClick(actionItem) {
			if (typeof actionItem.onClick === 'function') {
				actionItem.onClick();
				this.close();
			} else {
				this.close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.notification-box {
	position: relative;
	display: flex;
	border-radius: $border-radius;
	padding: $gap-2 $gap-25 $gap-2 $gap-25;
	box-sizing: border-box;
	transition: $transition-default;
	background-color: color("secondary", 400);
	color: $color-white;
	font-size: $size-8;
	text-align: left;
	line-height: 1.5;
	align-items: center;

	.box-title {
		font-weight: 700;
	}

	.box-icon {
		margin-right: $gap-1;
		color: color("secondary", 100);
		font-size: $size-6;
		flex-shrink: 0;
		align-self: flex-start;
	}

	.box-actions {
		display: flex;
		align-items: center;
	}

	.box-action {
		color: color("secondary", 800);
		font-weight: 600;

		&:hover {
			color: color("secondary", 900);
			cursor: pointer;
		}

		&:not(:last-child) {
			margin-right: $gap-15;
		}
	}

	.close {
		line-height: 0;
		color: $color-white;
		opacity: 0.6;

		&:hover {
			cursor: pointer;
			opacity: 1;
		}
	}

	&.error {
		background-color: color("tertiary", 400);

		.box-icon {
			color: color("tertiary", 100);
		}
	}

	&.warning {
		background-color: color("senary", 800);

		.box-icon {
			color: color("senary", 100);
		}
	}

	&.success,
	&.secure {
		background-color: $color-success;

		.box-icon {
			color: color("primary", 100);
		}
	}
}
</style>
