<template>
	<div>
		<div class="input-fields">
			<AppInput
				v-model="form.name"
				:label="$t('input.label.name')"
			/>
			<AppInput
				v-model="form.email"
				:label="$t('input.label.email')"
			/>
			<AppInput
				v-model="form.organization"
				:label="$t('input.label.organization')"
			/>
		</div>
		<div class="buttons is-row">
			<SunButton
				width="condensed"
			>
				{{ $t('button.saveChanges') }}
			</SunButton>
			<SunButton
				width="condensed"
				appearance="borderless"
				color="gray"
			>
				{{ $t('button.cancel') }}
			</SunButton>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FormAccountSettings',

	data() {
		return {
			form: {
				name: '',
				email: '',
				organization: '',
			},
		};
	},
};
</script>

<style>

</style>
