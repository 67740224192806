const displayAdCategories = {
	SQUARE_AND_RECTANGLE: 'Square and rectangle',
	SKYSCRAPER: 'Skyscraper',
	LEADERBOARD: 'Leaderboard',
};

class DisplayAdFormat {
	constructor({
		name, width, height, category,
	}) {
		this.name = name;
		this.width = width;
		this.height = height;
		this.category = category;
	}
}

const supportedDisplayAdFormats = [
	new DisplayAdFormat({
		name: 'Inline rectangle', width: 300, height: 250, category: displayAdCategories.SQUARE_AND_RECTANGLE,
	}),
	new DisplayAdFormat({
		name: 'Small mobile', width: 320, height: 50, category: displayAdCategories.SQUARE_AND_RECTANGLE,
	}),
	new DisplayAdFormat({
		name: 'Large mobile', width: 320, height: 100, category: displayAdCategories.SQUARE_AND_RECTANGLE,
	}),
	new DisplayAdFormat({
		name: 'Square', width: 250, height: 250, category: displayAdCategories.SQUARE_AND_RECTANGLE,
	}),
	new DisplayAdFormat({
		name: 'Small Square', width: 200, height: 200, category: displayAdCategories.SQUARE_AND_RECTANGLE,
	}),
];

export { displayAdCategories, supportedDisplayAdFormats, DisplayAdFormat };
