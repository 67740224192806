<template>
	<ValidationObserver
		ref="observer"
		v-slot="{ invalid }"
		tag="form"
		class="form"
	>
		<div class="input-fields">
			<AppInput
				v-model="form.newPassword"
				vid="confirm"
				:label="$t('input.label.enterDesiredPassword')"
				:rules="{ required: true }"
				mode="aggressive-touch"
				type="password"
			/>
			<AppInput
				v-model="form.confirmNewPassword"
				:label="$t('input.label.confirmDesiredPassword')"
				:rules="{ required: true, confirmed: 'confirm' }"
				mode="aggressive-touch"
				type="password"
			/>
		</div>
		<div class="buttons is-row">
			<SunButton
				width="condensed"
				:state="{ disabled: invalid }"
				@click="changePassword"
			>
				{{ $t('button.changePassword') }}
			</SunButton>
			<SunButton
				width="condensed"
				appearance="borderless"
				color="gray"
			>
				{{ $t('button.cancel') }}
			</SunButton>
		</div>
	</ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions } from 'vuex';

export default {
	name: 'FormChangePassword',

	components: {
		ValidationObserver,
	},

	data() {
		return {
			form: {
				newPassword: '',
				confirmNewPassword: '',
			},
		};
	},

	methods: {
		...mapActions('user', [
			'changePasswordForCurrentUser',
		]),
		async changePassword() {
			try {
				await this.changePasswordForCurrentUser(this.form.confirmNewPassword);
				this.$notify({
					type: 'success',
					text: this.$t('notification.success.passwordChanged'),
				});

				this.form.newPassword = '';
				this.form.confirmNewPassword = '';
				this.$refs.observer.reset();
			} catch (error) {
				this.$notify({
					type: 'error',
					text: this.$t('notification.error.passwordChanged'),
				});
			}
		},
	},
};
</script>

<style>

</style>
