<template>
	<AppModal
		ref="modal"
		size="wide"
		:before-cancel="beforeCancel"
		@afterClose="onAfterClose"
	>
		<template #title>
			{{ modalTitle }}
		</template>
		<template #body>
			<div class="columns">
				<div class="column">
					<ValidationObserver
						ref="observer"
						v-slot="{ valid }"
						tag="form"
						class="form"
						@submit.prevent
					>
						<div class="input-fields">
							<AppInput
								v-model="form.name"
								:label="`1 – ${$t('store.name')}`"
								:rules="{ required: true }"
								:character-count="128"
							/>
							<AppInput
								v-model="form.streetNumber"
								:label="`2 – ${$t('store.streetNumber')}`"
								:rules="{ numeric: true }"
								:character-count="16"
							/>
							<AppInput
								v-model="form.street"
								:label="`3 – ${$t('store.street')}`"
								:rules="{ required: true }"
								:character-count="64"
							/>
							<AppInput
								v-model="form.zip"
								:rules="{ required: true, numeric: true }"
								:label="`4 – ${$t('store.zip')}`"
								:character-count="32"
							/>
							<AppInput
								v-model="form.city"
								:label="`5 – ${$t('store.city')}`"
								:rules="{ required: true, alpha: true }"
								:character-count="64"
							/>
							<AppInput
								v-model="form.region"
								:rules="{alpha: true}"
								:label="`6 – ${$t('store.region')}`"
								:character-count="128"
							/>
							<AppInput
								v-model="form.radius"
								:label="`7 – ${$t('store.radius')}`"
								:rules="{ between: {min: '1',max: '80'} }"
								:character-count="80"
							/>
						</div>
						<div class="buttons is-row is-right is-reverse">
							<SunButton
								v-if="!editMode"
								width="condensed"
								:state="{ disabled: !(valid) }"
								@click="onSubmit"
							>
								{{ $t("button.saveAndUpload") }}
							</SunButton>
							<SunButton
								v-if="editMode"
								width="condensed"
								:state="{ disabled: !(valid && hasChanges) }"
								@click="onSubmit"
							>
								{{ $t("button.saveChanges") }}
							</SunButton>
							<SunButton
								width="condensed"
								appearance="borderless"
								color="gray"
								@click="cancel"
							>
								{{ $t("button.cancel") }}
							</SunButton>
						</div>
					</ValidationObserver>
				</div>
			</div>
		</template>
	</AppModal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ModalConfirm from '@/components/ModalConfirm.vue';
import ModalLoading from '@/components/ModalLoading.vue';
import CustomModalMixin from '@/plugins/CustomModals/mixin';
import ENDPOINTS from '@/utilities/Endpoints';
import { getObjectDiff, withPopper } from '@/utilities/Helpers';
import CallAPI from '../plugins/CallAPI';

export default {
	name: 'ModalNewStore',

	components: {
		ValidationObserver,
	},

	mixins: [CustomModalMixin],

	props: {
		store: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			form: {
				name: '',
				street: '',
				streetNumber: '',
				zip: '',
				city: '',
				region: '',
				radius: '',
			},
			formSnapshot: {},
			uploaded: [],
			editMode: false,
			withPopper,
		};
	},

	computed: {
		modalTitle() {
			if (this.editMode) {
				return this.$t('store.edit.title');
			}

			return this.$t('store.new.title');
		},
		changes() {
			return getObjectDiff(this.form, this.formSnapshot);
		},
		hasChanges() {
			return Object.keys(this.changes).length > 0;
		},
	},

	mounted() {
		if (this.store) {
			this.form.name = this.store.Name || '';
			this.form.street = this.store.Street;
			this.form.streetNumber = this.store.StreetNumber;
			this.form.zip = this.store.Zip;
			this.form.city = this.store.City;
			this.form.region = this.store.Region || '';
			this.form.radius = this.store.Radius || '';
			this.editMode = true;
		}

		this.setFormSnapshot();
	},

	methods: {
		async beforeCancel() {
			return new Promise((resolve) => {
				if (this.hasChanges) {
					this.$modal({
						component: ModalConfirm,
						props: {
							title: this.$t('modal.unsavedChanges.title'),
							text: this.$t('modal.unsavedChanges.text'),
							onConfirm: () => resolve(true),
							onCancel: () => resolve(false),
						},
					});
				} else {
					resolve(true);
				}
			});
		},

		async onSubmit() {
			const loadingModal = this.$modal({
				component: ModalLoading,
				props: {
					title: this.$t('modal.uploadingStore.title'),
					text: this.$t('modal.uploadingStore.text'),
				},
			});

			try {
				const response = await CallAPI.callDWH({
					method: 'POST',
					path: this.store
						? `${ENDPOINTS.LocationImport}/${this.store.Id}`
						: ENDPOINTS.LocationImport,
					data: {
						Name: this.form.name,
						Street: this.form.street,
						StreetNumber: this.form.streetNumber,
						Zip: this.form.zip,
						City: this.form.city,
						Region: this.form.region,
						Radius: this.form.radius,
						Id: this.store ? this.store.id : Date.now(),
						// Hardcoded default value for Facebook Page.
						// Since Edeka is our only client and the app is deprecated no better implementation is required
						FacebookPage: 'https://www.facebook.com/edeka.nst/',
					},
				});
				this.$emit('success', response.data.Objects[0].ObjectData);
			} catch (error) {
				this.$emit('error', { error });
			} finally {
				loadingModal.close();
				this.$refs.modal.close();
			}
		},
		setFormSnapshot() {
			this.formSnapshot = { ...this.form };
		},
	},
};
</script>

<style lang="scss" scoped></style>
