<template>
	<div>
		<AppModal
			ref="modal"
			:allow-backdrop-close="false"
			@afterClose="onAfterClose"
		>
			>
			<template #body>
				<LoaderCheckmark class="has-gap-bottom-15" />
				<p class="modal-title has-text-center">
					{{ title }}
				</p>
				<p class="has-text-center">
					{{ text }}
				</p>
			</template>
		</AppModal>
	</div>
</template>

<script>
import LoaderCheckmark from '@/components/LoaderCheckmark.vue';
import CustomModalMixin from '@/plugins/CustomModals/mixin';

export default {
	name: 'ModalConfirm',

	components: {
		LoaderCheckmark,
	},

	mixins: [CustomModalMixin],

	props: {
		title: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		onConfirm: {
			type: Function,
			required: false,
			default: () => {},
		},
		onCancel: {
			type: Function,
			required: false,
			default: () => {},
		},
	},

	methods: {
		async confirm() {
			try {
				await this.onConfirm();
			} catch (error) {
				this.$notify({
					type: 'error',
					text: error,
				});
			} finally {
				this.$refs.modal.close();
			}
		},
	},
};
</script>

<style>

</style>
