<template>
	<div>
		<div
			v-if="isDevelopment"
			class="development-details tags"
		>
			<span
				class="tag"
				:class="isDevelopment ? 'tag--negative' : 'tag--info'"
			>
				<div class="tag-icon">
					<FontAwesomeIcon :icon="['fas', 'info-circle']" />
				</div>
				<span><span v-if="isDevelopment">Development</span></span>
			</span>
			<a
				v-if="isDevelopment"
				class="tag tag--info"
				:href="`https://github.com/${githubRepository}/commit/${githubSha}`"
				target="_blank"
			>
				{{ githubSha }}
			</a>
			<span
				v-if="isDevelopment"
				class="tag tag--info"
			>
				Build Time: {{ buildTime }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DevelopmentDetails',

	data() {
		return {
			githubSha: process.env.VUE_APP_GITHUB_SHA,
			githubRepository: process.env.VUE_APP_GITHUB_REPOSITORY,
			buildTime: this.$moment(process.env.VUE_APP_BUILD_TIME).format('DD.MM.YYYY HH:mm:ss'),
			isDevelopment: process.env.NODE_ENV === 'development',
		};
	},
};
</script>

<style lang="scss" scoped>
.changelog {
	border-top: 1px solid color('gray', 200);
	margin-top: $gap-2;
	padding-top: $gap-2;
}
</style>
