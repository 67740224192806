import { steps } from '@/utilities/BookCampaignSteps';
import numeral from 'numeral';
import { getObjectDiff } from '@/utilities/Helpers';

const DEFAULT_CAMPAIGN = {
	NamePrefix: '',
	Name: '',
	Budget: 0,
	adPlatform: 'LinkAd',
	stores: [],
	StartDate: '',
	EndDate: '',
	Genders: 'None',
	MinAge: 18,
	MaxAge: 65,
	UseOpeningHours: false,
	CreativeLink_Id: null,
	State: 'None',
};

const getDefaultState = () => ({
	campaign: { ...DEFAULT_CAMPAIGN },
	campaignOrigin: { ...DEFAULT_CAMPAIGN },
	currentStepIndex: 0,
	previousStepIndex: 0,
	furthestStepIndex: 0,
	transitionMode: 'transition-next',
	previewModeState: false,
	steps: steps(),
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_CURRENT_STEP_INDEX(state, payload) {
			state.currentStepIndex = payload;
		},
		SET_FURTHEST_STEP_INDEX(state, payload) {
			state.furthestStepIndex = payload;
		},
		SET_PREVIOUS_STEP_INDEX(state, payload) {
			state.previousStepIndex = payload;
		},
		SET_TRANSITION_MODE(state, payload) {
			state.transitionMode = payload;
		},
		SET_CAMPAIGN(state, campaign) {
			state.campaign = campaign;
		},
		UPDATE_CAMPAIGN(state, properties) {
			state.campaign = { ...state.campaign, ...properties };
		},
		SET_PREVIEW_MODE_STATE(state, previewModeState) {
			state.previewModeState = previewModeState;
		},
	},
	actions: {
		resetBookState({ commit }) {
			commit('RESET_STATE');
		},
		setStep({ commit, state, getters }, stepNumber) {
			const index = stepNumber - 1;

			if (state.furthestStepIndex < index) {
				commit('SET_FURTHEST_STEP_INDEX', index);
			}

			commit('SET_TRANSITION_MODE', state.currentStepIndex > index
				? 'transition-prev'
				: 'transition-next');

			commit('SET_PREVIOUS_STEP_INDEX', state.currentStepIndex);
			commit('SET_CURRENT_STEP_INDEX', index);

			getters.currentStep.changeState('undone');
		},
		setPreviewMode({ commit, getters }, previewState) {
			commit('SET_FURTHEST_STEP_INDEX', previewState ? getters.currentStepIndex : getters.furthestStepIndex);
			commit('SET_PREVIOUS_STEP_INDEX', previewState ? getters.currentStepIndex : getters.furthestStepIndex);
			commit('SET_PREVIEW_MODE_STATE', previewState);
		},
		setCampaign({ commit }, campaign) {
			commit('SET_CAMPAIGN', campaign);
		},
		updateCampaign({ commit }, properties) {
			commit('UPDATE_CAMPAIGN', properties);
		},
	},
	getters: {
		currentStepIndex: (state) => state.currentStepIndex,
		currentStepCount: (state) => state.currentStepIndex + 1,
		previousStepIndex: (state) => state.previousStepIndex,
		furthestStepIndex: (state) => state.furthestStepIndex,
		currentStep: (state) => state.steps[state.currentStepIndex],
		transitionMode: (state) => state.transitionMode,
		campaign: (state) => state.campaign,
		adPlatform: (state) => state.campaign.adPlatform,
		budgetInCurrency: (state) => numeral(state.campaign.Budget).format('(0,0.00)$'),
		hasChanges: (state) => Object.keys(getObjectDiff(state.campaignOrigin, state.campaign)).length > 0,
		isInPreviewMode: (state) => state.previewModeState,
		selectedStores: (state) => state.campaign.stores,
		steps: (state) => state.steps,
	},
};
