<template>
	<AppModal
		ref="modal"
		size="wide"
		:show-sidebar="true"
		sidebar-position="left"
		:before-cancel="beforeCancel"
		@afterClose="onAfterClose"
	>
		<template #title>
			{{ modalTitle }}
		</template>
		<template #body>
			<ValidationObserver
				ref="observer"
				v-slot="{ valid }"
				tag="form"
				class="form"
				@submit.prevent
			>
				<div class="input-fields">
					<AppInput
						v-model="form.internalName"
						:label="$t('creatives.types.facebook.internalName')"
					/>
					<FileUploader
						v-model="form.files"
						:label="$t('input.label.uploadImage')"
						:limit="10"
						:rules="{ required: true }"
						:requirements="{
							sizes: supportedDisplayAdFormatsAsSizeArray
						}"
					>
						<template #beforeList>
							<NotificationItem
								class="has-gap-top-2 has-gap-bottom-15"
								:closeable="false"
							>
								<p v-html="$t('creatives.types.displayAd.notification.imageRecommandations')" />
							</NotificationItem>
						</template>
					</FileUploader>
					<AppInput
						v-model="form.displayLink"
						:label="$t('creatives.types.displayAd.displayLink')"
						:rules="{ required: true, domain: true }"
					/>
				</div>
				<div class="buttons is-row is-right is-reverse">
					<SunButton
						v-if="!editMode"
						width="condensed"
						:state="{ disabled: !valid }"
						@click="onSubmit"
					>
						{{ $t('button.saveAndUpload') }}
					</SunButton>
					<SunButton
						v-if="editMode"
						width="condensed"
						:state="{ disabled: !(valid && hasChanges) }"
						@click="onSubmit"
					>
						{{ $t('button.saveChanges') }}
					</SunButton>
					<SunButton
						width="condensed"
						appearance="borderless"
						color="gray"
						@click="cancel"
					>
						{{ $t('button.cancel') }}
					</SunButton>
				</div>
			</ValidationObserver>
		</template>
		<template #sidebar>
			<div class="sidebar-body">
				<div class="content">
					<p class="headline is-6">
						{{ $t('creatives.types.displayAd.modal.sidebar.title') }}
					</p>
					<p>
						<i18n path="creatives.types.displayAd.modal.sidebar.moreText">
							<!-- eslint-disable -->
							<a href="" target="_blank">{{ $t('creatives.types.displayAd.modal.sidebar.moreLink') }}</a>
							<!-- eslint-enable -->
						</i18n>
					</p>
					<div class="supported-formats">
						<div
							v-for="category in Object.keys(supportedDisplayAdFormatsByCategory)"
							:key="category"
							class="format-category"
						>
							<p class="category-name">
								{{ category }}
							</p>
							<div class="format-list">
								<div
									v-for="format in supportedDisplayAdFormatsByCategory[category]"
									:key="format.name"
									class="list-item"
								>
									<div class="format-size">
										{{ format.width }} × {{ format.height }}
									</div>
									<div class="format-name">
										{{ format.name }} <span v-if="getUploadedFormatCount(format)">({{ getUploadedFormatCount(format) }})</span>
									</div>
									<div
										v-if="getUploadedFormatCount(format)"
										class="format-check"
									>
										<FontAwesomeIcon
											:icon="['far', 'check']"
											class="check-icon"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</AppModal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ModalConfirm from '@/components/ModalConfirm.vue';
import ModalLoading from '@/components/ModalLoading.vue';
import FileUploader from '@/components/FileUploader.vue';
import CustomModalMixin from '@/plugins/CustomModals/mixin';
import NotificationItem from '@/components/NotificationItem.vue';
import ENDPOINTS from '@/utilities/Endpoints';
import { getObjectDiff } from '@/utilities/Helpers';
import { supportedDisplayAdFormats } from '@/utilities/GoogleDisplayAdFormats';
import CreativeImage, { fileToDWH } from '@/utilities/CreativeImage';
import CallAPI from '../plugins/CallAPI';

export default {
	name: 'ModalNewGoogleDisplayAd',

	components: {
		ValidationObserver,
		FileUploader,
		NotificationItem,
	},

	mixins: [CustomModalMixin],

	props: {
		creative: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			form: {
				internalName: '',
				displayLink: '',
				files: [],
			},
			formSnapshot: {},
			uploaded: [],
			editMode: false,
			supportedDisplayAdFormats,
		};
	},

	computed: {
		modalTitle() {
			if (this.editMode) {
				return this.$t('creatives.types.displayAd.modal.edit.title');
			}

			return this.$t('creatives.types.displayAd.modal.new.title');
		},
		changes() {
			return getObjectDiff(this.form, this.formSnapshot);
		},
		hasChanges() {
			return Object.keys(this.changes).length > 0;
		},
		supportedDisplayAdFormatsByCategory() {
			const supportedDisplayAdFormatsByCategory = this.supportedDisplayAdFormats.reduce((newObject, format) => {
				if (Object.keys(newObject).includes(format.category)) {
					newObject[format.category].push(format);
					return newObject;
				}

				newObject[format.category] = [format];
				return newObject;
			}, {});

			return supportedDisplayAdFormatsByCategory;
		},
		supportedDisplayAdFormatsAsSizeArray() {
			return this.supportedDisplayAdFormats.map((format) => [format.width, format.height]);
		},
	},

	mounted() {
		if (this.creative) {
			this.form.internalName = this.creative.Name || '';
			this.form.displayLink = this.creative.Text1;
			this.editMode = true;

			this.form.files = Object.keys(this.creative).reduce((newArray, key) => {
				if (key.match(/((I|i)mage)\w+/g) && this.creative[key]) {
					const image = new CreativeImage(key, this.creative[key]);
					image.getImageDimensions();
					newArray.push(image);
				}

				return newArray;
			}, []);
		}

		this.setFormSnapshot();
	},

	methods: {
		async beforeCancel() {
			return new Promise((resolve) => {
				if (this.hasChanges) {
					this.$modal({
						component: ModalConfirm,
						props: {
							title: this.$t('modal.unsavedChanges.title'),
							text: this.$t('modal.unsavedChanges.text'),
							onConfirm: () => resolve(true),
							onCancel: () => resolve(false),
						},
					});
				} else {
					resolve(true);
				}
			});
		},

		getUploadedFormatCount(format) {
			const files = this.form.files.filter((file) => file.width === format.width && file.height === format.height);
			return files.length;
		},

		async onSubmit() {
			const loadingModal = this.$modal({
				component: ModalLoading,
				props: {
					title: this.$t('modal.uploadingCreatives.title'),
					text: this.$t('modal.uploadingCreatives.text'),
				},
			});

			const images = (await Promise.all(this.form.files.map(fileToDWH)))
				.reduce((newObject, file, index) => {
					newObject[`Image${index + 1}`] = file;
					return newObject;
				}, {});

			this.getImagesToDelete(images).forEach((key) => {
				images[key] = '';
			});

			try {
				const response = await CallAPI.callDWH({
					method: 'POST',
					path: this.creative ? `${ENDPOINTS.Creatives}/${this.creative.Id}` : ENDPOINTS.Creatives,
					data: {
						CreativeType: 'DisplayAd',
						Name: this.form.internalName,
						Text1: this.form.displayLink,
						...images,
					},
				});

				this.$emit('success', response.data.Objects[0].ObjectData);
			} catch (error) {
				this.$emit('error', { error });
			} finally {
				loadingModal.close();
				this.$refs.modal.close();
			}
		},
		setFormSnapshot() {
			this.formSnapshot = { ...this.form };
		},
		getImagesToDelete(imagesToSubmit) {
			const currentlyUsedKeys = this.creative ? Object.keys(this.creative).filter((key) => key.match(/((I|i)mage)\w+/g) && this.creative[key]) : [];
			const imagesToDelete = currentlyUsedKeys.filter((key) => !Object.keys(imagesToSubmit).includes(key));

			return imagesToDelete;
		},
	},
};
</script>

<style lang="scss" scoped>
.supported-formats {
	margin-top: $gap-25;
	color: color('gray', 700);

	.format-category {
		&:not(:last-child) {
			margin-bottom: $gap-25;
		}
	}

	.category-name {
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: $gap-15;
	}

	.format-list .list-item {
		display: flex;
		font-size: $size-8;

		.format-size {
			width: 35%;
		}

		.format-name {
			flex: 1;
		}

		.format-check {
			display: flex;
			align-items: center;
		}

		.check-icon {
			color: $color-success;
		}

		&:not(:last-child) {
			margin-bottom: $gap-05;
		}
	}
}
</style>
