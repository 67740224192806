<template>
	<div
		class="table-wrapper"
		:class="tableWrapperClassObject"
	>
		<TableFilter
			v-if="showFilters"
			:filters="transformedFilters"
			@filter-change="onFilterChange"
		/>
		<LoaderCheckmark
			v-if="isLoading"
			:wrapper="true"
			:threshold="100"
			background-color="rgba(244,244,244,0.5)"
		/>
		<div class="table-container">
			<table
				class="table"
				:class="tableClasses"
			>
				<thead v-if="showHeader">
					<tr>
						<th
							v-for="column in transformedColumns"
							:key="getColumnKey(column)"
							:class="columnClasses(column)"
							:style="columnStyle(column)"
						>
							<template v-if="column.field === 'Checkbox'">
								<SunButton
									v-if="allVisibleRowsSelected"
									color="gray"
									size="small"
									appearance="borderless"
									width="condensed"
									@click="unselectAllVisibleRows"
								>
									{{ $t('button.unselectAll') }}
								</SunButton>
								<SunButton
									v-else
									color="secondary"
									size="small"
									appearance="borderless"
									width="condensed"
									@click="selectAllRows"
								>
									{{ $t('button.selectAll') }}
								</SunButton>
							</template>
							<span v-else>
								{{ column.label }}
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="hasRows">
						<tr
							v-for="row in rows"
							:key="row.Id"
							v-click-pulse:dark="isRowClickable"
							:class="{ 'is-clickable': isRowClickable }"
							@click="onRowClick(row)"
						>
							<td
								v-for="column in transformedColumns"
								:key="`${getColumnKey(column)}-${row.Id}`"
								:class="columnClasses(column)"
							>
								<component
									:is="column.component"
									v-if="column.component"
									:data="row[column.field]"
									:column="column"
									:row="row"
								/>
								<span
									v-else
									:class="{ placeholder: isPlaceholder(column, row) }"
									v-html="formatField(column, row)"
								/>
							</td>
						</tr>
					</template>
					<template v-else>
						<tr>
							<td
								:colspan="transformedColumns.length"
								class="is-empty"
							>
								{{ $t('table.noDataAvailable') }}
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div
			v-if="showFooter"
			class="table-footer"
		>
			<slot name="table-footer">
				<div class="footer-item">
					<AppSelect
						v-model="serverParams.take"
						size="small"
						color="white"
						:label="{
							name: this.$t('input.label.perPage'),
							position: 'left',
							style: 'none'
						}"
						:width="70"
						:options="itemsPerPageOptions"
						:append-to-body="true"
						:calculate-position="withPopper"
						@input="onPerPageChange"
					/>
				</div>
				<div class="footer-item">
					<TablePagination
						:pagination="serverPagination"
						@change-page="onChangePage"
					/>
				</div>
			</slot>
		</div>
	</div>
</template>

<script>
import TablePagination from '@/components/TablePagination.vue';
import TableFilter from '@/components/TableFilter.vue';
import TableFieldCheckbox from '@/components/TableFieldCheckbox.vue';
import TableFieldActions from '@/components/TableFieldActions.vue';
import LoaderCheckmark from '@/components/LoaderCheckmark.vue';
import { createURLQueryPath } from '@/utilities/Helpers';
import ApiToDataMixin from '@/mixins/ApiToDataMixin';
import CallAPI from '../plugins/CallAPI';

export default {
	name: 'AppTable',

	components: {
		TablePagination,
		TableFilter,
		LoaderCheckmark,
	},

	mixins: [ApiToDataMixin],

	props: {
		data: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Array,
			default: () => [],
		},
		columns: {
			type: Array,
			required: true,
		},
		mode: {
			type: String,
			default: 'remote',
		},
		stickyHeader: {
			type: Boolean,
			default: false,
		},
		showFilters: {
			type: Boolean,
			default: false,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		rowClickHandler: {
			type: [Function, null],
			default: null,
		},
		actions: {
			type: Array,
			default: () => [],
		},
		layout: {
			type: String,
			default: 'auto',
			validator: (value) => ['auto', 'fixed'].includes(value),
		},
	},

	computed: {
		selectedRows: {
			get() {
				return this.value;
			},
			set(rows) {
				this.$emit('input', rows);
			},
		},

		allVisibleRowsSelected() {
			return this.rows.every((row) => this.selectedRows.findIndex((selectedRow) => selectedRow[this.trackBy] === row[this.trackBy]) !== -1);
		},

		tableClasses() {
			return {
				'is-fixed': this.layout === 'fixed',
			};
		},

		tableWrapperClassObject() {
			return {
				'is-maxheight': this.mode === 'maxheight',
				'has-sticky-header': this.stickyHeader,
			};
		},
		transformedColumns() {
			let columns = [...this.columns];

			if (this.selectable) {
				columns = [
					{
						field: 'Checkbox',
						label: 'Select',
						component: TableFieldCheckbox,
						width: '135px',
					},
					...columns,
				];
			}

			if (this.actions.length > 0) {
				columns = [
					...columns,
					{
						field: 'Actions',
						label: '',
						component: TableFieldActions,
						actions: this.actions,
						width: '',
					},
				];
			}

			return columns;
		},
		isRowClickable() {
			return typeof this.rowClickHandler === 'function';
		},
		externalColumns() {
			const externalColumns = this.columns.filter((column) => Object.keys(column).includes('source'));
			const mappedColumns = externalColumns.reduce((newObject, column) => {
				if (!Object.keys(newObject).includes(column.source.type)) {
					newObject[column.source.type] = { type: column.source.type, paths: {} };
				}

				const queryPath = createURLQueryPath(column.source.path, column.source.queryParams);

				if (!Object.keys(newObject[column.source.type].paths).includes(queryPath)) {
					newObject[column.source.type].paths[queryPath] = { path: column.source.path, queryParams: column.source.queryParams, columns: [] };
				}

				newObject[column.source.type].paths[queryPath].columns.push({ label: column.label, field: column.field });

				return newObject;
			}, {});

			return mappedColumns;
		},
	},

	watch: {
		perPage(value) {
			this.serverParams.take = value;
		},
	},

	async mounted() {
		this.rows = this.createPlaceholderData();
		this.serverParams.take = this.perPage;
		this.loadData();
	},

	methods: {
		formatField(column, row) {
			const value = this.isPlaceholder(column, row) ? (column.placeholder || 'placeholder') : row[column.field];
			if (typeof column.formatter === 'function') return column.formatter(value);
			return value;
		},

		columnClasses(column) {
			let alignment = 'is-align-left';

			if (column.align === 'right') {
				alignment = 'is-align-right';
			}

			if (column.align === 'center') {
				alignment = 'is-align-center';
			}

			return [
				alignment,
			];
		},

		columnStyle(column) {
			const width = typeof column.width === 'string' && column.width.match(/\d+(?=px|%)/g) ? column.width : '';

			return {
				width,
			};
		},

		isPlaceholder(column, row) {
			const identifier = column.field ? column.field : column.label;
			if (!Object.keys(row).includes(identifier)) return true;
			return (row[identifier] === 'placeholder' || row[identifier] === column.placeholder);
		},

		createPlaceholderData() {
			const placerholderDataSet = this.transformedColumns.reduce((newObject, column) => {
				newObject[column.field || column.label] = column.placeholder || 'placeholder';
				return newObject;
			}, {});

			const placeholderData = [];
			let i = 0;

			while (i < this.serverParams.take) {
				placeholderData.push(placerholderDataSet);
				i++;
			}

			return placeholderData;
		},

		async loadExternalData() {
			if (Object.keys(this.externalColumns).length > 0) {
				this.rows.forEach((row) => {
					Object.values(this.externalColumns).forEach((api) => {
						Object.values(api.paths).forEach(async (path) => {
							const resolvedQueryParams = Object.entries(path.queryParams).reduce((newObject, param) => {
								newObject[param[0]] = param[1][0] === ':' ? row[param[1].match(/(?!:)(.+)/g)] : param[1];
								return newObject;
							}, {});

							const args = {
								method: 'GET',
								path: path.path,
								params: resolvedQueryParams,
							};

							let response;

							if (api.type === 'x2x') {
								response = await CallAPI.callX2X(args);
							} else {
								response = await CallAPI.callDWH(args);
							}

							path.columns.forEach((column) => {
								this.$set(row, column.field, response.data.Objects[0] ? response.data.Objects[0][column.field] : null);
							});
						});
					});
				});
			}
		},

		onChangePage(page) {
			switch (page) {
				case 'next':
					if (this.serverPagination.CurrentPage < this.serverPagination.LastPage) {
						this.serverParams.skip = this.calculateSkipValue(this.serverPagination.CurrentPage + 1);
					}
					break;
				case 'prev':
					if (this.serverPagination.CurrentPage > 1) {
						this.serverParams.skip = this.calculateSkipValue(this.serverPagination.CurrentPage - 1);
					}
					break;
				default:
					this.serverParams.skip = this.calculateSkipValue(page);

					if (page > this.serverPagination.LastPage) {
						this.serverParams.skip = this.calculateSkipValue(this.serverPagination.LastPage);
					}

					if (page < 1) {
						this.serverParams.skip = this.calculateSkipValue(1);
					}
			}

			this.loadData();
		},

		onFilterChange() {
			this.serverParams.skip = this.calculateSkipValue(1);
			this.loadData();
		},

		onRowClick(params) {
			if (typeof this.rowClickHandler === 'function') {
				this.rowClickHandler(params);
			}
		},

		toggleRowSelection(row) {
			const selectedIndex = this.value.findIndex((selectedRow) => selectedRow[this.trackBy] === row[this.trackBy]);

			if (selectedIndex !== -1) {
				this.unselectRow(selectedIndex);
				return;
			}

			if (this.selectable) {
				this.value.push(row);
			}
		},

		selectAllRows() {
			const combinedRows = [...this.selectedRows, ...this.rows];
			const uniqueIds = [...new Set(combinedRows.map((row) => row[this.trackBy]))];
			this.selectedRows = uniqueIds.map((id) => combinedRows.find((row) => row[this.trackBy] === id));
		},

		unselectRow(row) {
			const selectedIndex = typeof row === 'number' ? row : this.value.findIndex((selectedRow) => selectedRow[this.trackBy] === row[this.trackBy]);

			if (selectedIndex !== -1) {
				this.value.splice(selectedIndex, 1);
			}
		},

		unselectAllRows() {
			this.selectedRows = [];
		},

		unselectAllVisibleRows() {
			this.selectedRows = this.selectedRows.filter((selectedRow) => !this.rows.some((row) => selectedRow[this.trackBy] === row[this.trackBy]));
		},

		isRowSelected(row) {
			const selectedIndex = this.selectedRows.findIndex((selectedRow) => selectedRow[this.trackBy] === row[this.trackBy]);
			return selectedIndex !== -1;
		},

		getColumnKey(column) {
			return column.field || column.label;
		},
	},
};
</script>

<style lang="scss" scoped>
.table-wrapper {
	position: relative;
	border: 1px solid color('gray', 200);
	box-shadow: $shadow-box-xnarrow;
	border-radius: $border-radius;

	&.is-maxheight {
		display: flex;
		flex-direction: column;
		height: 100%;

		.vuetable-wrapper {
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow: hidden;

			::v-deep .vuetable-head-wrapper {
				position: static;
			}

			::v-deep .vuetable-body-wrapper {
				flex: 1;
			}
		}
	}

	&.has-sticky-header {
		::v-deep .vgt-fixed-header > table {
			position: sticky;
			top: 64px;
			z-index: 1;
		}
	}
}

.table-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: $size-8;
	padding: $gap-1 $gap-25;
	background-color: color('gray', 50);
	border-top: 1px solid color('gray', 200);
	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;

	.footer-item {
		color: color('gray', 500);
		padding-top: $gap-1;
		padding-bottom: $gap-1;

		&.has-no-padding {
			padding-top: 0;
			padding-bottom: 0;
		}

		&.is-fullwidth {
			width: 100%;
		}

		&.is-centered {
			display: flex;
			justify-content: center;
		}

		&:not(:last-child) {
			margin-right: $gap-25;
		}
	}
}
</style>
