<template>
	<div class="pagination-buttons">
		<SunButton
			class="pagination-item"
			color="gray"
			size="small"
			appearance="borderless"
			width="condensed"
			:state="{ disabled: isOnFirstPage }"
			@click="loadPage('prev')"
		>
			{{ $t('button.tablePaginationPrev') }}
		</SunButton>
		<AppInput
			v-model="goToPageValue"
			class="pagination-item"
			size="small"
			color="white"
			:width="40"
			@submit="goToPage"
			@blur="goToPage"
		/>
		<SunButton
			class="pagination-item"
			color="gray"
			size="small"
			appearance="borderless"
			width="condensed"
			:state="{ disabled: isOnLastPage }"
			@click="loadPage('next')"
		>
			{{ $t('button.tablePaginationNext') }}
		</SunButton>
	</div>
</template>

<script>
export default {
	name: 'TablePagination',

	props: {
		pagination: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			goToPageValue: 1,
		};
	},

	computed: {
		isOnFirstPage() {
			return parseInt(this.pagination.CurrentPage, 0) === 1;
		},
		isOnLastPage() {
			return parseInt(this.pagination.CurrentPage, 0) === parseInt(this.pagination.LastPage, 0);
		},
		currentPage: {
			get() {
				return this.pagination.CurrentPage || 1;
			},
			set(value) {
				const page = parseInt(value, 0);
				this.loadPage(page);
			},
		},
	},

	watch: {
		pagination: {
			deep: true,
			handler(value) {
				this.goToPageValue = value.CurrentPage;
			},
		},
	},

	methods: {
		goToPage(event) {
			const page = parseInt(event.target.value, 0);

			if (this.goToPageValue !== page) {
				this.goToPageValue = page;
				this.loadPage(page);
			}
		},

		loadPage(payload) {
			this.$emit('change-page', payload);
		},
	},
};
</script>

<style lang="scss" scoped>
.pagination-buttons {
	display: flex;

	.pagination-item:not(:last-child) {
		margin-right: $gap-1;
	}
}
</style>
