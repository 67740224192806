import ModalWrapper from './ModalWrapper.vue';
import events from './events';

class Modal {
	constructor({ id }) {
		this.id = id;
	}

	close() {
		events.$emit('close', this.id);
	}
}

const CustomModals = {
	install(vue) {
		vue.component('ModalWrapper', ModalWrapper);

		const modal = (params) => {
			params.id = `modal-${Date.now()}`;
			const modalInstance = new Modal({ id: params.id });
			events.$emit('add', params);
			return modalInstance;
		};

		vue.prototype.$modal = modal;
		vue.modal = modal;
	},
};

export default CustomModals;
