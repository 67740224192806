<template>
	<div
		class="input-wrapper"
		:class="{ required }"
	>
		<div class="input-header">
			<p class="input-label">
				{{ $t('input.label.selectStores') }}
			</p>
			<p
				v-if="hasItemsSelected"
				class="selected-count"
			>
				({{ $t('amountSelected', [selectedCount]) }})
			</p>
		</div>
		<div
			v-if="hasItemsSelected || readonly"
			class="list-wrapper"
		>
			<ul class="selected-list">
				<li
					v-for="(item, $index) in selectedItems"
					:key="item.articleNumber"
					class="list-item"
				>
					<AppIcon
						size="small"
						:icon="ICON_STORE"
						color="quaternary"
					>
						{{ item.Name }}
					</AppIcon>
					<SunButton
						v-if="!readonly"
						size="square-medium"
						color="gray"
						appearance="borderless"
						class="item-remove"
						@click="removeItemByIndex($index)"
					>
						<FontAwesomeIcon
							:icon="['fal', 'times']"
							class="icon"
						/>
					</SunButton>
				</li>
			</ul>
		</div>
		<SunButton
			v-if="!readonly"
			color="secondary"
			size="small"
			appearance="borderless"
			width="condensed"
			@click="openStoresSelectionModal"
		>
			<template v-if="!hasItemsSelected">
				{{ $t('button.select') }}
			</template>
			<template v-else>
				{{ $t('button.editStoreSelection') }}
			</template>
		</SunButton>
	</div>
</template>

<script>
import ModalSelectStores from '@/components/ModalSelectStores.vue';
import { ICON_STORE } from '@/utilities/Icons';

export default {
	name: 'StoresSelection',

	props: {
		value: {
			type: Array,
			default: () => [],
		},
		readonly: {
			type: Boolean,
			defeault: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			selectedItems: [],
			ICON_STORE,
		};
	},

	computed: {
		selectedCount() {
			return this.selectedItems.length;
		},
		hasItemsSelected() {
			return this.selectedCount > 0;
		},
	},

	watch: {
		value: {
			immediate: true,
			handler(value) {
				this.selectedItems = value;
			},
		},
		selectedItems(selectedItems) {
			this.$emit('input', selectedItems);
		},
	},

	methods: {
		openStoresSelectionModal() {
			this.$modal({
				component: ModalSelectStores,
				props: {
					value: this.selectedItems,
				},
				events: {
					success: this.onStoresSelectionModalSuccess,
				},
			});
		},
		onStoresSelectionModalSuccess(selection) {
			this.selectedItems = selection;
		},
		removeItemByIndex(index) {
			this.selectedItems.splice(index, 1);
		},
	},
};
</script>

<style lang="scss" scoped>
.input-header {
	display: flex;
	align-items: center;
	margin-bottom: $gap-15;

	.input-label {
		margin-bottom: 0;
	}

	.selected-count {
		flex: 1;
		color: color('gray', 500);
		font-size: $size-8;
		margin-left: $gap-1;
	}

	.header-link {
		color: color('secondary', 500);
		font-weight: 600;
		font-size: $size-8;
		cursor: pointer;
	}
}

.list-wrapper {
	background-color: color('gray', 50);
	border-radius: $border-radius;
	height: 180px;
	overflow: auto;
	margin-bottom: $gap-2;
}

.selected-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: $gap-2;
	padding: $gap-2;

	.list-item {
		display: flex;
		align-items: center;
	}

	.item-remove {
		margin-left: auto;

		.remove-icon {
			width: 16px;
			height: 16px;
		}
	}
}
</style>
