<template>
	<div class="view">
		<div class="view-header">
			<div class="header-item">
				<h1 class="view-title">
					<FontAwesomeIcon
						:icon="['far', 'cog']"
						class="title-icon"
					/>
					{{ $t('view.settings.title') }}
				</h1>
				<p class="view-subtitle">
					{{ $t('view.settings.subtitle') }}
				</p>
			</div>
		</div>
		<div class="view-body">
			<div class="box">
				<div class="box-navigation">
					<router-link
						v-for="(item, $index) in navigation"
						:key="$index"
						v-click-pulse
						:to="item.link"
						class="navigation-item"
					>
						{{ item.label }}
					</router-link>
				</div>
				<div class="box-section is-12">
					<router-view />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Settings',

	data() {
		return {
			navigation: [
				{
					label: this.$t('navigation.label.accountSettings'),
					link: { name: 'accountSettings' },
				},
				{
					label: this.$t('navigation.label.changePassword'),
					link: { name: 'changePassword' },
				},
			],
		};
	},
};
</script>

<style>

</style>
