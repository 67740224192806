<template>
	<div class="view">
		<div class="view-header has-gap-small">
			<div class="header-item">
				<SunButton
					color="gray"
					appearance="borderless"
					size="small"
					@click="$router.push({ name: creativeType.name })"
				>
					<FontAwesomeIcon
						:icon="['far', 'chevron-left']"
						class="icon icon--before"
					/>
					{{ $t('button.back') }}
				</SunButton>
			</div>
			<div class="header-item is-right">
				<div class="buttons is-row">
					<SunButton
						color="secondary"
						appearance="borderless"
						size="small"
						@click="editCreative"
					>
						<FontAwesomeIcon
							:icon="['far', 'pen']"
							class="icon icon--before"
						/>
						{{ $t('button.editCreativeDetails') }}
					</SunButton>
					<SunButton
						color="tertiary"
						appearance="borderless"
						size="small"
						@click="requestCreativeDeletetion"
					>
						<FontAwesomeIcon
							:icon="['far', 'trash']"
							class="icon icon--before"
						/>
						{{ $t('button.deleteCreativeDetails') }}
					</SunButton>
				</div>
			</div>
		</div>
		<div class="view-body">
			<div class="box is-row">
				<div class="box-section is-9 is-flex is-column">
					<div class="section-middle">
						<AppIcon
							class="has-gap-bottom-2"
							:icon="ICON_CREATIVE"
							color="tertiary"
							size="large"
						>
							<span v-placeholder-loader>{{ creativeName }}</span>
						</AppIcon>
						<CreativeTypeTag
							:type="creative.CreativeType"
							class="has-gap-bottom-4"
						/>
						<div
							class="creative-images"
							:class="{ 'is-grid': images.length > 1 }"
						>
							<div
								v-for="image in images"
								:key="image.key"
								class="image-item"
							>
								<video
									v-if="creative.CreativeType === 'VideoAd'"
									class="creative-image"
									controls
								>
									<source :src="image.fileURL">
								</video>
								<ProgressiveImage
									v-else
									class="creative-image"
									:src="image.fileURL"
								/>
								<p>
									<span
										v-placeholder-loader="!image.fileLoaded"
										class="creative-filename"
									>
										{{ getFilename(image) }}
									</span>
								</p>
								<p>
									<span
										v-placeholder-loader="!image.fileLoaded"
										class="creative-dimensions"
									>
										{{ image.width }} x {{ image.height }}px
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="box-section is-gray is-3">
					<div
						v-for="field in fields"
						:key="field.id"
						class="field-item"
					>
						<p class="section-headline">
							{{ field.label }}
						</p>
						<strong
							v-placeholder-loader="!creativeFetched"
							class="section-text"
						>
							{{ getFieldText(field) }}
						</strong>
					</div>
				</div>
			</div>
		</div>
		<AppModal
			v-if="showDeleteModal"
			ref="modal"
			:allow-backdrop-close="false"
			@afterClose="showDeleteModal = false"
		>
			>
			<template #title>
				{{ $t('modal.confirmCreativeDelete.title') }}
			</template>
			<template #body="{ close }">
				<i18n path="modal.confirmCreativeDelete.body">
					<strong>{{ creative.Title1 }}</strong>
				</i18n>

				<NotificationItem
					class="has-gap-top-2 has-gap-bottom-15"
					:closeable="false"
					:notification="{ type: 'warning' }"
				>
					<p v-html="$t('notification.warning.cantBeUndone')" />
				</NotificationItem>

				<div class="buttons is-row is-right has-gap-top-6">
					<SunButton
						color="gray"
						appearance="borderless"
						width="condensed"
						@click="close"
					>
						{{ $t('button.cancel') }}
					</SunButton>
					<SunButton
						color="tertiary"
						width="condensed"
						@click="deleteCreative"
					>
						{{ $t('button.confirm') }}
					</SunButton>
				</div>
			</template>
		</AppModal>
	</div>
</template>

<script>
import ENDPOINTS from '@/utilities/Endpoints';
import ProgressiveImage from '@/components/ProgressiveImage.vue';
import CreativeTypeTag from '@/components/CreativeTypeTag.vue';
import NotificationItem from '@/components/NotificationItem.vue';
import AddCreativesMixin from '@/mixins/AddCreativesMixin';
import callToActionOptions from '@/utilities/FacebookCallToActions';
import { ICON_CREATIVE } from '@/utilities/Icons';
import CreativeFile from '@/utilities/CreativeFile';
import CreativeTypes from '@/utilities/CreativeTypes';
import CallAPI from '@/plugins/CallAPI';

export default {
	name: 'CreativeSingle',

	components: {
		ProgressiveImage,
		CreativeTypeTag,
		NotificationItem,
	},

	mixins: [AddCreativesMixin],

	props: {
		creativeId: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
			creative: {},
			creativeFetched: false,
			imageFetched: false,
			images: [new CreativeFile('Placeholder1', '')],
			showDeleteModal: false,
			ICON_CREATIVE,
		};
	},

	computed: {
		callToActionLabel() {
			if (this.creative.Text3) {
				const callToActionOption = callToActionOptions.find((option) => option.id === this.creative.Text3);
				return callToActionOption ? callToActionOption.label : this.creative.Text3;
			}

			return '';
		},
		creativeName() {
			return this.creative.Name || this.creative.Title1;
		},
		creativeType() {
			return CreativeTypes[this.creative.CreativeType];
		},
		fields() {
			return this.creativeType.fields;
		},
	},

	mounted() {
		this.fetchCreative();
	},

	methods: {
		mapCreativeImages() {
			this.images = [];

			Object.keys(this.creative).forEach((key) => {
				if (key.match(/((I|i)mage)\w+/g) && this.creative[key]) {
					const image = new CreativeFile(key, this.creative[key]);
					image.getFileDimensions();
					this.images.push(image);
				}
			});
		},

		getFilename(image) {
			if (image.fileURL) {
				return image.fileURL.replace(/^.*[\\/]/, '');
			}

			return '';
		},

		async fetchCreative() {
			try {
				const response = await CallAPI.callDWH({
					method: 'GET',
					path: `${ENDPOINTS.Creatives}/${this.creativeId}`,
				});

				if (response.data.Objects.length === 0) {
					throw new Error('creative.not.found');
				}

				if (response.data.Objects[0].State === 'Deleted') {
					throw new Error('creative.not.found');
				}

				this.creativeFetched = true;
				[this.creative] = response.data.Objects;

				this.mapCreativeImages();
			} catch (error) {
				this.$router.push({ name: 'creatives' });
				this.$notify({
					type: 'error',
					text: 'creative not found',
				});
			}
		},

		editCreative() {
			this.$_openCreateCreativeModal({
				creativeType: this.creative.CreativeType,
				creative: this.creative,
				onSuccess: this.onUpdateSuccess,
			});
		},

		requestCreativeDeletetion() {
			this.showDeleteModal = true;
		},

		async deleteCreative() {
			try {
				const response = await CallAPI.callDWH({
					method: 'POST',
					path: `${ENDPOINTS.Creatives}/${this.creative.Id}`,
					data: {
						State: 'Deleted',
					},
				});

				if (!response.data.Objects[0].Success) throw new Error('creative.update.failed');

				this.$notify({
					type: 'success',
					text: this.$t('notification.success.deletedCreative'),
				});

				this.$router.push({ name: 'creatives' });
			} catch (error) {
				this.$notify({
					type: 'error',
					text: error.message,
				});
			}
		},

		onUpdateSuccess(creative) {
			this.creative = { ...this.creative, ...creative };
			this.mapCreativeImages();
		},

		getFieldText(field) {
			if (field.formatter && typeof field.formatter === 'function') {
				return field.formatter(this.creative[field.id]);
			}

			return this.creative[field.id];
		},
	},
};
</script>

<style lang="scss" scoped>
.field-item:not(:last-child) {
	margin-bottom: $gap-25;
}

.creative-image {
	max-width: 540px;
	width: 100%;
	object-fit: contain;
	border: 1px solid color('gray', 200);
	border-radius: $border-radius;
	margin-bottom: $gap-2;
	position: relative;

	::v-deep & > * {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	&:after {
		display: block;
		content: '';
		padding-bottom: 100%;
	}
}

.creative-filename {
	font-weight: 600;
	color: color('gray', 700);
}

.creative-dimensions {
	font-weight: 600;
	font-size: $size-9;
	color: color('gray', 500);
}

.creative-images {
	&.is-grid {
		display: grid;
		grid-gap: $gap-3;
		grid-template-columns: repeat(4, 1fr);
	}
}
</style>
