import i18n from '@/plugins/i18n';

class UserMenuNavigationItem {
	constructor({ name, link, color = '' }) {
		this.name = name;
		this.link = link;
		this.color = color;
	}
}

const userMenuNavigationItems = [
	new UserMenuNavigationItem({
		name: i18n.t('navigation.label.settings'),
		link: { name: 'accountSettings' },
	}),
	new UserMenuNavigationItem({
		name: i18n.t('navigation.label.logout'),
		link: { name: 'logout' },
		color: '#FF1B29',
	}),
];

export { UserMenuNavigationItem, userMenuNavigationItems };
