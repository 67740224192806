<template>
	<transition
		name="fade-up"
		appear
	>
		<div class="usermenu">
			<div class="menu-header">
				<div class="user-info">
					<p class="user-name">
						<span v-if="currentUser">
							{{ transformedUserName }}
						</span>
					</p>
				</div>
			</div>
			<div class="menu-body">
				<ul class="menu-list">
					<li
						v-for="item in navigationItems"
						:key="item.name"
						class="list-item"
					>
						<router-link
							:to="item.link"
							class="item-link"
							@click.native="onItemClick"
						>
							<span
								class="item-label"
								:style="{ 'color': item.color }"
							>
								{{ item.name }}
							</span>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { userMenuNavigationItems } from '@/utilities/UserMenuNavigation';

const USERNAME_CHARACTER_LIMIT = 20;

export default {
	name: 'TopbarUserMenu',

	components: {
	},

	data() {
		return {
			navigationItems: userMenuNavigationItems,
		};
	},

	computed: {
		...mapGetters('user', [
			'currentUser',
		]),
		transformedUserName() {
			const userName = this.currentUser.UserName;

			if (userName.length > USERNAME_CHARACTER_LIMIT) {
				return `${userName.slice(0, USERNAME_CHARACTER_LIMIT)}...`;
			}

			return userName;
		},
	},

	methods: {
		...mapActions('auth', [
			'logoutUser',
		]),
		async logout() {
			await this.logoutUser();
			this.$router.push({ name: 'login' });
		},
		onItemClick() {
			this.$emit('item-click');
		},
	},
};
</script>

<style lang="scss" scoped>
.usermenu {
	background-color: $color-white;
	width: 200px;
	border: 1px solid color('gray', 200);
	border-radius: 6px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	padding-top: $gap-1;
	padding-bottom: $gap-1;

	.menu-header {
		display: flex;
		align-items: center;
		padding: $gap-1 $gap-15;

		.user-avatar {
			flex-shrink: 0;
			margin-right: $gap-1;
		}

		.user-name {
			font-size: $size-8;
			font-weight: 600;
		}

		.user-role {
			font-size: $size-8;
			color: color('gray', 500);
		}
	}

	.menu-list {
		.item-link {
			display: flex;
			align-items: center;
			height: 34px;
			padding: $gap-1 $gap-15;

			&:hover {
				background-color: color('gray', 100);

				.item-label {
					color: color('gray', 700);
				}

				.item-icon {
					color: color('secondary', 700);
				}
			}
		}

		.item-label {
			font-size: $size-8;
			font-weight: 500;
			color: color('gray', 500);
		}

		.item-icon {
			color: color('gray', 400);
			margin-right: $gap-1;
		}
	}
}
</style>
