<template>
	<div class="view">
		<div class="view-header has-gap-small">
			<div class="header-item">
				<SunButton
					color="gray"
					appearance="borderless"
					size="small"
					@click="$router.push({ name: 'stores' })"
				>
					<FontAwesomeIcon
						:icon="['far', 'chevron-left']"
						class="icon icon--before"
					/>
					{{ $t('button.back') }}
				</SunButton>
			</div>
			<div class="header-item is-right">
				<div class="buttons is-row">
					<SunButton
						color="secondary"
						appearance="borderless"
						size="small"
						@click="editStore"
					>
						<FontAwesomeIcon
							:icon="['far', 'pen']"
							class="icon icon--before"
						/>
						{{ $t('button.editStoreDetails') }}
					</SunButton>
					<SunButton
						color="tertiary"
						appearance="borderless"
						size="small"
						@click="requestStoreDeletion"
					>
						<FontAwesomeIcon
							:icon="['far', 'trash']"
							class="icon icon--before"
						/>
						{{ $t('button.deleteStoreDetails') }}
					</SunButton>
				</div>
			</div>
		</div>
		<div class="view-body">
			<div class="box is-row">
				<div class="box-section is-4 is-flex is-column">
					<div class="section-middle">
						<AppIcon
							class="box-title"
							:icon="ICON_STORE"
							color="secondary"
							size="large"
						>
							<span v-placeholder-loader>{{ store.Name }}</span>
						</AppIcon>
					</div>
				</div>
				<div class="box-section is-gray is-8">
					<div class="columns">
						<div class="column">
							<p class="section-headline">
								Id
							</p>
							<strong
								v-placeholder-loader="!storeFetched"
								class="section-text"
							>
								{{ store.Id }}
							</strong>
							<p class="section-headline">
								{{ $t('section.label.street') }}
							</p>
							<strong
								v-placeholder-loader="!storeFetched"
								class="section-text"
							>
								{{ store.StreetNumber }}   {{ store.Street }}
							</strong>
							<p class="section-headline">
								{{ $t('section.label.zipCode') }}
							</p>
							<strong
								v-placeholder-loader="!storeFetched"
								class="section-text"
							>
								{{ store.Zip }}
							</strong>
						</div>
						<div class="column">
							<p class="section-headline">
								{{ $t('section.label.city') }}
							</p>
							<strong
								v-placeholder-loader="!storeFetched"
								class="section-text"
							>
								{{ store.City }}
							</strong>
							<p class="section-headline">
								{{ $t('section.label.region') }}
							</p>
							<strong
								v-if="store.Region"
								v-placeholder-loader="!storeFetched"
								class="section-text"
							>
								{{ store.Region }}
							</strong>
							<strong
								v-else
								v-placeholder-loader="!storeFetched"
								class="section-text"
							>
								{{ $t('section.text.noRegion') }}
							</strong>
							<p class="section-headline">
								{{ $t('section.label.radius') }}
							</p>
							<strong
								v-if="store.Radius"
								v-placeholder-loader="!storeFetched"
								class="section-text"
							>
								{{ store.Radius }}
							</strong>
							<strong
								v-else
								v-placeholder-loader="!storeFetched"
								class="section-text"
							>
								{{ $t('section.text.noRadius') }}
							</strong>
						</div>
					</div>
				</div>
			</div>
		</div>
		<AppModal
			v-if="showDeleteModal"
			ref="modal"
			:allow-backdrop-close="false"
			@afterClose="showDeleteModal = false"
		>
			>
			<template #title>
				{{ $t('modal.confirmStoreDelete.title') }}
			</template>
			<template #body="{ close }">
				<i18n path="modal.confirmStoreDelete.body">
					<strong>{{ store.Name }}</strong>
				</i18n>

				<NotificationItem
					class="has-gap-top-2 has-gap-bottom-15"
					:closeable="false"
					:notification="{ type: 'warning' }"
				>
					<p v-html="$t('notification.warning.cantBeUndone')" />
				</NotificationItem>

				<div class="buttons is-row is-right has-gap-top-6">
					<SunButton
						color="gray"
						appearance="borderless"
						width="condensed"
						@click="close"
					>
						{{ $t('button.cancel') }}
					</SunButton>
					<SunButton
						color="tertiary"
						width="condensed"
						@click="deleteStore"
					>
						{{ $t('button.confirm') }}
					</SunButton>
				</div>
			</template>
		</AppModal>
	</div>
</template>

<script>
import ENDPOINTS from '@/utilities/Endpoints';
import AddStoreMixin from '@/mixins/AddStoreMixin';
import { ICON_STORE } from '@/utilities/Icons';
import NotificationItem from '@/components/NotificationItem.vue';
import CallAPI from '@/plugins/CallAPI';

export default {
	name: 'StoreSingle',
	components: {
		NotificationItem,
	},

	mixins: [AddStoreMixin],

	props: {
		storeId: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			store: {},
			storeFetched: false,
			ICON_STORE,
			showDeleteModal: false,
		};
	},

	mounted() {
		this.fetchStore();
	},
	methods: {
		async fetchStore() {
			try {
				const response = await CallAPI.callDWH({
					method: 'GET',
					path: `${ENDPOINTS.LocationImport}/${this.storeId}`,
				});
				if (response.data.Objects.length === 0) {
					throw new Error('location.not.found');
				}

				[this.store] = response.data.Objects;
				this.storeFetched = true;
				return response.data.Objects[0];
			} catch (error) {
				this.$router.push({ name: 'stores' });
				this.$notify({
					type: 'error',
					text: this.$t('notification.error.storeNotFound'),
				});
				return {};
			}
		},
		onUpdateSuccess(store) {
			this.store = { ...this.store, ...store };
		},
		editStore() {
			this.$_openCreateStoreModal({
				store: this.store,
				onSuccess: this.onUpdateSuccess,
			});
		},
		requestStoreDeletion() {
			this.showDeleteModal = true;
		},
		async deleteStore() {
			try {
				const response = await CallAPI.callDWH({
					method: 'POST',
					path: `${ENDPOINTS.LocationImport}/${this.store.Id}`,
					data: {
						State: 'Deleted',
					},
				});

				if (!response.data.Objects[0].Success) throw new Error('creative.update.failed');
				this.$notify({
					type: 'success',
					text: this.$t('notification.success.deletedStore'),
				});

				this.$router.push({ name: 'stores' });
			} catch (error) {
				this.$notify({
					type: 'error',
					text: error.message,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>

</style>
