<template>
	<ValidationObserver
		ref="observer"
		v-slot="{ valid }"
		tag="div"
		class="box-section is-flex is-column is-gapless is-fullheight"
	>
		<BookCampaignHeader :step="step" />
		<div class="section-middle">
			<div class="input-fields">
				<AppSelect
					v-model="form.Genders"
					:label="$t('input.label.gender')"
					:options="genderOptions"
					required
					:append-to-body="true"
				/>
				<AppSlider
					v-model="age"
					:label="$t('input.label.age')"
					:max="99"
					tooltip="none"
				/>
			</div>
		</div>
		<BookCampaignFooter
			:step="step"
			:valid="valid"
			v-on="$listeners"
		/>
	</ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CampaignBookStepsMixin from '@/mixins/CampaignBookStepsMixin';

export default {
	name: 'TargetOptions',

	mixins: [CampaignBookStepsMixin],

	data() {
		return {
			form: {
				Genders: 'None',
				MinAge: 18,
				MaxAge: 65,
			},
			genderOptions: [
				{
					name: this.$t('genders.name.any'),
					value: 'None',
				},
				{
					name: this.$t('genders.name.male'),
					value: 'Male',
				},
				{
					name: this.$t('genders.name.female'),
					value: 'Female',
				},
			],
		};
	},

	computed: {
		...mapGetters('book', [
			'campaign',
		]),
		age: {
			get() {
				return [this.form.MinAge, this.form.MaxAge];
			},
			set([MinAge, MaxAge]) {
				this.form.MinAge = MinAge;
				this.form.MaxAge = MaxAge;
			},
		},
	},

	watch: {
		form: {
			deep: true,
			async handler(form) {
				this.updateCampaign(form);
			},
		},
	},

	mounted() {
		this.syncForm();
	},

	methods: {
		...mapActions('book', [
			'updateCampaign',
		]),
		syncForm() {
			Object.keys(this.form).forEach((key) => {
				if (Object.prototype.hasOwnProperty.call(this.campaign, key)) {
					this.form[key] = this.campaign[key];
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.section-middle {
	max-width: 570px;
}
</style>
