<template>
	<div class="buttons is-row is-right">
		<SunButton
			v-for="(action, $index) in actions"
			:key="$index"
			size="small"
			:icon="action.icon"
			@click="action.onClick(row)"
		>
			{{ action.label }}
		</SunButton>
	</div>
</template>

<script>
export default {
	name: 'TableFieldActions',

	props: {
		column: {
			type: Object,
			required: true,
		},
		row: {
			type: Object,
			required: true,
		},
	},

	computed: {
		actions() {
			return this.column.actions;
		},
	},
};
</script>

<style>

</style>
