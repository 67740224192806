<template>
	<div class="sidebar">
		<div class="top-box">
			<router-link :to="{ name: 'dashboard' }">
				<img src="@/assets/logo-localup-dark.svg">
			</router-link>
		</div>
		<div class="middle-box">
			<transition
				name="fade"
				mode="out-in"
			>
				<component :is="sidebarComponent" />
			</transition>
		</div>
	</div>
</template>

<script>
import SidebarNavigation from '@/components/SidebarNavigation.vue';

export default {
	name: 'TheSidebar',

	components: {
		SidebarNavigation,
	},

	computed: {
		sidebarComponent() {
			return this.$route.meta.sidebar || SidebarNavigation;
		},
	},
};
</script>

<style lang="scss" scoped>
.sidebar {
	position: fixed;
	box-sizing: border-box;
	width: 300px;
	height: 100vh;
	background-color: color('secondary', 900);
	padding: $gap-3;
	box-sizing: border-box;
	z-index: 150;

	.top-box {
		padding-left: $gap-2;
		padding-right: $gap-2;
	}

	.middle-box {
		margin-top: $gap-45;
		margin-bottom: $gap-6;
	}
}
</style>
