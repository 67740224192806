<template>
	<div class="modal-wrapper">
		<component
			:is="modal.component"
			v-for="modal in modals"
			:key="modal.id"
			:ref="modal.id"
			v-bind="modal.props"
			v-on="modal.events"
			@afterClose="destroyModal(modal.id)"
		/>
		<EventListener @keyup.esc="onKeyupEsc" />
	</div>
</template>

<script>
import EventListener from '@/components/EventListener.vue';
import events from './events';

export default {
	name: 'ModalWrapper',

	components: {
		EventListener,
	},

	data() {
		return {
			modals: [],
		};
	},

	watch: {
		modals(value) {
			if (value.length > 0) {
				this.disableBodyScroll();
			} else {
				this.enableBodyScroll();
			}
		},
	},

	mounted() {
		events.$on('add', this.addModal);
		events.$on('close', this.closeModal);
	},

	methods: {
		addModal(options) {
			this.modals.push(options);
		},

		closeModal(modalId) {
			const modalReference = this.$refs[modalId];

			if (modalReference) {
				modalReference[0].close();
			} else {
				this.destroyModal(modalId);
			}
		},

		destroyModal(modalId) {
			const modalIndex = this.modals.findIndex((modal) => modal.id === modalId);

			if (modalIndex !== -1) {
				this.modals.splice(modalIndex, 1);
				delete this.$refs[modalId];
			}
		},

		onKeyupEsc() {
			const modal = this.getLatestModal();

			if (modal) {
				modal.cancelByBackdrop();
			}
		},

		getLatestModal() {
			const dialogIndex = (-1 + this.modals.length);

			if (dialogIndex > -1) {
				const modal = this.modals[dialogIndex];
				return this.$refs[modal.id][0];
			}

			return null;
		},

		disableBodyScroll() {
			document.getElementsByTagName('body')[0].classList.add('has-scrolling-disabled');
		},

		enableBodyScroll() {
			document.getElementsByTagName('body')[0].classList.remove('has-scrolling-disabled');
		},
	},
};
</script>

<style>

</style>
