import i18n from '@/plugins/i18n';

const supportedCallToActionsEnums = [
	'LearnMore',
	'ShopNow',
	'SignUp',
	'Subscribe',
];

const mappedCallToActions = supportedCallToActionsEnums.map((ctaEnum) => ({
	id: ctaEnum,
	label: i18n.t(`creatives.types.facebook.callToAction.${ctaEnum}`),
}));

export default mappedCallToActions;
