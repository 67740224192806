import CallAPI from '@/plugins/CallAPI';

const getDefaultState = () => ({
	users: [],
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_USERS(state, users) {
			state.users = users;
		},
	},
	actions: {
		resetUserState({ commit }) {
			commit('RESET_STATE');
		},
		async fetchUsers({ commit }) {
			const response = await CallAPI.callX2X({
				method: 'GET',
				path: '/users',
			});

			commit('SET_USERS', response.data.Objects);

			return response.data.Objects;
		},
		async changePasswordForCurrentUser({ getters }, newPassword) {
			const response = await CallAPI.callX2X({
				method: 'POST',
				path: `/users/${getters.currentUser.Id}`,
				data: {
					Password: newPassword,
				},
			});

			return response;
		},
	},
	getters: {
		users: (state) => state.users,
		currentUser: (state) => state.users[0],
		featureFlags: (state, getters) => ({
			// Only enabled for Edeka (4) and user@example.org (1)
			CAMPAIGN_NAME_PREFIX: getters.currentUser.Id === 4 || getters.currentUser.Id === 1,
		}),
	},
};
