import Vue from 'vue';
import NProgress from 'nprogress';
import moment from 'moment';

const NOTIFICATION_TIME_THRESHOLD = 5000;

export default {
	namespaced: true,
	state: {
		readyState: false,
		loadingSegments: [],
		loadingStartTimeout: null,
		loadingEndTimeout: null,
		lastLoadingNotificationTime: moment(),
		isLoadingNotificationVisible: false,
		loadingNotificationSegment: null,
	},
	mutations: {
		SET_READY_STATE(state, payload) {
			state.readyState = payload;
		},
		ADD_LOADING_SEGMENT(state, segmentId) {
			state.loadingSegments.push(segmentId);
		},
		REMOVE_LOADING_SEGMENT(state, segmentId) {
			state.loadingSegments.splice(state.loadingSegments.indexOf(segmentId), 1);
		},
		SET_LAST_LOADING_NOTIFICATION_TIME(state, time) {
			state.lastLoadingNotificationTime = time;
		},
		SET_LOADING_NOTIFICATION_VISIBILITY_STATE(state, visibilityState) {
			state.isLoadingNotificationVisible = visibilityState;
		},
		SET_LOADING_NOTIFICATION_SEGMENT(state, segmentId) {
			state.loadingNotificationSegment = segmentId;
		},
	},
	actions: {
		addLoadingSegment({ state, commit, dispatch }, segmentId) {
			commit('ADD_LOADING_SEGMENT', segmentId);

			clearTimeout(state.loadingStartTimeout);

			state.loadingStartTimeout = setTimeout(() => {
				if (state.loadingSegments.length !== 0) {
					return dispatch('startLoading');
				}

				return false;
			}, 350);

			setTimeout(() => {
				if (state.loadingSegments.indexOf(segmentId) > -1 && moment().diff(state.lastLoadingNotificationTime, 'seconds', true) > (NOTIFICATION_TIME_THRESHOLD / 1000) && !state.isLoadingNotificationVisible) {
					Vue.notify({
						type: 'info',
						text: 'notification.text.longLoadingTime',
						duration: -1,
						closeOnClick: false,
						data: {
							closeable: false,
						},
					});

					commit('SET_LOADING_NOTIFICATION_VISIBILITY_STATE', true);
					commit('SET_LAST_LOADING_NOTIFICATION_TIME', moment());
					commit('SET_LOADING_NOTIFICATION_SEGMENT', segmentId);
				}
			}, NOTIFICATION_TIME_THRESHOLD);
		},
		removeLoadingSegment({ state, commit, dispatch }, segmentId) {
			commit('REMOVE_LOADING_SEGMENT', segmentId);

			clearTimeout(state.loadingEndTimeout);

			state.loadingEndTimeout = setTimeout(() => {
				if (state.loadingSegments.length === 0) {
					if (state.isLoadingNotificationVisible) Vue.notify({ clear: true });
					commit('SET_LOADING_NOTIFICATION_VISIBILITY_STATE', false);
					commit('SET_LOADING_NOTIFICATION_SEGMENT', null);

					return dispatch('endLoading');
				}

				return dispatch('updateLoading', 0.1);
			}, 350);
		},
		startLoading({ commit }) {
			if (!NProgress.isRendered()) {
				NProgress.start();
				commit('SET_READY_STATE', false);
			}
		},
		updateLoading(context, amountToAdd) {
			NProgress.inc(amountToAdd);
		},
		endLoading({ commit }) {
			NProgress.done();
			commit('SET_READY_STATE', true);
		},
	},
	getters: {
	},
};
