<template>
	<AppModal
		ref="modal"
		size="wide"
		:before-cancel="beforeCancel"
		@afterClose="onAfterClose"
	>
		<template #title>
			{{ modalTitle }}
		</template>
		<template #body>
			<div class="columns">
				<div class="content column is-4">
					<img src="@/assets/link-ad-example.svg">
					<p class="headline is-5 has-gap-top-1">
						{{ $t("creatives.types.facebook.scheme.title") }}
					</p>
					<p>
						<i18n path="creatives.types.facebook.scheme.moreText">
							<!-- eslint-disable -->
							<a
								href="https://www.facebook.com/business/ads-guide/image"
								target="_blank"
								>{{ $t("creatives.types.facebook.scheme.moreLink") }}</a
							>
							<!-- eslint-enable -->
						</i18n>
					</p>
				</div>
				<div class="column">
					<ValidationObserver
						ref="observer"
						v-slot="{ valid }"
						tag="form"
						class="form"
						@submit.prevent
					>
						<div class="input-fields">
							<AppInput
								v-model="form.internalName"
								:label="$t('creatives.types.facebook.internalName')"
							/>
							<AppTextarea
								v-model="form.primaryCopy"
								:label="`1 – ${$t('creatives.types.facebook.primaryCopy')}`"
								:rules="{ required: true }"
								:character-count="125"
								rows="5"
							/>
							<FileUploader
								v-model="form.files"
								:label="`2 – ${$t('input.label.uploadImage')}`"
								:rules="{ required: true }"
								:recommandations="{
									minSizes: [[1000, 1000]],
									maxSizes: [[1200, 1200]]
								}"
								@recommandationCheck="onRecommandationCheck"
							>
								<template #beforeList>
									<NotificationItem
										class="has-gap-top-2 has-gap-bottom-15"
										:closeable="false"
									>
										<p
											v-html="
												$t(
													'creatives.types.facebook.notification.imageRecommandations'
												)
											"
										/>
									</NotificationItem>
								</template>
								<template #afterList="{ hasMinRecommandationFails, hasMaxRecommandationFails }">
									<NotificationItem
										v-if="hasMinRecommandationFails"
										class="has-gap-top-2 has-gap-bottom-15"
										:notification="{ type: 'warning' }"
										:closeable="false"
									>
										<p
											v-html="
												$t(
													'creatives.types.facebook.notification.hasMinRecommandationFails'
												)
											"
										/>
									</NotificationItem>
									<NotificationItem
										v-if="hasMaxRecommandationFails"
										class="has-gap-top-2 has-gap-bottom-15"
										:notification="{ type: 'warning' }"
										:closeable="false"
									>
										<p
											v-html="
												$t(
													'creatives.types.facebook.notification.hasMaxRecommandationFails'
												)
											"
										/>
									</NotificationItem>
								</template>
							</FileUploader>
							<AppInput
								v-model="form.displayLink"
								:rules="{domain: true}"
								:label="`3 – ${$t('creatives.types.facebook.displayLink')}`"
							/>
							<AppInput
								v-model="form.headline"
								:label="`4 – ${$t('creatives.types.facebook.headline')}`"
								:rules="{ required: true }"
								:character-count="25"
							/>
							<AppInput
								v-model="form.description"
								:label="`5 – ${$t('creatives.types.facebook.description')}`"
								:character-count="30"
							/>
							<AppSelect
								v-model="form.callToAction"
								:options="callToActionOptions"
								option-value="id"
								option-label="label"
								:calculate-position="withPopper"
								:append-to-body="true"
								:label="`6 – ${$t('creatives.types.facebook.callToAction')}`"
								required
							/>
						</div>
						<div class="buttons is-row is-right is-reverse">
							<SunButton
								v-if="!editMode"
								width="condensed"
								:state="{ disabled: !(valid && imagesFitRecommandation) }"
								@click="onSubmit"
							>
								{{ $t("button.saveAndUpload") }}
							</SunButton>
							<SunButton
								v-if="editMode"
								width="condensed"
								:state="{ disabled: !(valid && hasChanges && imagesFitRecommandation) }"
								@click="onSubmit"
							>
								{{ $t("button.saveChanges") }}
							</SunButton>
							<SunButton
								width="condensed"
								appearance="borderless"
								color="gray"
								@click="cancel"
							>
								{{ $t("button.cancel") }}
							</SunButton>
						</div>
					</ValidationObserver>
				</div>
			</div>
		</template>
	</AppModal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ModalConfirm from '@/components/ModalConfirm.vue';
import ModalLoading from '@/components/ModalLoading.vue';
import FileUploader from '@/components/FileUploader.vue';
import CustomModalMixin from '@/plugins/CustomModals/mixin';
import NotificationItem from '@/components/NotificationItem.vue';
import ENDPOINTS from '@/utilities/Endpoints';
import { getObjectDiff, withPopper } from '@/utilities/Helpers';
import callToActionOptions from '@/utilities/FacebookCallToActions';
import CreativeImage, { fileToDWH } from '@/utilities/CreativeImage';
import CallAPI from '../plugins/CallAPI';

export default {
	name: 'ModalNewFacebookLinkAd',

	components: {
		ValidationObserver,
		FileUploader,
		NotificationItem,
	},

	mixins: [CustomModalMixin],

	props: {
		creative: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			form: {
				internalName: '',
				primaryCopy: '',
				displayLink: '',
				headline: '',
				description: '',
				callToAction: '',
				files: [],
			},
			imagesFitRecommandation: false,
			callToActionOptions,
			formSnapshot: {},
			uploaded: [],
			editMode: false,
			withPopper,
		};
	},

	computed: {
		modalTitle() {
			if (this.editMode) {
				return this.$t('creatives.types.facebok.modal.edit.title');
			}

			return this.$t('creatives.types.facebok.modal.new.title');
		},
		changes() {
			return getObjectDiff(this.form, this.formSnapshot);
		},
		hasChanges() {
			return Object.keys(this.changes).length > 0;
		},
	},

	mounted() {
		if (this.creative) {
			this.form.internalName = this.creative.Name || '';
			this.form.headline = this.creative.Title1;
			this.form.primaryCopy = this.creative.Text1;
			this.form.description = this.creative.Text2;
			this.form.callToAction = this.creative.Text3;
			this.form.displayLink = this.creative.Text4;
			this.form.files = [new CreativeImage('Image1', this.creative.Image1)];
			this.editMode = true;
			this.imagesFitRecommandation = true;
		}

		this.setFormSnapshot();
	},

	methods: {
		onRecommandationCheck(checks) {
			this.imagesFitRecommandation = !checks.hasAnyRecommandationFails;
		},
		async beforeCancel() {
			return new Promise((resolve) => {
				if (this.hasChanges) {
					this.$modal({
						component: ModalConfirm,
						props: {
							title: this.$t('modal.unsavedChanges.title'),
							text: this.$t('modal.unsavedChanges.text'),
							onConfirm: () => resolve(true),
							onCancel: () => resolve(false),
						},
					});
				} else {
					resolve(true);
				}
			});
		},

		async onSubmit() {
			const loadingModal = this.$modal({
				component: ModalLoading,
				props: {
					title: this.$t('modal.uploadingCreatives.title'),
					text: this.$t('modal.uploadingCreatives.text'),
				},
			});

			const images = (await Promise.all(this.form.files.map(fileToDWH))).reduce(
				(newObject, file, index) => {
					newObject[`Image${index + 1}`] = file;
					return newObject;
				},
				{},
			);

			try {
				const response = await CallAPI.callDWH({
					method: 'POST',
					path: this.creative
						? `${ENDPOINTS.Creatives}/${this.creative.Id}`
						: ENDPOINTS.Creatives,
					data: {
						CreativeType: 'LinkAd',
						Name: this.form.internalName,
						Title1: this.form.headline,
						Text1: this.form.primaryCopy,
						Text2: this.form.description,
						Text3: this.form.callToAction,
						Text4: this.form.displayLink,
						...images,
					},
				});
				this.$emit('success', response.data.Objects[0].ObjectData);
			} catch (error) {
				this.$emit('error', { error });
			} finally {
				loadingModal.close();
				this.$refs.modal.close();
			}
		},
		setFormSnapshot() {
			this.formSnapshot = { ...this.form };
		},
	},
};
</script>

<style lang="scss" scoped></style>
