import { getFileTypeByURL } from './Helpers';

class CreativeFile {
	constructor(key, url, type) {
		this.key = key;
		this.fileURL = url;
		this.width = 0;
		this.height = 0;
		this.fileLoaded = false;
		this.type = type || getFileTypeByURL(url);
	}

	async getImage() {
		const image = new Image();
		image.src = this.fileURL;

		return new Promise((resolve) => {
			image.addEventListener('load', () => {
				this.fileLoaded = true;
				resolve(image);
			});
		});
	}

	async getVideo() {
		const videoElement = document.createElement('video');
		videoElement.src = this.fileURL;

		return new Promise((resolve) => {
			videoElement.addEventListener('loadedmetadata', () => {
				this.fileLoaded = true;
				resolve(videoElement);
			});
		});
	}

	async getFile() {
		if (this.type === 'image') {
			return this.getImage();
		}

		if (this.type === 'video') {
			return this.getVideo();
		}

		return Promise.reject();
	}

	async getFileDimensions() {
		if (!(this.width && this.height)) {
			const image = await this.getFile();
			this.width = image.width || image.videoWidth;
			this.height = image.height || image.videoHeight;
		}

		return [this.width, this.height];
	}
}

export default CreativeFile;
