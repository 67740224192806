<template>
	<div class="topbar">
		<div class="topbar-left">
			<DevelopmentDetails />
		</div>
		<div class="topbar-right">
			<div class="topbar-menu">
				<div
					class="menu-item"
					:class="{ 'is-active': isUserMenuVisible }"
					@click="toggleUserMenu"
				>
					<FontAwesomeIcon
						:icon="['far', 'user']"
						class="item-icon"
					/>
				</div>
				<TopbarUserMenu
					v-if="isUserMenuVisible"
					v-click-outside="closeUserMenu"
					@item-click="closeUserMenu"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import DevelopmentDetails from '@/components/DevelopmentDetails.vue';
import TopbarUserMenu from '@/components/TopbarUserMenu.vue';
import ClickOutside from 'vue-click-outside';

export default {
	name: 'TheTopbar',

	components: {
		DevelopmentDetails,
		TopbarUserMenu,
	},

	directives: {
		ClickOutside,
	},

	data() {
		return {
			isUserMenuVisible: false,
		};
	},

	mounted() {
		// prevent click outside event with popupItem.
		this.popupItem = this.$el;
	},

	methods: {
		toggleUserMenu() {
			this.isUserMenuVisible = !this.isUserMenuVisible;
		},
		closeUserMenu() {
			this.isUserMenuVisible = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.topbar {
	position: fixed;
	display: flex;
	padding-right: $gap-8;
	padding-left: 300px + $gap-8;
	padding-top: $gap-25;
	width: 100%;
	box-sizing: border-box;
	z-index: 100;

	.topbar-right {
		margin-left: auto;
		position: relative;
	}

	.topbar-menu {
		.menu-item {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background-color: $color-white;
			box-shadow: $shadow-box-xnarrow;
			cursor: pointer;
			transition: $transition-default;

			.item-icon {
				color: color('gray', 600);
			}

			&:hover,
			&.is-active {
				box-shadow: $shadow-box-narrow;
			}
		}
	}
}

.usermenu {
	position: absolute;
	right: 0;
	top: 56px - 1px;
}
</style>
