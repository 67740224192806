import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	store.dispatch('auth/initAuthentication');

	const isAuthenticated = store.getters['auth/isAuthenticated'];
	const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
	const requiresGuest = to.matched.some((route) => route.meta.requiresGuest);

	try {
		if (requiresAuth) {
			if (!isAuthenticated) throw new Error('not.authenticated');
			await Promise.all([store.dispatch('user/fetchUsers')]);
		}

		if (requiresGuest && isAuthenticated) throw new Error('no.guest');

		return next();
	} catch (error) {
		if (error.message === 'no.guest') return next({ name: 'dashboard' });
		return next({ name: 'login', query: { redirectTo: to.fullPath } });
	}
});

router.afterEach((to) => {
	store.dispatch('setLayout', to.meta.layout);

	Vue.nextTick(() => {
		let title = 'LocalUp Fields';

		if (to.meta.title) {
			title = `${to.meta.title(to)} - ${title}`;
		}

		document.title = title;
	});
});

export default router;
