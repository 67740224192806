<template>
	<div class="view">
		<div class="view-header">
			<div class="header-item">
				<h1 class="view-title">
					<FontAwesomeIcon
						:icon="ICON_CREATIVE"
						class="title-icon"
					/>
					{{ $t('view.creatives.title') }}
				</h1>
				<p class="view-subtitle">
					{{ $t('view.creatives.subtitle') }}
				</p>
			</div>
		</div>
		<div class="view-body">
			<div class="box">
				<div class="box-navigation">
					<router-link
						v-for="(item, $index) in navigation"
						:key="$index"
						v-click-pulse
						:to="{ name: item.name }"
						class="navigation-item"
					>
						<InlineSvg
							v-if="item.icon"
							:src="item.icon"
							class="item-icon"
							width="18"
							height="18"
						/>
						<p class="item-label">
							{{ item.label }}
						</p>
						<p class="item-info">
							{{ $tc('creatives.count', count[item.id]) }}
						</p>
					</router-link>
				</div>
				<CreativeGallery
					ref="gallery"
					class="box-subsection is-12"
					:api-path="ENDPOINTS.Creatives"
					api-caller="dwh"
					data-path="Objects"
					pagination-path="Pagination"
					:append-params="appendParams"
					:transform="transformItems"
					:per-page="9"
					:classes="{
						gallery: 'box-section is-12',
						pagination: 'box-section is-small is-gray is-12',
					}"
					@create-button-click="onCreateButtonClick"
					@item-clicked="onItemClicked"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import CreativeGallery from '@/components/CreativeGallery.vue';
import AddCreativesMixin from '@/mixins/AddCreativesMixin';
import ENDPOINTS from '@/utilities/Endpoints';
import { ICON_CREATIVE } from '@/utilities/Icons';
import CreativeTypes from '@/utilities/CreativeTypes';
import CallAPI from '@/plugins/CallAPI';

export default {
	name: 'Creatives',

	components: {
		CreativeGallery,
	},

	mixins: [AddCreativesMixin],

	props: {
		creativeType: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			navigation: Object.values(CreativeTypes),
			count: {},
			ENDPOINTS,
			ICON_CREATIVE,
		};
	},

	computed: {
		appendParams() {
			return {
				order: 'CreationDate desc',
				creativeType: this.creativeType,
				predicates: 'State!=Deleted',
			};
		},
	},

	mounted() {
		this.fetchCreativesForCount();
	},

	methods: {
		fetchCreativesByCreativeType(creativeType) {
			return CallAPI.callDWH({
				path: ENDPOINTS.Creatives,
				params: {
					creativeType,
					order: 'CreationDate',
					take: 1,
					predicates: 'State!=Deleted',
				},
			});
		},

		fetchCreativesForCount() {
			this.navigation.forEach(async (navigationItem) => {
				const response = await this.fetchCreativesByCreativeType(navigationItem.id);
				this.$set(this.count, navigationItem.id, response.data.Pagination.Total);
			});
		},

		onCreateButtonClick() {
			this.$_openCreateCreativeModal({
				creativeType: this.creativeType,
				onSuccess: this.onCreatedSuccess,
			});
		},

		onCreatedSuccess() {
			this.$refs.gallery.reloadData();
			this.fetchCreativesForCount();
		},

		transformItems(items) {
			const transformedItems = items.map((item) => ({
				title: this.$moment.utc(item.CreationDate).local().format('YYYY-MM-DD, hh:mm:ss'),
				subtitle: item.Name || item.Title1,
				image: item.Image1,
				id: item.Id,
			}));

			return transformedItems;
		},

		onItemClicked(item) {
			this.$router.push({ name: 'creativeSingle', params: { creativeId: item.id } });
		},
	},
};
</script>

<style lang="scss" scoped>
.title-icon {
	color: color('tertiary', 300);
}

.pagination-buttons {
	justify-content: flex-end;
}
</style>
