import Vue from 'vue';

const PLACEHOLDER_DEFAULT = 'placeholder';

function displayPlaceholder(el, binding) {
	if (typeof binding.arg === 'boolean') return binding.arg;
	if (typeof binding.value === 'boolean') return binding.value;
	if (el.contentSnapshot) return el.contentSnapshot === el.innerHTML;
	return false;
}

Vue.directive('placeholder-loader', {
	bind(el, binding) {
		if (typeof binding.value === 'string') {
			el.innerHTML = binding.value;
		}

		if (!el.innerHTML.trim().length) {
			el.placeholderElement = document.createTextNode(PLACEHOLDER_DEFAULT);
			el.appendChild(el.placeholderElement);
			el.contentSnapshot = el.innerHTML;
		}

		if (displayPlaceholder(el, binding)) {
			el.classList.add('placeholder');
		}
	},
	componentUpdated(el, binding) {
		if (el.classList.contains('placeholder') && !displayPlaceholder(el, binding)) {
			if (el.placeholderElement) el.removeChild(el.placeholderElement);
			el.classList.remove('placeholder');
			delete el.placeholderElement;
		}
	},
});
