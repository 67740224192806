<template>
	<div class="view">
		<div class="view-header has-gap-small">
			<template v-if="isInPreviewMode">
				<div class="header-item">
					<SunButton
						color="gray"
						appearance="borderless"
						size="small"
						@click="onCancel"
					>
						<FontAwesomeIcon
							:icon="['far', 'chevron-left']"
							class="icon icon--before"
						/>
						{{ $t('button.cancelAndBackToDashboard') }}
					</SunButton>
				</div>
				<div class="header-item is-right">
					<SunButton
						color="secondary"
						appearance="borderless"
						size="small"
						@click="cancelCampaignPreview"
					>
						<FontAwesomeIcon
							:icon="['far', 'pen']"
							class="icon icon--before"
						/>
						{{ $t('button.editCampaignInformation') }}
					</SunButton>
				</div>
			</template>
			<template v-else>
				<div class="header-item">
					<SunButton
						v-if="!isInPreviewMode"
						color="gray"
						appearance="borderless"
						size="small"
						width="condensed"
						:state="{ disabled: isOnFirstStep }"
						@click="goToPreviousStep"
					>
						<FontAwesomeIcon
							:icon="['far', 'chevron-left']"
							class="icon icon--before"
						/>
						{{ $t('button.back') }}
					</SunButton>
				</div>
			</template>
		</div>
		<div class="view-body">
			<transition
				name="fade"
				mode="out-in"
			>
				<CampaignBox
					v-if="isInPreviewMode"
					:campaign="campaign"
					:stores="campaign.stores"
					@bookSuccess="onBookSuccess"
				/>
				<div
					v-else
					class="box is-row"
				>
					<div class="box-section is-9 is-flex is-column">
						<transition
							name="fade"
							mode="out-in"
						>
							<component
								:is="currentStepView"
								:step="currentStep"
								@submit="onSubmit"
								@cancel="onCancel"
							/>
						</transition>
					</div>
					<div class="box-section is-gray is-3">
						<strong class="section-text">
							{{ $t('section.label.realTimeCalculation') }}
						</strong>
						<p class="section-headline">
							{{ $t('section.label.budgetPerStore') }}
						</p>
						<strong class="section-text">
							{{ budgetInCurrency }}
						</strong>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import ModalConfirm from '@/components/ModalConfirm.vue';
import { mapGetters, mapActions } from 'vuex';
import store from '@/store';
import CampaignBox from '@/components/CampaignBox.vue';
import { ValidationObserver } from 'vee-validate';

export default {
	name: 'CampaignBook',

	components: {
		CampaignBox,
		ValidationObserver,
	},

	data() {
		return {
			isPreview: false,
			isSuccessfullyBooked: false,
		};
	},

	beforeRouteEnter(to, from, next) {
		store.dispatch('book/resetBookState');
		next();
	},

	beforeRouteLeave(to, from, next) {
		if (this.hasChanges && !this.isSuccessfullyBooked) {
			this.$modal({
				component: ModalConfirm,
				props: {
					title: this.$t('modal.unsavedChanges.title'),
					text: this.$t('modal.unsavedChanges.text'),
					onConfirm: () => next(),
					onCancel: () => next(from),
				},
			});
		} else {
			next();
		}
	},

	computed: {
		...mapGetters('book', [
			'currentStep',
			'currentStepIndex',
			'currentStepCount',
			'budgetInCurrency',
			'hasChanges',
			'isInPreviewMode',
			'campaign',
			'steps',
		]),
		currentStepView() {
			return this.currentStep.view;
		},
		currentStepTitle() {
			return `${this.currentStepCount}. ${this.currentStep.title}`;
		},
		isOnFirstStep() {
			return this.currentStepCount === 1;
		},
		isOnLastStep() {
			return this.currentStepCount === this.steps.length;
		},
	},

	methods: {
		...mapActions('book', [
			'setStep',
			'setPreviewMode',
			'resetBookState',
		]),
		showCampaignPreview() {
			this.currentStep.changeState('done');
			this.setPreviewMode(true);
		},
		cancelCampaignPreview() {
			this.currentStep.changeState('undone');
			this.setPreviewMode(false);
		},
		goToPreviousStep() {
			this.setStep(this.currentStepCount - 1);
		},
		goToNextStep() {
			this.currentStep.changeState('done');
			this.setStep(this.currentStepCount + 1);
		},
		onSubmit({ showPreview }) {
			if (showPreview) {
				this.showCampaignPreview();
			} else {
				this.goToNextStep();
			}
		},
		onCancel() {
			this.$router.push({ name: 'dashboard' });
		},
		onBookSuccess() {
			this.isSuccessfullyBooked = true;
			this.$router.push({ name: 'dashboard' });
			this.resetBookState();
		},
	},
};
</script>

<style lang="scss" scoped>
.form {
	margin-bottom: $gap-6;
}

.box {
	min-height: 540px;
}
</style>
