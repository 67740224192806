<template>
	<div class="box is-row">
		<div class="box-section is-4 is-flex is-column">
			<div class="section-middle">
				<AppIcon
					class="box-title"
					:icon="ICON_CAMPAIGN"
					color="secondary"
					size="large"
				>
					<span v-placeholder-loader>{{ campaignName }}</span>
				</AppIcon>
				<CampaignStatusTag
					v-if="campaign.State !== 'None'"
					v-placeholder-loader="!campaignFetched"
					:data="resolvedCampaign"
				/>
			</div>
			<div class="section-bottom">
				<div
					v-show="campaignFetched"
					class="buttons is-row"
				>
					<template v-if="campaign.State === 'None'">
						<SunButton
							width="condensed"
							@click="bookCampaign"
						>
							{{ $t('button.bookACampaign') }}
						</SunButton>
					</template>
					<template v-else>
						<SunButton
							color="gray"
							appearance="outlined"
							@click="toggleCampaignState"
						>
							{{ stateButtonLabel }}
						</SunButton>
					</template>
				</div>
			</div>
		</div>
		<div class="box-section is-gray is-8">
			<div class="columns">
				<div class="column">
					<p class="section-headline">
						{{ $t('section.label.dateRange') }}
					</p>
					<strong
						v-placeholder-loader="!campaignFetched"
						class="section-text"
					>
						{{ startDate }} – {{ endDate }}
					</strong>
					<p class="section-headline">
						{{ $t('section.label.budgetPerStore') }}
					</p>
					<strong
						v-placeholder-loader="!campaignFetched"
						class="section-text"
					>
						{{ budgetInCurrency }}
					</strong>
					<p class="section-headline">
						{{ $t('section.label.targetAudience') }}
					</p>
					<strong
						v-placeholder-loader="!campaignFetched"
						class="section-text"
					>
						{{ resolvedCampaign.Genders }}, {{ resolvedCampaign.MinAge }} – {{ resolvedCampaign.MaxAge }}
					</strong>
				</div>
				<div class="column">
					<p class="section-headline">
						{{ $t('section.label.creatives') }}
					</p>
					<div
						v-for="creative in resolvedCreatives"
						:key="creative.Id"
						class="creative-item"
						@click="$router.push({ name: 'creativeSingle', params: { creativeId: creative.Id } })"
					>
						<video
							v-if="creative.CreativeType === 'VideoAd'"
							class="creative-thumbnail"
						>
							<source :src="creative.Image1">
						</video>
						<ProgressiveImage
							v-else
							class="creative-thumbnail"
							:src="creative.Image1"
							:alt="creative.Title1"
						/>
						<span
							v-placeholder-loader:[!creativesFetched]="creative.Title1"
							class="creative-name"
						/>
					</div>
				</div>
				<div class="column">
					<p class="section-headline">
						{{ $t('section.label.stores') }}
					</p>
					<div class="stores-list">
						<div
							v-for="store in reducedStores"
							:key="store.Id"
							class="store-item"
						>
							<AppIcon
								size="small"
								:icon="ICON_STORE"
								color="quaternary"
							>
								<span v-placeholder-loader:[!storesFetched]="store.Name" />
							</AppIcon>
						</div>
					</div>
					<SunButton
						v-if="storesCount > storesToDisplayCount"
						width="condensed"
						appearance="borderless"
						color="secondary"
						size="small"
						@click="showAllStores"
					>
						{{ $t('button.manyMore', [(storesCount - storesToDisplayCount)]) }}
					</SunButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CampaignStatusTag from '@/components/CampaignStatusTag.vue';
import ENDPOINTS from '@/utilities/Endpoints';
import numeral from 'numeral';
import LoadingSVG from '@/assets/loading.svg';
import ModalLoading from '@/components/ModalLoading.vue';
import { compareTimeAndWait } from '@/utilities/Helpers';
import ModalConfirm from '@/components/ModalConfirm.vue';
import ProgressiveImage from '@/components/ProgressiveImage.vue';
import sortBy from 'lodash/sortBy';
import { ICON_CAMPAIGN, ICON_STORE } from '@/utilities/Icons';
import CallAPI from '../plugins/CallAPI';

const STORE_DISPLAY_LIMIT = 3;

export default {
	name: 'CampaignBox',

	components: {
		CampaignStatusTag,
		ProgressiveImage,
	},

	props: {
		campaign: {
			type: [Object, Function],
			required: true,
		},
		stores: {
			type: [Array, Function],
			default: () => ([]),
		},
		creatives: {
			type: Array,
			default: null,
		},
	},

	data() {
		return {
			storesToDisplayCount: STORE_DISPLAY_LIMIT,
			campaignFetched: false,
			storesFetched: false,
			creativesFetched: false,
			resolvedCampaign: {},
			resolvedStores: [
				{ Id: 0, Name: 'Placeholder Store 1' },
				{ Id: 1, Name: 'Placeholder Store 1' },
				{ Id: 2, Name: 'Placeholder Store 1' },
			],
			resolvedCreatives: [
				{ Id: 0, Title1: 'Placeholder Store 1', Image1: LoadingSVG },
			],
			loadingModal: null,
			ICON_CAMPAIGN,
			ICON_STORE,
		};
	},

	computed: {
		startDate() {
			const date = this.$moment.utc(this.resolvedCampaign.StartDate).local();
			return date.isValid() ? date.format('DD. MMM YYYY') : 'n/a';
		},
		endDate() {
			const date = this.$moment.utc(this.resolvedCampaign.EndDate).local();
			return date.isValid() ? date.format('DD. MMM YYYY') : 'n/a';
		},
		stateButtonLabel() {
			if (this.resolvedCampaign.State === 'Active') {
				return this.$t('button.pauseCampaign');
			}

			return this.$t('button.activateCampaign');
		},
		storesCount() {
			return this.resolvedStores.length;
		},
		reducedStores() {
			const sortedStores = sortBy(this.resolvedStores, ['Name']);
			return sortedStores.slice(0, this.storesToDisplayCount);
		},
		budgetInCurrency() {
			return numeral(this.resolvedCampaign.Budget).format('(0,0.00)$');
		},
		campaignName() {
			let campaignName = '';

			if (this.resolvedCampaign.NamePrefix) {
				campaignName += `${this.resolvedCampaign.NamePrefix} `;
			}

			if (this.resolvedCampaign.Name) {
				campaignName += this.resolvedCampaign.Name;
			}

			return campaignName;
		},
	},

	async mounted() {
		Promise.resolve(typeof this.campaign === 'function' ? this.campaign() : this.campaign).then((campaign) => {
			this.resolvedCampaign = campaign;
			this.campaignFetched = true;
			this.fetchCreatives();
		});

		Promise.resolve(typeof this.stores === 'function' ? this.stores() : this.stores).then((stores) => {
			this.resolvedStores = stores;
			this.storesFetched = true;
		});
	},

	methods: {
		async toggleCampaignState() {
			try {
				const newState = this.resolvedCampaign.State === 'Active' ? 'Paused' : 'Active';
				const response = await CallAPI.callDWH({
					method: 'POST',
					path: `${ENDPOINTS.Campaigns}/${this.resolvedCampaign.Id}`,
					data: {
						State: newState,
					},
				});

				if (!response.data.Objects[0].Success) throw new Error('campaign.update.failed');

				this.$notify({
					type: 'success',
					text: this.$t('notification.success.campignStateChanges', [newState]),
				});

				this.resolvedCampaign.State = newState;
			} catch (error) {
				this.$notify({
					type: 'error',
					text: error.message,
				});
			}
		},
		showAllStores() {
			this.storesToDisplayCount = this.resolvedStores.length;
		},
		async fetchCreatives() {
			if (this.creatives) {
				this.resolvedCreatives = this.creatives;
				return;
			}

			const creativesToFetch = [this.resolvedCampaign.CreativeLink_Id].map((creativeLinkId) => CallAPI.callDWH({
				method: 'GET',
				path: `${ENDPOINTS.Creatives}/${creativeLinkId}`,
			}));

			const fetchedCreatives = (await Promise.all(creativesToFetch)).map((response) => response.data.Objects[0]);
			this.resolvedCreatives = fetchedCreatives;
			this.creativesFetched = true;
		},
		async bookCampaign() {
			try {
				await new Promise((resolve, reject) => {
					this.$modal({
						component: ModalConfirm,
						props: {
							title: this.$t('modal.confirmBooking.title'),
							text: this.$t('modal.confirmBooking.text'),
							onConfirm: () => resolve(),
							onCancel: () => reject(new Error('bookCampaign.canceledByUser')),
						},
					});
				});

				const timeBeforeBookingProcess = this.$moment();

				this.loadingModal = this.$modal({
					component: ModalLoading,
					props: {
						title: this.$t('modal.bookingCamapaign.title'),
						text: this.$t('modal.bookingCamapaign.text'),
					},
				});

				// Erstellen des Campaign "Templates"
				const campaignTemplate = (await CallAPI.callDWH({
					method: 'POST',
					path: ENDPOINTS.Campaigns,
					data: { ...this.resolvedCampaign, Name: this.campaignName, State: 'Active' },
				})).data.Objects[0];

				// Erstellen eines Arrays für bulk add "LocationBookingImport"
				const storeBookings = this.resolvedStores.map((store) => ({
					LocationLink_Id: store.AtomId,
					CampaignLink_Id: campaignTemplate.Id,
					State: 'Active',
				}));

				// Bulk add "LocationBookingImport"
				await CallAPI.callDWH({
					method: 'POST',
					path: ENDPOINTS.LocationBookingImport,
					data: storeBookings,
				});

				await compareTimeAndWait(timeBeforeBookingProcess, this.$moment(), 3000);

				this.$notify({
					type: 'success',
					text: this.$t('notification.success.campaignBooked'),
				});

				this.$emit('bookSuccess');
			} catch (error) {
				if (error.message === 'bookCampaign.canceledByUser') {
					this.$notify({
						type: 'error',
						text: this.$t('bookCampaign.canceledByUser'),
					});
				}

				this.$emit('bookError');
			} finally {
				if (this.loadingModal) this.loadingModal.close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.store-item {
	margin-bottom: $gap-1;
}

.section-bottom {
	margin-top: $gap-5;
}

.creative-item {
	display: flex;
	align-items: center;

	.creative-thumbnail {
		width: 40px;
		height: 40px;
		object-fit: contain;
		border: 1px solid color('gray', 200);
		margin-right: $gap-15;
		border-radius: $border-radius;
		transition: $transition-default;
	}

	.creative-name {
		font-size: $size-8;
		font-weight: 600;
	}

	&:hover {
		cursor: pointer;

		.creative-thumbnail {
			box-shadow: $shadow-box-narrow;
		}
	}
}

.stores-list {
	max-height: 560px;
	overflow: auto;
}
</style>
