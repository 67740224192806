<template>
	<div class="field-wrapper">
		<AppIcon
			:icon="ICON_STORE"
			color="quaternary"
		>
			<span :class="{ placeholder: isPlaceholder }">{{ data }}</span>
		</AppIcon>
	</div>
</template>

<script>
import TableFieldMixin from '@/mixins/TableFieldMixin';
import { ICON_STORE } from '@/utilities/Icons';

export default {
	name: 'TableFieldStore',

	mixins: [TableFieldMixin],

	props: {
		data: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			ICON_STORE,
		};
	},
};
</script>

<style lang="scss" scoped>
.field-wrapper {
	display: flex;
	align-items: center;

	.icon-box {
		margin-right: $gap-15;
	}
}
</style>
