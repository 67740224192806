<template>
	<div>
		<ul class="sidebar-navigation">
			<li
				v-for="item in navigationItems"
				:key="item.name"
				class="navigation-item"
			>
				<router-link
					v-click-pulse:light
					:to="item.link"
					class="item-link"
				>
					<FontAwesomeIcon
						:icon="item.icon"
						class="item-icon"
					/>
					{{ item.name }}
				</router-link>
			</li>
		</ul>
		<div class="buttons">
			<router-link :to="{ name: 'campaignBook' }">
				<SunButton width="fullwidth">
					{{ $t('button.bookACampaign') }}
				</SunButton>
			</router-link>
		</div>
	</div>
</template>

<script>
import { firstLevelNavigationItems } from '@/utilities/FirstLevelNavigation';

export default {
	name: 'SidebarNavigation',

	data() {
		return {
			navigationItems: firstLevelNavigationItems,
		};
	},
};
</script>

<style lang="scss" scoped>
.navigation-item {
	.item-link {
		display: flex;
		align-items: center;
		height: 46px;
		padding: 0 $gap-25;
		text-decoration: none;
		color: $color-white;
		border-radius: $border-radius;
		opacity: 0.5;
		transition: $transition-default;

		&.router-link-active {
			background-color: color('secondary', 800);
			opacity: 1;
		}

		&:hover {
			background-color: color('secondary', 800);
		}
	}

	.item-icon {
		width: 18px;
		height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: $gap-25;
	}

	&:not(:last-child) {
		margin-bottom: $gap-1;
	}
}

.buttons {
	margin-top: $gap-5;

	.button {
		width: 100%;
	}
}
</style>
