<template>
	<span :class="{ placeholder: isPlaceholder }">
		{{ startDate }} – {{ endDate }}
	</span>
</template>

<script>
import TableFieldMixin from '@/mixins/TableFieldMixin';

export default {
	name: 'TableFieldDuration',

	mixins: [TableFieldMixin],

	computed: {
		startDate() {
			const date = this.$moment.utc(this.row.StartDate).local();
			return date.isValid() ? date.format('DD/MM/YY') : 'n/a';
		},
		endDate() {
			const date = this.$moment.utc(this.row.EndDate).local();
			return date.isValid() ? date.format('DD/MM/YY') : 'n/a';
		},
		isPlaceholder() {
			return !Object.keys(this.row).includes('StartDate') && !Object.keys(this.row).includes('EndDate');
		},
	},
};
</script>

<style>

</style>
