<template>
	<div class="view">
		<div class="view-header">
			<div class="header-item">
				<h1 class="view-title">
					<FontAwesomeIcon
						:icon="ICON_CAMPAIGN"
						class="title-icon"
					/>
					{{ $t('view.campaigns.title') }}
				</h1>
				<p class="view-subtitle">
					{{ $t('view.campaigns.subtitle') }}
				</p>
			</div>
			<div class="header-item is-right">
				<AppInput
					v-model="searchByName"
					:placeholder="$t('input.placeholder.searchByName')"
					size="small"
					color="white-shadow"
					:width="200"
					:icon-after="{
						src: ['far', 'search']
					}"
					@input="loadTableData(500)"
				/>
			</div>
		</div>
		<div class="view-body">
			<AppTable
				ref="table"
				:columns="tableColumns"
				:api-path="ENDPOINTS.Campaigns"
				data-path="Objects"
				pagination-path="Pagination"
				api-caller="dwh"
				:show-pagination="true"
				:row-click-handler="rowClickHandler"
				:append-params="appendParams"
				layout="fixed"
			/>
		</div>
	</div>
</template>

<script>
import ENDPOINTS from '@/utilities/Endpoints';
import DetailedCampaignsColumns from '@/utilities/DetailedCampaignsColumns';
import { ICON_CAMPAIGN } from '@/utilities/Icons';

export default {
	name: 'Campaigns',

	components: {
	},

	data() {
		return {
			tableColumns: DetailedCampaignsColumns,
			searchByName: '',
			loadDataTimeout: null,
			ENDPOINTS,
			ICON_CAMPAIGN,
		};
	},

	computed: {
		appendParams() {
			const appendParams = {
				order: 'CreationDate desc',
			};

			if (this.searchByName) appendParams.name = `%${this.searchByName}%`;

			return appendParams;
		},
	},

	methods: {
		rowClickHandler(row) {
			this.$router.push({ name: 'campaignSingle', params: { campaignId: row.Id } });
		},
		loadTableData(timeout = 0) {
			clearTimeout(this.loadDataTimeout);

			this.loadDataTimeout = setTimeout(() => {
				this.$refs.table.loadData();
			}, timeout);
		},
	},
};
</script>

<style lang="scss" scoped>
.title-icon {
	color: color('secondary', 400);
}
</style>
