<template>
	<div class="widget">
		<div class="widget-header">
			<div class="header-item">
				<p class="widget-title">
					<FontAwesomeIcon
						:icon="ICON_STORE"
						class="title-icon"
					/>
					{{ $t('widget.stores.title') }}
				</p>
			</div>
		</div>
		<div class="widget-body">
			<AppTable
				ref="apptable"
				:columns="tableColumns"
				:api-path="ENDPOINTS.LocationImport"
				data-path="Objects"
				pagination-path="Pagination"
				api-caller="dwh"
				:show-header="false"
				:per-page="5"
			>
				<template #table-footer>
					<div class="footer-item has-no-padding is-fullwidth is-centered">
						<SunButton
							color="gray"
							size="small"
							appearance="borderless"
							@click="$router.push({ name: 'stores' })"
						>
							{{ $t('button.seeAll') }}
						</SunButton>
					</div>
				</template>
			</AppTable>
		</div>
	</div>
</template>

<script>
import TableFieldStore from '@/components/TableFieldStore.vue';
import ENDPOINTS from '@/utilities/Endpoints';
import { ICON_STORE } from '@/utilities/Icons';

export default {
	name: 'WidgetStores',

	data() {
		return {
			tableColumns: [
				{
					field: 'Name',
					label: 'Title',
					component: TableFieldStore,
				},
			],
			ENDPOINTS,
			ICON_STORE,
		};
	},
};
</script>

<style lang="scss" scoped>
.title-icon {
	color: $color-stores;
}
</style>
