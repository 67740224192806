<template>
	<ValidationProvider
		ref="validationProvider"
		v-slot="{ errors, classes }"
		:mode="mode"
		:rules="rules"
		:vid="vid"
		:name="labelObject.name"
		slim
	>
		<div
			class="input-wrapper"
			:class="{ ...wrapperClasses, ...classes, 'has-input-counter': remainingCharacters, 'has-icon-before': hasIconBefore, 'has-icon-after': hasIconAfter, 'is-aggressive-touch': mode === 'aggressive-touch' }"
		>
			<label
				v-if="label"
				for=""
				class="input-label"
				:class="labelClasses"
			>
				{{ labelObject.name }}
			</label>
			<div
				class="input-field-wrapper"
				:style="{ width: width ? `${width}px` : '' }"
			>
				<div
					v-if="hasIconBefore"
					class="input-icon-wrapper input-icon-wrapper--before"
				>
					<FontAwesomeIcon
						v-if="hasIconBefore"
						:icon="iconBefore.src"
						:style="{ color: iconBefore.fill }"
						class="input-icon input-icon--before"
					/>
				</div>
				<input
					ref="input"
					:value="value"
					:type="type"
					:tabindex="tabindex"
					class="input-field"
					:class="inputClasses"
					:placeholder="placeholder"
					:disabled="disabled"
					:readonly="readOnly"
					@focus="onFocus"
					@blur="onBlur"
					@input="onInput"
					@change="onChange"
					@keypress.enter="onSubmit"
				>
				<div
					v-if="hasIconAfter"
					class="input-icon-wrapper input-icon-wrapper--after"
				>
					<FontAwesomeIcon
						v-if="hasIconAfter"
						:icon="iconAfter.src"
						:style="{ color: iconAfter.fill }"
						class="input-icon input-icon--after"
					/>
				</div>
				<div
					v-if="remainingCharacters"
					class="input-count"
				>
					{{ remainingCharacters }}
				</div>
			</div>
			<div
				v-if="$_showErrors(errors, classes)"
				class="input-message"
			>
				<span data-cy="error-message">{{ errors[0] }}</span>
			</div>
		</div>
	</ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import InputMixin from '@/mixins/InputMixin';

export default {
	name: 'AppInput',

	components: {
		ValidationProvider,
	},

	mixins: [InputMixin],

	props: {
		value: {
			type: [String, Number],
			required: true,
		},
		type: {
			type: String,
			default: 'text',
		},
		tabindex: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		iconBefore: {
			type: Object,
			default: () => ({}),
		},
		iconAfter: {
			type: Object,
			default: () => ({}),
		},
		rules: {
			type: [String, Object],
			default: '',
		},
		vid: {
			type: String,
			default: '',
		},
		mode: {
			type: [String, Function],
			default: 'eager',
		},
		emitType: {
			type: String,
			default: 'value',
			validator: (value) => ['value', 'event'].indexOf(value) !== -1,
		},
		characterCount: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			hasFocus: false,
		};
	},
	computed: {
		hasIconBefore() {
			return Object.keys(this.iconBefore).length > 0;
		},
		hasIconAfter() {
			return Object.keys(this.iconAfter).length > 0;
		},
		remainingCharacters() {
			if (this.characterCount < 1) {
				return null;
			}

			return this.characterCount - this.value.length;
		},
	},
	methods: {
		onFocus() {
			this.$emit('focus');
		},
		onBlur(event) {
			this.$emit('blur', event);
		},
		onInput(event) {
			this.$emit('input', this.emitType === 'event' ? event : event.target.value);
		},
		onChange(event) {
			this.$emit('change', event);
		},
		onSubmit(event) {
			this.$emit('submit', event);
		},
	},
};
</script>
