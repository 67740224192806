<template>
	<div class="view">
		<div class="view-header">
			<div class="header-item">
				<h1 class="view-title">
					<FontAwesomeIcon
						:icon="ICON_DASHBOARD"
						class="title-icon"
					/>
					{{ $t('view.dashboard.welcomeMessage', ['User']) }}
				</h1>
				<p class="view-subtitle">
					{{ $t('view.dashboard.subtitle') }}
				</p>
			</div>
		</div>
		<div class="view-body">
			<div class="widgets">
				<WidgetActiveCampaigns class="is-9" />
				<WidgetStores class="is-3" />
			</div>
		</div>
	</div>
</template>

<script>
import WidgetActiveCampaigns from '@/components/WidgetActiveCampaigns.vue';
import WidgetStores from '@/components/WidgetStores.vue';
import { ICON_DASHBOARD } from '@/utilities/Icons';

export default {
	name: 'Dashboard',

	components: {
		WidgetActiveCampaigns,
		WidgetStores,
	},

	data() {
		return {
			ICON_DASHBOARD,
		};
	},
};
</script>

<style lang="scss" scoped>
.title-icon {
	color: color('primary', 300);
}
</style>
