import Vue from 'vue';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import VTooltip from 'v-tooltip';
import App from './App.vue';
import router from './router';
import store from './store';
import CustomModals from './plugins/CustomModals';
import i18n from './plugins/i18n';
import './plugins/GlobalComponents';
import './plugins/Notifications';
import './plugins/Numeral';
import './plugins/VeeValidate';
import './plugins/PlaceholderLoaderDirective';
import './plugins/ClickPulseDirective';
import './plugins/FontAwesome';

Sentry.init({
	dsn: 'https://649c954c8e4f45deba657b9a93f90ec1@o440428.ingest.sentry.io/5409363',
	integrations: [
		new VueIntegration({
			Vue,
			tracing: true,
			attachProps: true,
		}),
		new Integrations.BrowserTracing(),
	],
	tracesSampleRate: 1,
	environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
	release: process.env.VUE_APP_SENTRY_RELEASE,
});

Vue.use(CustomModals);
Vue.use(VTooltip);

Object.defineProperty(Vue.prototype, '$moment', { get() { return this.$root.moment; } });

new Vue({
	router,
	store,

	data: {
		moment,
	},

	i18n,
	render: (h) => h(App),
}).$mount('#app');
