<template>
	<AppModal
		ref="modal"
		size="wide"
		:before-cancel="beforeCancel"
		@afterClose="onAfterClose"
	>
		<template #title>
			{{ modalTitle }}
		</template>
		<template #body>
			<div class="columns">
				<div class="content column is-4">
					<img src="@/assets/link-ad-example.svg">
					<p class="headline is-5 has-gap-top-1">
						{{ $t('creatives.types.carousel.scheme.title') }}
					</p>
					<p>
						<i18n path="creatives.types.facebook.scheme.moreText">
							<!-- eslint-disable -->
							<a href="https://www.facebook.com/business/ads-guide/image" target="_blank">{{ $t('creatives.types.facebook.scheme.moreLink') }}</a>
							<!-- eslint-enable -->
						</i18n>
					</p>
				</div>
				<div class="column">
					<ValidationObserver
						ref="observer"
						v-slot="{ valid }"
						tag="form"
						class="form"
						@submit.prevent
					>
						<div class="input-fields">
							<AppInput
								v-model="form.internalName"
								:label="$t('creatives.types.facebook.internalName')"
							/>
							<AppTextarea
								v-model="form.primaryCopy"
								:label="`1 – ${$t('creatives.types.facebook.primaryCopy')}`"
								:rules="{ required: true }"
								:character-count="125"
								rows="5"
							/>
							<div class="accordion-label">
								{{ $t('creatives.types.carouselAd.cards') }}
							</div>
							<div class="accordion">
								<div
									v-for="(card, $index) in form.cards"
									:key="$index"
								>
									<button
										class="accordion-item-header"
										@click="activeCardIndex = $index"
									>
										<p>Card {{ $index + 1 }}: {{ card.title }}</p>
										<SunButton
											v-if="form.cards.length > 2"
											width="condensed"
											size="small"
											appearance="borderless"
											color="gray"
											@click="removeCarouselCard($index)"
										>
											{{ $t('button.removeCarouselCard') }}
										</SunButton>
									</button>
									<div
										v-show="$index === activeCardIndex"
										class="accordion-item-body"
									>
										<FileUploader
											v-model="card.image"
											:label="`2 – ${$t('input.label.uploadImage')}`"
											:rules="{ required: true }"
											:recommandations="{
												minSizes: [
													[1000,1000]
												],
												maxSizes: [
													[1200, 1200]
												],
											}"
											@recommandationCheck="onRecommandationCheck"
										>
											<template #beforeList>
												<NotificationItem
													class="has-gap-top-2 has-gap-bottom-15"
													:closeable="false"
												>
													<p v-html="$t('creatives.types.facebook.notification.imageRecommandations')" />
												</NotificationItem>
											</template>
											<template #afterList="{ hasMinRecommandationFails, hasMaxRecommandationFails }">
												<NotificationItem
													v-if="hasMinRecommandationFails"
													class="has-gap-top-2 has-gap-bottom-15"
													:notification="{ type: 'warning' }"
													:closeable="false"
												>
													<p v-html="$t('creatives.types.facebook.notification.hasMinRecommandationFails')" />
												</NotificationItem>
												<NotificationItem
													v-if="hasMaxRecommandationFails"
													class="has-gap-top-2 has-gap-bottom-15"
													:notification="{ type: 'warning' }"
													:closeable="false"
												>
													<p v-html="$t('creatives.types.facebook.notification.hasMaxRecommandationFails')" />
												</NotificationItem>
											</template>
										</FileUploader>
										<AppInput
											v-model="card.link"
											:vid="`card-${$index}-link`"
											:rules="{domain: true}"
											:label="`3 – ${$t('creatives.types.facebook.displayLink')}`"
										/>
										<AppInput
											v-model="card.title"
											:vid="`card-${$index}-title`"
											:label="`4 – ${$t('creatives.types.facebook.headline')}`"
											:rules="{ required: true }"
											:character-count="25"
										/>
										<AppInput
											v-model="card.text"
											:vid="`card-${$index}-text`"
											:label="`5 – ${$t('creatives.types.facebook.description')}`"
											:character-count="30"
										/>
										<AppSelect
											v-model="card.callToAction"
											:options="callToActionOptions"
											option-value="id"
											option-label="label"
											:calculate-position="withPopper"
											:append-to-body="true"
											:vid="`card-${$index}-callToAction`"
											:label="`6 – ${$t('creatives.types.facebook.callToAction')}`"
											required
										/>
									</div>
								</div>
								<div v-if="form.cards.length < 10">
									<SunButton
										appearance="borderless"
										width="fullwidth"
										@click="addCarouselCard"
									>
										{{ $t('button.addCarouselCard') }}
									</SunButton>
								</div>
							</div>
						</div>
						<div class="buttons is-row is-right is-reverse">
							<SunButton
								v-if="!editMode"
								width="condensed"
								:state="{ disabled: !(valid && imagesFitRecommandation)}"
								@click="onSubmit"
							>
								{{ $t('button.saveAndUpload') }}
							</SunButton>
							<SunButton
								v-if="editMode"
								width="condensed"
								:state="{ disabled: !(valid && hasChanges && imagesFitRecommandation) }"
								@click="onSubmit"
							>
								{{ $t('button.saveChanges') }}
							</SunButton>
							<SunButton
								width="condensed"
								appearance="borderless"
								color="gray"
								@click="cancel"
							>
								{{ $t('button.cancel') }}
							</SunButton>
						</div>
					</ValidationObserver>
				</div>
			</div>
		</template>
	</AppModal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ModalConfirm from '@/components/ModalConfirm.vue';
import ModalLoading from '@/components/ModalLoading.vue';
import FileUploader from '@/components/FileUploader.vue';
import CustomModalMixin from '@/plugins/CustomModals/mixin';
import NotificationItem from '@/components/NotificationItem.vue';
import ENDPOINTS from '@/utilities/Endpoints';
import { capitalizeFirstLetter, lowercaseFirstLetter, withPopper } from '@/utilities/Helpers';
import callToActionOptions from '@/utilities/FacebookCallToActions';
import CreativeImage, { fileToDWH } from '@/utilities/CreativeImage';
import CallAPI from '../plugins/CallAPI';

const createEmptyCarouselCard = () => ({
	image: [],
	title: '',
	text: '',
	link: '',
	callToAction: '',
});

export default {
	name: 'ModelNewCarouselAd',

	components: {
		ValidationObserver,
		FileUploader,
		NotificationItem,
	},

	mixins: [CustomModalMixin],

	props: {
		creative: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			form: {
				internalName: '',
				primaryCopy: '',
				cards: [
					createEmptyCarouselCard(),
					createEmptyCarouselCard(),
				],
			},
			callToActionOptions,
			imagesFitRecommandation: false,
			formSnapshot: {},
			uploaded: [],
			editMode: false,
			activeCardIndex: 0,
			withPopper,
		};
	},

	computed: {
		modalTitle() {
			if (this.editMode) {
				return this.$t('creatives.types.carousel.modal.edit.title');
			}

			return this.$t('creatives.types.carousel.modal.new.title');
		},

		hasChanges() {
			return JSON.stringify(this.form) !== JSON.stringify(this.formSnapshot);
		},
	},

	mounted() {
		if (this.creative) {
			this.form.internalName = this.creative.Name || '';
			this.form.primaryCopy = this.creative.Message;
			this.form.cards = this.DWHtoCards(this.creative);
			this.editMode = true;
		}

		this.setFormSnapshot();
	},

	methods: {
		onRecommandationCheck(checks) {
			this.imagesFitRecommandation = !checks.hasAnyRecommandationFails;
		},
		async beforeCancel() {
			return new Promise((resolve) => {
				if (this.hasChanges) {
					this.$modal({
						component: ModalConfirm,
						props: {
							title: this.$t('modal.unsavedChanges.title'),
							text: this.$t('modal.unsavedChanges.text'),
							onConfirm: () => resolve(true),
							onCancel: () => resolve(false),
						},
					});
				} else {
					resolve(true);
				}
			});
		},

		DWHtoCards(input) {
			const cards = Object.entries(input).reduce((cardObject, [key, value]) => {
				const cardCount = key.match(/\d/);

				if (!cardCount || !value) {
					return cardObject;
				}

				const countObject = cardObject[cardCount[0]] ? cardObject[cardCount[0]] : createEmptyCarouselCard();
				const normalizedKey = lowercaseFirstLetter(key.replace(/\d/, ''));
				const normalizedValue = normalizedKey === 'image' ? [new CreativeImage('image', value)] : value;

				Object.assign(countObject, { [normalizedKey]: normalizedValue });

				return {
					...cardObject,
					[cardCount]: countObject,
				};
			}, {});

			return Object.values(cards);
		},

		async cardsToDWH(cards) {
			const withResolvedImages = await Promise.all(cards.map(async (card) => ({
				...card,
				image: await fileToDWH(card.image[0]),
			})));

			return withResolvedImages.reduce((object, card, index) => {
				const cardAsJson = Object.keys(card).reduce((jsonObject, key) => ({
					...jsonObject,
					[`${capitalizeFirstLetter(key)}${index + 1}`]: card[key],
				}), {});

				return {
					...object,
					...cardAsJson,
				};
			}, {});
		},

		async onSubmit() {
			const loadingModal = this.$modal({
				component: ModalLoading,
				props: {
					title: this.$t('modal.uploadingCreatives.title'),
					text: this.$t('modal.uploadingCreatives.text'),
				},
			});

			try {
				const resolvedCards = await this.cardsToDWH(this.form.cards);
				const entriesToDelete = this.getKeyToDelete(resolvedCards).reduce((object, key) => ({ ...object, [key]: '' }), {});

				const response = await CallAPI.callDWH({
					method: 'POST',
					path: this.creative ? `${ENDPOINTS.Creatives}/${this.creative.Id}` : ENDPOINTS.Creatives,
					data: {
						CreativeType: 'CarouselAd',
						Name: this.form.internalName,
						Message: this.form.primaryCopy,
						...resolvedCards,
						...entriesToDelete,
					},
				});

				this.$emit('success', response.data.Objects[0].ObjectData);
			} catch (error) {
				this.$emit('error', { error });
			} finally {
				loadingModal.close();
				this.$refs.modal.close();
			}
		},
		setFormSnapshot() {
			this.formSnapshot = { ...JSON.parse(JSON.stringify(this.form)) };
		},

		addCarouselCard() {
			this.form.cards.push(createEmptyCarouselCard());
			this.activeCardIndex = this.form.cards.length - 1;
		},

		removeCarouselCard(index) {
			const newCards = [...this.form.cards];
			newCards.splice(index, 1);

			this.form.cards = newCards;
		},

		getKeyToDelete(imagesToSubmit) {
			const currentlyUsedKeys = this.creative
				? Object.keys(this.creative).filter((key) => key.match(/(((I|i)mage)|((T|t)ext)|((C|c)allToAction)|((T|t)itle)|((L|l)ink))\d+/g) && this.creative[key])
				: [];

			const keyToDelete = currentlyUsedKeys.filter((key) => !Object.keys(imagesToSubmit).includes(key));

			return keyToDelete;
		},
	},
};
</script>

<style lang="scss" scoped>
.accordion {
	border: 1px solid color('gray', 200);
	border-radius: $border-radius;
	margin-bottom: $gap-4;
}

.accordion-label {
	font-size: $size-8;
	font-weight: 600;
	color: color('gray', 600);
	margin-bottom: $gap-1;
}

.accordion-item-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: color('gray', 50);
	padding: $gap-1 $gap-2;
	font-size: $size-8;
	font-weight: 600;
	color: color('gray', 600);
	appearance: none;
	outline: 0;
	border: 0;
	width: 100%;
	text-align: left;
	min-height: 53px;
	cursor: pointer;

	&:hover {
		background-color: color('gray', 200);
	}
}

.accordion-item-body {
	padding: $gap-2;
}
</style>
