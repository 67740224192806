import TableFieldStatusTag from '@/components/TableFieldStatusTag.vue';
import TableFieldDuration from '@/components/TableFieldDuration.vue';
import TableFieldCampaignNameWithCreativeType from '@/components/TableFieldCampaignNameWithCreativeType.vue';
import numeral from 'numeral';
import ENDPOINTS from '@/utilities/Endpoints';

export default [
	{
		field: 'CreativeType',
		label: 'Title',
		width: '25%',
		component: TableFieldCampaignNameWithCreativeType,
		source: {
			type: 'dwh',
			path: ENDPOINTS.Creatives,
			queryParams: {
				Id: ':CreativeLink_Id',
			},
		},
	},
	{
		field: 'EffectiveState',
		label: 'Status',
		component: TableFieldStatusTag,
	},
	{
		field: '',
		label: 'Duration',
		component: TableFieldDuration,
	},
	{
		field: 'Impressions',
		label: 'Impressions',
		align: 'right',
		formatter: (value) => numeral(value).format(),
		source: {
			type: 'x2x',
			path: ENDPOINTS.CentralInsights,
			queryParams: {
				OrderLink_Id: ':Id',
				timeframe: 'alltime',
			},
		},
	},
	{
		field: 'Cpm',
		label: 'Cpm',
		align: 'right',
		formatter: (value) => numeral(value).format('(0,0.00)$'),
		source: {
			type: 'x2x',
			path: ENDPOINTS.CentralInsights,
			queryParams: {
				OrderLink_Id: ':Id',
				timeframe: 'alltime',
			},
		},
	},
];
