import Dashboard from '@/views/Dashboard.vue';
import Stores from '@/views/Stores.vue';
import StoreSingle from '@/views/StoreSingle.vue';
import Campaigns from '@/views/Campaigns.vue';
import CampaignSingle from '@/views/CampaignSingle.vue';
import CampaignBook from '@/views/CampaignBook.vue';
import Login from '@/views/Login.vue';
import Settings from '@/views/Settings.vue';
import FormAccountSettings from '@/components/FormAccountSettings.vue';
import FormChangePassword from '@/components/FormChangePassword.vue';
import SidebarBreadcrumbs from '@/components/SidebarBreadcrumbs.vue';
import store from '@/store';
import creatives from './routes/creatives';

const routes = [
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/stores',
		name: 'stores',
		component: Stores,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/stores/:storeId',
		name: 'storeSingle',
		props: true,
		component: StoreSingle,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/campaigns',
		name: 'campaigns',
		component: Campaigns,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/campaigns/book',
		name: 'campaignBook',
		component: CampaignBook,
		meta: {
			requiresAuth: true,
			sidebar: SidebarBreadcrumbs,
		},
	},
	{
		path: '/campaigns/:campaignId',
		name: 'campaignSingle',
		props: true,
		component: CampaignSingle,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/settings',
		name: 'settings',
		component: Settings,
		redirect: { name: 'accountSettings' },
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: 'account',
				name: 'accountSettings',
				component: FormAccountSettings,
				meta: {
					title: () => 'Account Settings',
					requiresAuth: true,
				},
			},
			{
				path: 'password',
				name: 'changePassword',
				component: FormChangePassword,
				meta: {
					title: () => 'Change password',
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			requiresGuest: true,
			layout: 'blank-aside',
		},
	},
	{
		path: '/logout',
		name: 'logout',
		meta: {
			requiresAuth: true,
		},
		beforeEnter: async (to, from, next) => {
			await store.dispatch('auth/logoutUser');
			next({ name: 'login' });
		},
	},
	...creatives,
	{ path: '*', redirect: { name: 'dashboard' } },
];

export default routes;
