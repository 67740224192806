import {
	extend, setInteractionMode, localize,
} from 'vee-validate';
import {
	required, email, min, alpha, confirmed, alpha_spaces as alphaSpaces, digits, between, numeric,
} from 'vee-validate/dist/rules';
import de from 'vee-validate/dist/locale/de.json';
import parseUrl from 'parse-url';

localize('de', de);

// Install rules
extend('numeric', numeric);
extend('required', required);
extend('email', email);
extend('min', min);
extend('alpha', alpha);
extend('confirmed', confirmed);
extend('alpha_spaces', alphaSpaces);
extend('digits', digits);
extend('between', between);
extend('domain', (value) => {
	try {
		const parsedUrl = parseUrl(value, { defaultProtocol: 'https:' });

		if (!parsedUrl) {
			return 'Dieses Feld ist keine gültige URL.';
		}

		if (!parsedUrl.resource.match(/(\.com|\.de)/)) {
			return 'Die URL muss den Top-Level-Domainnamen .com oder .de enthalten';
		}

		return true;
	} catch (error) {
		return 'Dieses Feld ist keine gültige URL.';
	}
});

setInteractionMode('change', () => ({ on: ['change'] }));
setInteractionMode('aggressive-touch', () => ({
	on: ['input', 'change', 'blur'],
}));
