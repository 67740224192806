<template>
	<ValidationObserver
		ref="observer"
		v-slot="{ valid }"
		tag="div"
		class="box-section is-flex is-column is-gapless is-fullheight"
	>
		<BookCampaignHeader :step="step">
			<div class="buttons is-row is-right">
				<SunButton
					size="small"
					width="condensed"
					appearance="borderless"
					color="secondary"
					@click="onCreateButtonClick"
				>
					{{ $t('button.addNewCreative') }}
				</SunButton>
			</div>
		</BookCampaignHeader>
		<div class="section-middle">
			<ValidationProvider
				ref="validationProvider"
				:rules="{ required: true }"
				name="gallery"
				slim
			>
				<CreativeGallery
					ref="gallery"
					v-model="selectedCreatives"
					:api-path="ENDPOINTS.Creatives"
					api-caller="dwh"
					data-path="Objects"
					pagination-path="Pagination"
					:selectable="1"
					:append-params="appendParams"
					:transform="transformItems"
					:per-page="10"
					:show-create-button="false"
					:classes="{ pagination: ['has-gap'] }"
				/>
			</ValidationProvider>
		</div>
		<BookCampaignFooter
			:step="step"
			:valid="valid"
			v-on="$listeners"
		/>
	</ValidationObserver>
</template>

<script>
import CreativeGallery from '@/components/CreativeGallery.vue';
import AddCreativesMixin from '@/mixins/AddCreativesMixin';
import CampaignBookStepsMixin from '@/mixins/CampaignBookStepsMixin';
import { mapGetters, mapActions } from 'vuex';
import ENDPOINTS from '@/utilities/Endpoints';
import { ValidationProvider } from 'vee-validate';

export default {
	name: 'Creatives',

	components: {
		ValidationProvider,
		CreativeGallery,
	},

	mixins: [AddCreativesMixin, CampaignBookStepsMixin],

	data() {
		return {
			selectedCreatives: [],
			ENDPOINTS,
		};
	},

	computed: {
		...mapGetters('book', [
			'adPlatform',
		]),
		appendParams() {
			return {
				order: 'CreationDate desc',
				creativeType: this.adPlatform,
				predicates: 'State!=Deleted',
			};
		},
	},

	watch: {
		selectedCreatives(creatives) {
			this.updateCampaign({ CreativeLink_Id: creatives.length > 0 ? creatives[0].id : null });
		},
	},

	methods: {
		...mapActions('book', [
			'updateCampaign',
		]),

		transformItems(items) {
			const transformedItems = items.map((item) => ({
				title: this.$moment.utc(item.CreationDate).local().format('YYYY-MM-DD, hh:mm:ss'),
				subtitle: item.Name || item.Title1,
				image: item.Image1,
				id: item.Id,
			}));

			return transformedItems;
		},

		onCreateButtonClick() {
			this.$_openCreateCreativeModal({
				creativeType: this.adPlatform,
				onSuccess: this.onCreatedSuccess,
			});
		},

		onCreatedSuccess() {
			this.$refs.gallery.reloadData();
		},
	},
};
</script>

<style lang="scss" scoped>
.buttons {
	flex: 1;
}
</style>
