<template>
	<ValidationProvider
		ref="validationProvider"
		v-slot="{ errors, classes }"
		:mode="mode"
		:rules="{ required: required }"
		:vid="vid"
		:name="labelObject.name"
		slim
	>
		<div
			class="input-wrapper"
			:class="{ ...wrapperClasses, ...classes }"
		>
			<label
				v-if="label"
				for=""
				class="input-label"
				:class="labelClasses"
			>
				{{ labelObject.name }}
			</label>
			<div
				class="slider-wrapper"
				:class="{ 'is-rangeslider': isRangeSlider }"
			>
				<div
					v-if="isRangeSlider"
					class="slider-label"
				>
					{{ min }}
				</div>
				<VueSlider
					v-model="inputValue"
					v-bind="$attrs"
					:tooltip="tooltip"
					:min="min"
					:max="max"
					:dot-size="22"
					v-on="$listeners"
				>
					<template
						v-if="isRangeSlider"
						v-slot:process="{ start, end, style, index }"
					>
						<div
							class="vue-slider-process"
							:style="style"
						>
							<div
								:class="[
									'merge-tooltip',
								]"
							>
								{{ value[index] }} - {{ value[index + 1] }}
							</div>
						</div>
					</template>
				</VueSlider>
				<div
					v-if="isRangeSlider"
					class="slider-label"
				>
					{{ max }}
				</div>
			</div>
			<div
				v-if="$_showErrors(errors, classes)"
				class="input-message"
			>
				<span data-cy="error-message">{{ errors[0] }}</span>
			</div>
		</div>
	</ValidationProvider>
</template>

<script>
import VueSlider from 'vue-slider-component';
import { ValidationProvider } from 'vee-validate';
import InputMixin from '@/mixins/InputMixin';
import 'vue-slider-component/theme/default.css';

export default {
	name: 'AppSlider',

	components: {
		ValidationProvider,
		VueSlider,
	},

	mixins: [InputMixin],

	inheritAttrs: false,

	props: {
		value: {
			type: [Number, Array],
			default: 0,
		},
		required: {
			type: Boolean,
			default: false,
		},
		vid: {
			type: String,
			default: '',
		},
		mode: {
			type: String,
			default: 'lazy',
		},
		tooltip: {
			type: String,
			default: 'active',
		},
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: 100,
		},
	},

	computed: {
		inputValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		isRangeSlider() {
			return Array.isArray(this.value);
		},
	},
};
</script>

<style lang="scss" scoped>
.merge-tooltip {
	position: absolute;
	left: 50%;
	bottom: 100%;
	transform: translate(-50%, -$gap-15);
	font-size: $size-8;
	white-space: nowrap;
	color: color('gray', 700);
	box-shadow: $shadow-box-xnarrow;
	border: 1px solid color('gray', 200);
	padding: $gap-05 $gap-1;
	border-radius: 4px;
}

.slider-label {
	font-size: $size-8;
	color: color('gray', 500);
}

::v-deep .vue-slider-rail {
	background-color: color('gray', 200);
}

::v-deep .vue-slider-process {
	background-color: color('secondary', 400);
}

::v-deep .vue-slider-dot-handle {
	border: 1px solid color('gray', 200);
	box-shadow: $shadow-box-xnarrow;
}

.slider-wrapper {
	display: flex;
	align-items: center;

	.vue-slider {
		flex: 1;
	}

	&.is-rangeslider {
		margin-top: $gap-5;

		.vue-slider {
			margin-left: $gap-2;
			margin-right: $gap-2;
		}
	}
}
</style>
