<template>
	<ValidationProvider
		ref="validationProvider"
		v-slot="{ classes }"
		:rules="rules"
		:vid="vid"
		:name="labelObject.name"
		slim
	>
		<div
			class="input-wrapper"
			:class="{ ...wrapperClasses, ...classes }"
		>
			<label
				v-if="label"
				for=""
				class="input-label"
			>
				{{ labelObject.name }}
			</label>
			<slot
				name="beforeList"
				:hasAnyRecommandationFails="hasAnyRecommandationFails"
			/>
			<SunButton
				v-show="!hasFilesSelected"
				width="condensed"
				appearance="outlined"
				color="gray"
				@click="clickFileInput"
			>
				{{ buttonLabel }}
			</SunButton>
			<input
				ref="fileInput"
				class="file-input"
				type="file"
				:accept="inputAcceptType"
				:multiple="isMultipleUpload"
				@change="onFileChanged"
			>
			<div class="selected-list">
				<div
					v-for="(file, $index) in selectedFiles"
					:key="$index"
					class="list-item"
				>
					<div class="thumbnail-wrapper">
						<template v-if="!isFilePending(file)">
							<div
								class="thumbnail-delete"
								@click="removeFile(file)"
							>
								<FontAwesomeIcon
									class="delete-icon"
									:icon="['fal', 'times']"
								/>
							</div>
							<img
								v-if="file.type === 'image'"
								:src="getFileSrc(file)"
								class="item-thumbnail"
							>
							<video
								v-if="file.type === 'video'"
								class="item-thumbnail"
							>
								<source :src="getFileSrc(file)">
							</video>
						</template>
						<template v-else>
							<LoaderCheckmark
								:wrapper="true"
								background-color="rgba(255,255,255,0.7)"
							/>
						</template>
					</div>
					<div class="item-body">
						<p class="item-name">
							{{ getFileName(file) }}
						</p>
						<p class="item-status">
							<template v-if="!isFileAlreadyUploaded(file)">
								<span
									v-if="file.hasAnyRequirementFails()"
									class="status-error"
								>
									{{ $t('fileUploader.failedToAddUnsupportedSize') }}
								</span>
								<span v-else>{{ $t('fileUploader.readyForUpload', [`${file.width} × ${file.height}`]) }}</span>
							</template>
							<template v-else>
								<FontAwesomeIcon
									:icon="['far', 'check']"
									class="check-icon"
								/>
								{{ $t('fileUploader.uploaded') }}
							</template>
						</p>
					</div>
				</div>
			</div>
			<SunButton
				v-if="showAddMoreButton"
				class="addmore"
				appearance="borderless"
				color="secondary"
				size="small"
				@click="clickFileInput"
			>
				{{ $t('button.addMore') }}
			</SunButton>
			<slot
				name="afterList"
				:hasAnyRecommandationFails="hasAnyRecommandationFails"
				:hasMinRecommandationFails="hasMinRecommandationFails"
				:hasMaxRecommandationFails="hasMaxRecommandationFails"
			/>
		</div>
	</ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import LoaderCheckmark from '@/components/LoaderCheckmark.vue';
import ImageFile from '@/utilities/ImageFile';
import CreativeImage from '@/utilities/CreativeImage';
import i18n from '@/plugins/i18n';

export default {
	name: 'FileUploader',

	components: {
		ValidationProvider,
		LoaderCheckmark,
	},

	props: {
		value: {
			type: Array,
			default: () => [],
		},
		label: {
			type: [String, Object],
			required: false,
			default: '',
			validator: (value) => {
				if (typeof value === 'string') {
					return true;
				}

				if (value.name) {
					return true;
				}

				return false;
			},
		},
		buttonLabel: {
			type: String,
			default: i18n.t('button.browseImages'),
		},
		rules: {
			type: [String, Object],
			required: false,
			default: '',
		},
		vid: {
			type: String,
			required: false,
			default: '',
		},
		limit: {
			type: Number,
			default: 1,
		},
		recommandations: {
			type: Object,
			default: () => ({}),
		},
		requirements: {
			type: Object,
			default: () => ({}),
		},
		type: {
			type: String,
			default: 'image',
			validator: (value) => ['image', 'video'].includes(value),
		},
	},

	data() {
		return {
			selectedFiles: [],
			cachedFiles: [],
		};
	},

	computed: {
		wrapperClasses() {
			return {
				'has-label-left': this.labelObject.position === 'left',
				'is-small': this.size === 'small',
				'is-large': this.size === 'large',
			};
		},

		labelObject() {
			if (typeof this.label === 'string') {
				return {
					name: this.label,
				};
			}

			return this.label;
		},

		isMultipleUpload() {
			if (this.limit > 1) return true;
			if (this.limit === -1) return true;

			return false;
		},

		showAddMoreButton() {
			if (!this.hasFilesSelected) {
				return false;
			}

			if (this.limit === -1) {
				return true;
			}

			return !(this.filesCount >= this.limit);
		},

		hasFilesSelected() {
			return this.filesCount > 0;
		},

		filesCount() {
			return this.selectedFiles.length;
		},

		hasAnyRecommandationFails() {
			return this.selectedFiles.some((file) => (this.isFileAlreadyUploaded(file) ? false : file.hasAnyRecommandationFails()));
		},

		hasMaxRecommandationFails() {
			return this.selectedFiles.some((file) => (this.isFileAlreadyUploaded(file) ? false : file.hasMaxRecommandationFails()));
		},

		hasMinRecommandationFails() {
			return this.selectedFiles.some((file) => (this.isFileAlreadyUploaded(file) ? false : file.hasMinRecommandationFails()));
		},

		inputAcceptType() {
			if (this.type === 'video') {
				return 'video/*';
			}

			return 'image/*';
		},

		recommandationChecks() {
			return {
				hasAnyRecommandationFails: this.hasAnyRecommandationFails,
				hasMinRecommandationFails: this.hasMinRecommandationFails,
				hasMaxRecommandationFails: this.hasMaxRecommandationFails,
			};
		},
	},

	watch: {
		value: {
			handler(files) {
				this.selectedFiles = files;
			},
			deep: true,
		},
		selectedFiles: {
			handler(newFiles) {
				if (this.allResolved(newFiles) && this.hasNewFiles(newFiles, this.cachedFiles)) {
					this.sendFiles(newFiles);
				}
			},
			deep: true,
		},
		recommandationChecks(checks) {
			this.$emit('recommandationCheck', checks);
		},
	},

	methods: {
		async onFileChanged(event) {
			const constructedFiles = Array.from(event.target.files).map((file) => new ImageFile(file));

			constructedFiles.forEach((file) => {
				file.addChecks('recommandations', this.recommandations);
				file.addChecks('requirements', this.requirements);
				this.selectedFiles.push(file);
			});

			event.target.value = '';
		},

		allResolved(files) {
			return files.every((file) => (this.isFileAlreadyUploaded(file) ? true : file.isResolved()));
		},

		hasNewFiles(newFiles, oldFiles) {
			const newFilesIds = newFiles.map((file) => (this.isFileAlreadyUploaded(file) ? file : file.id));
			const oldFilesIds = oldFiles.map((file) => (this.isFileAlreadyUploaded(file) ? file : file.id));
			return !(newFilesIds.every((newFileId) => oldFilesIds.includes(newFileId)) && oldFilesIds.every((oldFileId) => newFilesIds.includes(oldFileId)));
		},

		async sendFiles(files) {
			const filteredFiles = files.filter((file) => (this.isFileAlreadyUploaded(file) ? true : !file.hasAnyRequirementFails()));
			this.$refs.validationProvider.validate(filteredFiles);
			this.cachedFiles = [...filteredFiles];
			this.$emit('input', filteredFiles);
			this.$emit('recommandationCheck', this.recommandationChecks);
		},

		clickFileInput() {
			this.$refs.fileInput.click();
		},

		removeFile(file) {
			const index = this.selectedFiles.findIndex((f) => f.id === file.id);
			this.selectedFiles.splice(index, 1);
		},

		isFilePending(file) {
			return this.isFileAlreadyUploaded(file) ? false : file.isPending();
		},

		getFileSrc(file) {
			return file.imageURL;
		},

		getFileName(file) {
			return this.isFileAlreadyUploaded(file) ? file.imageURL.replace(/^.*[\\/]/, '') : file.name;
		},

		isFileAlreadyUploaded(file) {
			return file instanceof CreativeImage;
		},
	},
};
</script>

<style lang="scss" scoped>
.file-input {
	display: none;
}

.delete-icon {
	font-size: $size-9;
	color: $color-white;
	transform: translateY(1px);
}

.selected-list {
	margin-top: $gap-15;

	.list-item {
		display: flex;
		align-items: center;
		margin-top: $gap-15;
		margin-bottom: $gap-15;
	}

	.item-status {
		display: flex;
		align-items: center;
		font-size: $size-9;
		color: color('gray', 500);

		.status-error {
			color: $color-error;
		}
	}

	.check-icon {
		color: $color-success;
		margin-right: $gap-1;
	}

	.thumbnail-wrapper {
		position: relative;
		margin-right: $gap-15;
		width: 64px;
		height: 64px;
		flex-shrink: 0;
		border: 1px solid color('gray', 200);
		border-radius: $border-radius;
	}

	.thumbnail-delete {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: -8px;
		top: -6px;
		width: 20px;
		height: 20px;
		background-color: color('gray', 500);
		border-radius: 50%;
		cursor: pointer;
		z-index: 2;
	}

	.item-thumbnail {
		width: 63px;
		height: 63px;
		object-fit: contain;
		border-radius: $border-radius;
	}

	.item-name {
		font-weight: 600;
		font-size: $size-8;
	}
}
</style>
