import { withPopper, getObjectDiff } from '@/utilities/Helpers';
import CallAPI from '@/plugins/CallAPI';

export default {
	props: {
		apiPath: {
			type: String,
			required: true,
		},
		dataPath: {
			type: String,
			default: 'data',
		},
		paginationPath: {
			type: String,
			default: 'pagination',
		},
		trackBy: {
			type: String,
			default: 'id',
		},
		apiCaller: {
			type: String,
			default: 'x2x',
			validator: (value) => ['x2x', 'dwh'].indexOf(value) !== -1,
		},
		perPage: {
			type: Number,
			default: 10,
		},
		appendParams: {
			type: Object,
			default: () => {},
		},
		filters: {
			type: Array,
			default: () => [],
		},
		selectable: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			rows: [],
			serverParams: {
				skip: 0,
				take: 10,
				order: 'Name',
			},
			serverPagination: {},
			isLoading: false,
			itemsPerPageOptions: [
				10,
				25,
				50,
				75,
				100,
			],
		};
	},

	computed: {
		hasRows() {
			return this.rows.length > 0;
		},

		filterData() {
			return this.filters.filter((filter) => !!filter.transformedValue());
		},

		transformedFilters() {
			const transformedFilters = [];

			this.filters.forEach((filter) => {
				if (!Object.prototype.hasOwnProperty.call(filter, 'value')) {
					this.$set(filter, 'value', '');
				}

				transformedFilters.push(filter);
			});

			return transformedFilters;
		},

		filterParams() {
			const filterParams = this.filterData.reduce((newObj, filter) => {
				const value = filter.transformedValue();

				if (filter.filterBy) {
					newObj.filterBy = newObj.filterBy ? [...newObj.filterBy, filter.name] : [filter.name];
					newObj.filter = newObj.filter ? [...newObj.filter, value] : [value];
					return newObj;
				}

				newObj[filter.name] = value;
				return newObj;
			}, {});

			if (filterParams.filterBy) {
				filterParams.filterBy = filterParams.filterBy.join(',');
			}

			if (filterParams.filter) {
				filterParams.filter = filterParams.filter.join(',');
			}

			return filterParams;
		},
	},

	watch: {
		appendParams(newParams, oldParams) {
			if (Object.keys(getObjectDiff(newParams, oldParams)).length > 0) {
				this.serverParams.skip = 0;
				this.loadData();
			}
		},
	},

	methods: {
		withPopper,
		callAPI(...args) {
			if (this.apiCaller === 'x2x') {
				return CallAPI.callX2X(...args);
			}

			return CallAPI.callDWH(...args);
		},

		async loadData() {
			try {
				this.isLoading = true;

				const response = await this.callAPI({
					method: 'GET',
					path: this.apiPath,
					params: this.getAllQueryParams(),
				});

				this.rows = response.data[this.dataPath];
				this.serverPagination = response.data[this.paginationPath];
				this.serverParams.skip = this.serverPagination.From - 1;

				this.loadExternalData();

				this.$emit('load-success', response);
			} finally {
				this.isLoading = false;
			}
		},

		reloadData() {
			this.serverParams.skip = this.calculateSkipValue(1);
			this.loadData();
		},

		loadExternalData() {},

		calculateSkipValue(currentPage, take = this.serverParams.take) {
			return (currentPage - 1) * take;
		},

		onChangePage(page) {
			switch (page) {
				case 'next':
					if (this.serverPagination.CurrentPage < this.serverPagination.LastPage) {
						this.serverParams.skip = this.calculateSkipValue(this.serverPagination.CurrentPage + 1);
					}
					break;
				case 'prev':
					if (this.serverPagination.CurrentPage > 1) {
						this.serverParams.skip = this.calculateSkipValue(this.serverPagination.CurrentPage - 1);
					}
					break;
				default:
					this.serverParams.skip = this.calculateSkipValue(page);

					if (page > this.serverPagination.LastPage) {
						this.serverParams.skip = this.calculateSkipValue(this.serverPagination.LastPage);
					}

					if (page < 1) {
						this.serverParams.skip = this.calculateSkipValue(1);
					}
			}

			this.loadData();
		},

		onPerPageChange() {
			this.reloadData();
		},

		getAllQueryParams() {
			const params = {
				...this.serverParams,
				...this.filterParams,
				...this.appendParams,
			};

			if (this.getSortParam()) {
				params.order = this.getSortParam();
			}

			return params;
		},

		getSortParam() {
			if (!this.sortOrder || this.sortOrder.field === '') {
				return '';
			}
			if (typeof this.sortParams === 'function') {
				return this.sortParams(this.sortOrder);
			}
			return this.getDefaultSortParam();
		},

		getDefaultSortParam() {
			return this.sortOrder.map((item) => `${item.sortField} ${item.direction}`).join(',');
		},
	},
};
