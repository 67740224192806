import Creatives from '@/views/Creatives.vue';
import CreativeSingle from '@/views/CreativeSingle.vue';
import CreativeTypes from '@/utilities/CreativeTypes';

function getDynamicCreativeTypeRoutes() {
	return Object.values(CreativeTypes).map((creativeType) => ({
		path: `/creatives/${creativeType.getSlug()}`,
		name: creativeType.name,
		component: Creatives,
		props: {
			creativeType: creativeType.id,
		},
		meta: {
			title: () => creativeType.name,
			requiresAuth: true,
		},
	}));
}

const routes = [
	{
		path: '/creatives',
		name: 'creatives',
		redirect: { name: Object.values(CreativeTypes)[0].name },
	},
	...getDynamicCreativeTypeRoutes(),
	{
		path: '/creatives/:creativeId',
		name: 'creativeSingle',
		props: true,
		component: CreativeSingle,
		meta: {
			requiresAuth: true,
		},
	},
];

export default routes;
