import { createPopper } from '@popperjs/core';
import xor from 'lodash/xor';
import supportedImageFileFormats from '@/data/imageExtensions.json';
import supportedVideoFileFormats from '@/data/videoExtensions.json';

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function lowercaseFirstLetter(string) {
	return string.charAt(0).toLowerCase() + string.slice(1);
}

function withPopper(dropdownList, component, { width }) {
	dropdownList.style.width = width;

	const popper = createPopper(component.$refs.toggle, dropdownList, {
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 8],
				},
			},
			{
				name: 'toggleClass',
				enabled: true,
				phase: 'write',
				fn({ state }) {
					component.$el.classList.toggle('drop-up', state.placement === 'top');
				},
			}],
	});

	return () => popper.destroy();
}

function createURLQueryPath(path, params) {
	const paramsArray = Object.entries(params);
	const queryString = paramsArray.reduce((string, param) => {
		let newString = string;

		if (string) {
			newString += `&${param[0]}=${param[1]}`;
			return newString;
		}

		newString += `?${param[0]}=${param[1]}`;
		return newString;
	}, '');

	return `${path}${queryString}`;
}

function getObjectDiff(object1, object2) {
	const properties = [...new Set([...Object.keys(object1), ...Object.keys(object2)])];
	const changedProperties = properties.reduce((diff, key) => {
		if (Array.isArray(object2[key]) && xor(object2[key], object1[key]).length === 0) {
			return diff;
		}

		if (object2[key] === object1[key]) {
			return diff;
		}

		return {
			...diff,
			[key]: object1[key],
		};
	}, {});

	return changedProperties;
}

async function compareTimeAndWait(inputTime, compareTime, waitTime) {
	return new Promise((resolve) => {
		const difference = compareTime.diff(inputTime);

		if (difference < waitTime) {
			setTimeout(() => resolve(), waitTime - difference);
		} else {
			resolve();
		}
	});
}

async function asyncForEach(elements, callback) {
	/* eslint-disable */
	for (const [index, element] of elements.entries()) {
		await callback(element, index, elements);
	}
	/* eslint-enable */
}

export const getFileTypeByURL = (url) => {
	if (typeof url !== 'string') {
		return undefined;
	}

	const fileEnding = url.split('.').pop();

	if (supportedVideoFileFormats.includes(fileEnding)) {
		return 'video';
	}

	if (supportedImageFileFormats.includes(fileEnding)) {
		return 'image';
	}

	return undefined;
};

// find the greatest common divisor
export const gcd = (a, b) => ((b === 0) ? a : gcd(b, a % b));

export default capitalizeFirstLetter;
export {
	capitalizeFirstLetter, withPopper, createURLQueryPath, getObjectDiff, compareTimeAndWait, asyncForEach, lowercaseFirstLetter,
};
