import GeneralInfo from '@/components/BookCampaignSteps/GeneralInfo.vue';
import Creatives from '@/components/BookCampaignSteps/Creatives.vue';
import TargetOptions from '@/components/BookCampaignSteps/TargetOptions.vue';
import i18n from '@/plugins/i18n';

const keys = Object.freeze({
	GENERAL_INFO: 'general_info',
	CREATIVES_SELECTION: 'creatives_selection',
	TARGET_OPTIONS: 'target_options',
});

class BookCampaignStep {
	constructor(id, name, title, view) {
		this.id = id;
		this.name = name;
		this.title = title;
		this.view = view;
		this.state = 'untouched';
	}

	changeState(state) {
		this.state = state;
	}
}

const steps = () => [
	new BookCampaignStep(
		keys.GENERAL_INFO,
		i18n.t('bookCampaignStep.generalInfo.name'),
		i18n.t('bookCampaignStep.generalInfo.title'),
		GeneralInfo,
	),
	new BookCampaignStep(
		keys.CREATIVES_SELECTION,
		i18n.t('bookCampaignStep.creativesSelection.name'),
		i18n.t('bookCampaignStep.creativesSelection.title'),
		Creatives,
	),
	new BookCampaignStep(
		keys.TARGET_OPTIONS,
		i18n.t('bookCampaignStep.targetOptions.name'),
		i18n.t('bookCampaignStep.targetOptions.title'),
		TargetOptions,
	),
];

export { keys, steps };
