var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"validationProvider",attrs:{"mode":_vm.mode,"rules":{ required: _vm.required },"vid":_vm.vid,"name":_vm.labelObject.name,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"input-wrapper",class:Object.assign({}, _vm.wrapperClasses, classes)},[(_vm.label)?_c('label',{staticClass:"input-label",class:_vm.labelClasses,attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.labelObject.name)+" ")]):_vm._e(),_c('div',{staticClass:"slider-wrapper",class:{ 'is-rangeslider': _vm.isRangeSlider }},[(_vm.isRangeSlider)?_c('div',{staticClass:"slider-label"},[_vm._v(" "+_vm._s(_vm.min)+" ")]):_vm._e(),_c('VueSlider',_vm._g(_vm._b({attrs:{"tooltip":_vm.tooltip,"min":_vm.min,"max":_vm.max,"dot-size":22},scopedSlots:_vm._u([(_vm.isRangeSlider)?{key:"process",fn:function(ref){
var start = ref.start;
var end = ref.end;
var style = ref.style;
var index = ref.index;
return [_c('div',{staticClass:"vue-slider-process",style:(style)},[_c('div',{class:[
								'merge-tooltip' ]},[_vm._v(" "+_vm._s(_vm.value[index])+" - "+_vm._s(_vm.value[index + 1])+" ")])])]}}:null],null,true),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},'VueSlider',_vm.$attrs,false),_vm.$listeners)),(_vm.isRangeSlider)?_c('div',{staticClass:"slider-label"},[_vm._v(" "+_vm._s(_vm.max)+" ")]):_vm._e()],1),(_vm.$_showErrors(errors, classes))?_c('div',{staticClass:"input-message"},[_c('span',{attrs:{"data-cy":"error-message"}},[_vm._v(_vm._s(errors[0]))])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }