import Vue from 'vue';
import ModalNewFacebookLinkAd from '@/components/ModalNewFacebookLinkAd.vue';
import ModalNewGoogleDisplayAd from '@/components/ModalNewGoogleDisplayAd.vue';
import ModalNewVideoAd from '@/components/ModalNewVideoAd.vue';
import ModalNewCarouselAd from '@/components/ModalNewCarouselAd.vue';
import i18n from '@/plugins/i18n';

const createTypeModalMap = {
	LinkAd: ModalNewFacebookLinkAd,
	DisplayAd: ModalNewGoogleDisplayAd,
	VideoAd: ModalNewVideoAd,
	CarouselAd: ModalNewCarouselAd,
};

function getCreateModalComponentByCreativeType(creativeType) {
	return createTypeModalMap[creativeType];
}

function openCreateCreativeModal({
	creativeType,
	creative = null,
	onSuccess = () => {},
	onError = () => {},
	showSuccessNotification = true,
}) {
	const modalComponent = getCreateModalComponentByCreativeType(creativeType);

	if (!modalComponent) throw new Error('No valid creativeType');

	Vue.modal({
		component: getCreateModalComponentByCreativeType(creativeType),
		props: {
			creative,
		},
		events: {
			success: (newCreative) => {
				onSuccess(newCreative);

				if (showSuccessNotification) {
					if (creative) {
						Vue.notify({
							type: 'success',
							text: i18n.t('notification.success.updatedCreative'),
						});
					} else {
						Vue.notify({
							type: 'success',
							text: i18n.t('notification.success.addedNewCreative'),
						});
					}
				}
			},
			error: onError,
		},
	});
}

export default {
	methods: {
		$_openCreateCreativeModal: openCreateCreativeModal,
	},
};
