import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faStore } from '@fortawesome/pro-duotone-svg-icons/faStore';
import { faHomeAlt } from '@fortawesome/pro-regular-svg-icons/faHomeAlt';
import { faBullhorn } from '@fortawesome/pro-duotone-svg-icons/faBullhorn';
import { faImages } from '@fortawesome/pro-duotone-svg-icons/faImages';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faTachometerFast } from '@fortawesome/pro-duotone-svg-icons/faTachometerFast';

library.add(faCheck, faInfoCircle, faTimesCircle, faCheckCircle, faExclamationTriangle, faCog, faChevronRight, faChevronLeft, faPlusCircle, faTimes, faPen, faStore, faBullhorn, faImages, faTrash, faHomeAlt, faUser, faSearch, faTachometerFast);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
