import i18n from '@/plugins/i18n';
import {
	ICON_DASHBOARD, ICON_STORE, ICON_CAMPAIGN, ICON_CREATIVE,
} from '@/utilities/Icons';

class FirstLevelNavigationItem {
	constructor({ name, link, icon }) {
		this.name = name;
		this.link = link;
		this.icon = icon;
	}
}

const firstLevelNavigationItems = [
	new FirstLevelNavigationItem({
		name: i18n.t('navigation.label.dashboard'),
		link: { name: 'dashboard' },
		icon: ICON_DASHBOARD,
	}),
	new FirstLevelNavigationItem({
		name: i18n.t('navigation.label.stores'),
		link: { name: 'stores' },
		icon: ICON_STORE,
	}),
	new FirstLevelNavigationItem({
		name: i18n.t('navigation.label.campaigns'),
		link: { name: 'campaigns' },
		icon: ICON_CAMPAIGN,
	}),
	new FirstLevelNavigationItem({
		name: i18n.t('navigation.label.creatives'),
		link: { name: 'creatives' },
		icon: ICON_CREATIVE,
	}),
];

export { FirstLevelNavigationItem, firstLevelNavigationItems };
