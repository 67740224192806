export const campaignState = Object.freeze({
	PENDING: 'PENDING',
	ACTIVE: 'ACTIVE',
	FINISHED: 'FINISHED',
	PAUSED: 'PAUSED',
	UNKNOWN: 'UNKNOWN',
});

export const effectiveStateMap = Object.freeze({
	Paused: campaignState.PAUSED,
	Active: campaignState.ACTIVE,
	Scheduled: campaignState.PENDING,
	Completed: campaignState.FINISHED,
});

export const stateLabelMap = Object.freeze({
	[campaignState.PENDING]: 'Pending',
	[campaignState.ACTIVE]: 'Active',
	[campaignState.FINISHED]: 'Finished',
	[campaignState.PAUSED]: 'Paused',
	[campaignState.UNKNOWN]: 'n/a',
});

export function getCampaignState(campaign) {
	return effectiveStateMap[campaign.EffectiveState] || campaignState.UNKNOWN;
}
