<template>
	<div class="wrapper">
		<TheTopbar />
		<TheSidebar />
		<div class="main">
			<notifications
				position="top center"
				class="notifications--top"
				:width="600"
				style="top: 70px; left: calc(50% + (300px / 2)); transform: translateX(-50%)"
			>
				<template
					slot="body"
					slot-scope="props"
				>
					<NotificationItem
						:notification="props.item"
						:close="props.close"
						cypress-prefix="topNotification"
					/>
				</template>
			</notifications>
			<transition
				name="fade"
				mode="out-in"
			>
				<slot />
			</transition>
		</div>
	</div>
</template>

<script>
import TheTopbar from '@/components/TheTopbar.vue';
import TheSidebar from '@/components/TheSidebar.vue';
import NotificationItem from '@/components/NotificationItem.vue';

export default {
	name: 'LayoutDefault',
	components: {
		TheTopbar,
		TheSidebar,
		NotificationItem,
	},
};
</script>

<style lang="scss" scoped>
.main {
	padding-top: $gap-6 + 64px;
	padding-right: $gap-8;
	padding-bottom: $gap-8;
	padding-left: $gap-8 + 300px;
	background-color: color('gray', 100);
	min-height: 100vh;
	width: 100%;
	box-sizing: border-box;
}
</style>
