import { uuid } from 'uuidv4';
import ImageChecks from '@/utilities/ImageChecks';
import CreativeFile from './CreativeFile';
import { getFileTypeByURL } from './Helpers';

class ImageFile {
	constructor(file) {
		this.id = uuid();
		this.name = file.name;
		this.size = file.size;
		this.file = file;
		this.status = 'pending';
		this.imageURL = '';
		this.width = 0;
		this.height = 0;
		this.recommandations = {};
		this.requirements = {};

		this.getImageURL();
	}

	get type() {
		return getFileTypeByURL(this.name);
	}

	getImageURL() {
		if (this.imageURL) {
			return this.imageURL;
		}

		return new Promise((resolve) => {
			const imageReader = new FileReader();

			imageReader.onload = async () => {
				this.imageURL = imageReader.result;
				this.status = 'resolved';
				resolve(imageReader.result);
			};

			imageReader.readAsDataURL(this.file);
		});
	}

	getByteArray() {
		return new Promise((resolve) => {
			const byteArrayReader = new FileReader();

			byteArrayReader.onload = async () => {
				const bytes = new Uint8Array(byteArrayReader.result);
				resolve(bytes);
			};

			byteArrayReader.readAsArrayBuffer(this.file);
		});
	}

	async getImageDimensions() {
		const url = await this.getImageURL();
		const file = new CreativeFile(this.id, url, this.type);

		return file.getFileDimensions();
	}

	isPending() {
		return this.status === 'pending';
	}

	isResolved() {
		return this.status === 'resolved';
	}

	async addChecks(type, checks) {
		const imageDimensions = await this.getImageDimensions();
		const processedChecks = Object.keys(checks).reduce((newObject, key) => {
			newObject[key] = checks[key].reduce((result, check) => {
				if (result) {
					return result;
				}

				if (Object.keys(ImageChecks).includes(key)) {
					return ImageChecks[key].processor(check, imageDimensions);
				}

				return true;
			}, false);

			return newObject;
		}, {});
		this[type] = processedChecks;
	}

	hasAnyRecommandationFails() {
		return Object.keys(this.recommandations).some((key) => !this.recommandations[key]);
	}

	hasMinRecommandationFails() {
		if (this.recommandations.maxSizes && !this.recommandations.minSizes) {
			return true;
		}
		return false;
	}

	hasMaxRecommandationFails() {
		if (!this.recommandations.maxSizes && this.recommandations.minSizes) {
			return true;
		}
		return false;
	}

	hasAnyRequirementFails() {
		return Object.keys(this.requirements).some((key) => !this.requirements[key]);
	}
}

export default ImageFile;
