import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import LayoutDefault from '@/layouts/Default.vue';
import LayoutBlankAside from '@/layouts/BlankAside.vue';
import InlineSvg from 'vue-inline-svg';
import vSelect from 'vue-select';
import { SunButton } from '@sunlabde/sunlab-ui';

/**
 * Automatically add all .vue components starting with "App"
 */

const requireComponent = require.context(
	'@/components',
	false,
	/App[A-Z]\w+\.(vue|js)$/,
);

requireComponent.keys().forEach((fileName) => {
	const componentConfig = requireComponent(fileName);
	const componentName = upperFirst(
		camelCase(
			fileName
				.split('/')
				.pop()
				.replace(/\.\w+$/, ''),
		),
	);

	Vue.component(
		componentName,
		componentConfig.default || componentConfig,
	);
});

Vue.component('v-select', vSelect);
Vue.component('layout-default', LayoutDefault);
Vue.component('layout-blank-aside', LayoutBlankAside);
Vue.component('InlineSvg', InlineSvg);
Vue.component('SunButton', SunButton);
