import TableFieldStore from '@/components/TableFieldStore.vue';

export default [
	{
		field: 'Name',
		label: 'Title',
		component: TableFieldStore,
		placeholder: 'Placeholder Store Name',
	},
	{
		field: 'Zip',
		label: 'PLZ',
		placeholder: '12345',
	},
	{
		field: 'City',
		label: 'Location',
		placeholder: 'Placeholder Location',
	},
];
