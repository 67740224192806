import TableFieldStatusTag from '@/components/TableFieldStatusTag.vue';
import TableFieldDuration from '@/components/TableFieldDuration.vue';
import TableFieldCampaignNameWithCreativeType from '@/components/TableFieldCampaignNameWithCreativeType.vue';
import numeral from 'numeral';
import ENDPOINTS from '@/utilities/Endpoints';

export default [
	{
		field: 'CreativeType',
		label: 'Title',
		width: '18%',
		component: TableFieldCampaignNameWithCreativeType,
		source: {
			type: 'dwh',
			path: ENDPOINTS.Creatives,
			queryParams: {
				Id: ':CreativeLink_Id',
			},
		},
	},
	{
		field: 'State',
		label: 'Status',
		component: TableFieldStatusTag,
	},
	{
		field: '',
		label: 'Duration',
		component: TableFieldDuration,
		width: '14%',
	},
	{
		field: 'Impressions',
		label: 'Impressions',
		align: 'right',
		formatter: (value) => numeral(value).format(),
		source: {
			type: 'x2x',
			path: ENDPOINTS.CentralInsights,
			queryParams: {
				OrderLink_Id: ':Id',
				timeframe: 'alltime',
			},
		},
	},
	{
		field: 'UniqueDeltaReach',
		label: 'Reach',
		align: 'right',
		formatter: (value) => {
			if (value === null) {
				return '-';
			}

			return numeral(value).format();
		},
		source: {
			type: 'x2x',
			path: ENDPOINTS.CentralInsights,
			queryParams: {
				OrderLink_Id: ':Id',
				timeframe: 'alltime',
			},
		},
	},
	{
		field: 'Clicks',
		label: 'Link Clicks',
		align: 'right',
		formatter: (value) => numeral(value).format(),
		source: {
			type: 'x2x',
			path: ENDPOINTS.CentralInsights,
			queryParams: {
				OrderLink_Id: ':Id',
				timeframe: 'alltime',
			},
		},
	},
	{
		field: 'Ctr',
		label: 'Ctr',
		align: 'right',
		formatter: (value) => `${numeral(value).format('0.00')}%`,
		source: {
			type: 'x2x',
			path: ENDPOINTS.CentralInsights,
			queryParams: {
				OrderLink_Id: ':Id',
				timeframe: 'alltime',
			},
		},
	},
	{
		field: 'Cpm',
		label: 'Cpm',
		align: 'right',
		formatter: (value) => numeral(value).format('(0,0.00)$'),
		source: {
			type: 'x2x',
			path: ENDPOINTS.CentralInsights,
			queryParams: {
				OrderLink_Id: ':Id',
				timeframe: 'alltime',
			},
		},
	},
	{
		field: 'Cpc',
		label: 'Cpc',
		align: 'right',
		formatter: (value) => numeral(value).format('(0,0.00)$'),
		source: {
			type: 'x2x',
			path: ENDPOINTS.CentralInsights,
			queryParams: {
				OrderLink_Id: ':Id',
				timeframe: 'alltime',
			},
		},
	},
	{
		field: 'Cost',
		label: 'Amount spent',
		align: 'right',
		formatter: (value) => numeral(value).format('(0,0.00)$'),
		source: {
			type: 'x2x',
			path: ENDPOINTS.CentralInsights,
			queryParams: {
				OrderLink_Id: ':Id',
				timeframe: 'alltime',
			},
		},
	},
];
