<template>
	<div class="view">
		<div class="view-header has-gap-small">
			<div class="header-item">
				<SunButton
					color="gray"
					appearance="borderless"
					size="small"
					@click="$router.push({ name: 'campaigns' })"
				>
					<FontAwesomeIcon
						:icon="['far', 'chevron-left']"
						class="icon icon--before"
					/>
					{{ $t('button.back') }}
				</SunButton>
			</div>
		</div>
		<div class="view-body">
			<CampaignBox
				:campaign="fetchCampaign"
				:stores="fetchStores"
			/>
		</div>
	</div>
</template>

<script>
import ENDPOINTS from '@/utilities/Endpoints';
import CampaignBox from '@/components/CampaignBox.vue';
import CallAPI from '@/plugins/CallAPI';

export default {
	name: 'CampaignSingle',

	components: {
		CampaignBox,
	},

	props: {
		campaignId: {
			type: [Number, String],
			required: true,
		},
	},

	methods: {
		async fetchCampaign() {
			try {
				const response = await CallAPI.callDWH({
					method: 'GET',
					path: `${ENDPOINTS.Campaigns}/${this.campaignId}`,
				});

				if (response.data.Objects.length === 0) {
					throw new Error('campaign.not.found');
				}

				return response.data.Objects[0];
			} catch (error) {
				this.$router.push({ name: 'campaigns' });
				this.$notify({
					type: 'error',
					text: this.$t('notification.error.campaignNotFound'),
				});
				return {};
			}
		},
		async fetchStores() {
			const response = await CallAPI.callDWH({
				method: 'GET',
				path: ENDPOINTS.LocationBookingExport,
				params: {
					CampaignLink_Id: this.campaignId,
				},
			});

			return response.data.Objects;
		},
	},
};
</script>

<style lang="scss" scoped>

</style>
