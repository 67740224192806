export default {
	props: {
		row: {
			type: Object,
			required: false,
			default: () => ({}),
		},
		column: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},

	computed: {
		isPlaceholder() {
			const identifier = this.column.field ? this.column.field : this.column.label;
			return (this.row[identifier] === 'placeholder' || this.row[identifier] === this.column.placeholder);
		},
		table() {
			return this.$parent;
		},
	},
};
