<template>
	<transition
		name="modal"
		appear
		@after-leave="afterLeave"
	>
		<div
			v-show="showModal"
			ref="modal"
			:class="classObject"
			class="modal modal--boxed"
			data-cy="modal"
			@mousedown="onModalMouseDown"
		>
			<div class="modal-wrapper">
				<div
					ref="modalbox"
					class="modal-box"
					:class="modalBoxClassObject"
				>
					<div
						v-if="hasTitleSlot"
						class="modal-header"
					>
						<h2
							class="modal-title"
							data-cy="modal_title"
						>
							<slot name="title" />
						</h2>
					</div>
					<div class="modal-body">
						<div class="modal-main">
							<slot
								name="body"
								:close="close"
							/>
						</div>
						<div
							v-if="hasSidebarSlot && showSidebar"
							class="modal-sidebar"
						>
							<slot name="sidebar" />
						</div>
					</div>
					<div
						v-if="hasFooterSlot"
						class="modal-footer"
					>
						<slot
							:close="close"
							name="footer"
						/>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
	name: 'AppModal',

	directives: {
		ClickOutside,
	},

	props: {
		backgroundPath: {
			type: String,
			default: '',
		},
		allowBackdropClose: {
			type: Boolean,
			default: true,
		},
		contentAlign: {
			type: String,
			default: 'left',
			validator: (value) => ['left', 'center'].indexOf(value) !== -1,
		},
		beforeClose: {
			type: Function,
			default: () => (true),
		},
		beforeCancel: {
			type: Function,
			default: () => (true),
		},
		size: {
			type: String,
			default: '',
		},
		fixed: {
			type: Boolean,
			default: false,
		},
		showSidebar: {
			type: Boolean,
			default: false,
		},
		sidebarPosition: {
			type: String,
			default: 'right',
			validator: (value) => ['left', 'right'].includes(value),
		},
	},

	data() {
		return {
			showModal: true,
		};
	},

	computed: {
		hasTitleSlot() {
			return !!this.$scopedSlots.title;
		},
		hasFooterSlot() {
			return !!this.$scopedSlots.footer;
		},
		hasSidebarSlot() {
			return !!this.$scopedSlots.sidebar;
		},
		classObject() {
			return {
				'has-text-centered': this.contentAlign === 'center',
			};
		},
		modalBoxClassObject() {
			return {
				'is-wide': this.size === 'wide',
				'is-fixed': this.fixed,
				'has-sidebar': this.hasSidebarSlot,
				'has-sidebar-left': this.sidebarPosition === 'left',
				'has-sidebar-right': this.sidebarPosition === 'right',
			};
		},
	},

	mounted() {
	},

	methods: {
		async cancel() {
			const cancel = await this.beforeCancel();

			if (cancel) {
				this.close();
			}
		},

		async close(afterClose = null) {
			const close = await this.beforeClose();

			if (close) {
				this.showModal = false;
				this.$emit('close');

				if (typeof afterClose === 'function') {
					afterClose();
				}
			}
		},

		afterLeave() {
			this.$emit('afterClose');
		},

		onModalMouseDown(mouseDownEvent) {
			if (this.allowBackdropClose && !mouseDownEvent.path.includes(this.$refs.modalbox)) {
				this.$refs.modal.addEventListener('mouseup', (mouseUpEvent) => {
					if (!mouseUpEvent.path.includes(this.$refs.modalbox)) {
						this.cancel();
					}
				}, { once: true });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 200;
	background-color: rgba(0,0,0,0.7);

	.modal-wrapper {
		padding: $gap-4;
		max-height: 100vh;
		width: 100%;
		box-sizing: border-box;
		overflow: auto;
		z-index: 2;
	}

	.modal-box {
		position: relative;
		margin: 0 auto;
		z-index: 2;
		pointer-events: all;
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		text-align: center;
		margin-top: $gap-4;
	}

	.modal-header {
		box-sizing: border-box;
		border-bottom: 1px solid color('gray', 200);
		background-color: color('gray', 50);
		padding-left: $gap-4;
		padding-top: $gap-25;
		padding-bottom: $gap-25;
		border-top-right-radius: $border-radius;
		border-top-left-radius: $border-radius;
	}

	&.has-text-centered {
		text-align: center;
	}

	&.modal--boxed {
		.modal-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: $color-white;
			border-radius: $border-radius;
			max-width: 480px;
			width: 100%;
			min-height: 200px;
			animation: boxed-in 500ms $transition-bounce;
			box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);

			&.is-wide {
				max-width: 984px;
				width: 100%;
			}

			&.is-fixed {
				height: calc(100vh - (#{$gap-4 * 2}));
				display: flex;
				flex-direction: column;

				.modal-body {
					flex: 1;
					overflow: hidden;
				}

				.modal-main {
					overflow: auto;
				}
			}

			&.has-sidebar {
				.modal-body {
					display: flex;
				}

				.modal-main {
					flex: 1;
				}
			}

			&.has-sidebar-left {
				.modal-main {
					order: 2;
				}

				.modal-sidebar {
					border-left: 0;
					border-right: 1px solid color('gray', 200);
				}
			}
		}

		.modal-sidebar {
			display: flex;
			flex-direction: column;
			width: 320px;
			flex-shrink: 0;
			border-left: 1px solid color('gray', 200);
			background-color: color('gray', 50);
			z-index: 2;

			::v-deep .sidebar-header {
				padding: $gap-3 $gap-2;
			}

			::v-deep .sidebar-body {
				padding: $gap-4;
				flex: 1;
				overflow: auto;
			}

			::v-deep .sidebar-footer {
				padding: 0 $gap-4 $gap-4 $gap-4;
			}

			::v-deep .sidebar-title {
				font-weight: 600;
			}
		}

		.modal-header {
			padding: $gap-25 $gap-4;
			position: relative;
			z-index: 2;
		}

		.modal-footer {
			display: flex;
			justify-content: center;
			margin-top: 0;
			padding: $gap-2 $gap-4;
			border-bottom-left-radius: 2px;
			border-bottom-right-radius: 2px;
			box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
			position: relative;
			z-index: 2;
		}

		::v-deep .modal-title {
			font-size: $size-5;
			font-weight: 600;
		}

		.modal-main {
			padding: $gap-4;
			height: 100%;
		}

		.modal-body {
			::v-deep .modal-title {
				margin-bottom: $gap-1;
			}
		}

		.modal-background {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
		}
	}

	&.modal--seemless {
		.modal-title {
			color: $color-white;
			font-size: $size-3;
			text-align: center;
			width: percentage(8/12);
			margin-left: auto;
			margin-right: auto;
			margin-bottom: $gap-4;
		}

		.modal-body {
			max-width: 856px;
		}
	}

	&.modal-enter-active,
	&.modal-leave-active {
		transition: $transition-default;
	}

	&.modal-enter,
	&.modal-leave-to {
		opacity: 0;
	}
}

@keyframes boxed-in {
	0% {
		transform: scale(0.75);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}
</style>
