<template>
	<div class="view">
		<div class="view-header">
			<div class="header-item">
				<h1 class="view-title">
					<FontAwesomeIcon
						:icon="ICON_STORE"
						class="title-icon"
					/>
					{{ $t('view.stores.title') }}
				</h1>
				<p class="view-subtitle">
					{{ $t('view.stores.subtitle') }}
				</p>
			</div>
			<div class="header-item is-right">
				<div class="buttons is-row">
					<SunButton
						color="secondary"
						appearance="borderless"
						size="small"
						@click="addStore"
					>
						<FontAwesomeIcon
							:icon="['fal', 'plus-circle']"
							class="icon icon--before"
						/>
						{{ $t('button.addStore') }}
					</SunButton>
				</div>
			</div>
		</div>
		<div class="view-body">
			<AppTable
				ref="apptable"
				:columns="tableColumns"
				:api-path="ENDPOINTS.LocationImport"
				data-path="Objects"
				pagination-path="Pagination"
				api-caller="dwh"
				:show-pagination="true"
				:row-click-handler="rowClickHandler"
				:append-params="appendParams"
			/>
		</div>
	</div>
</template>

<script>
import DetailedStoresColumns from '@/utilities/DetailedStoresColumns';
import ENDPOINTS from '@/utilities/Endpoints';
import AddStoreMixin from '@/mixins/AddStoreMixin';
import { ICON_STORE } from '@/utilities/Icons';

export default {
	name: 'Stores',

	mixins: [AddStoreMixin],

	data() {
		return {
			tableColumns: DetailedStoresColumns,
			ENDPOINTS,
			ICON_STORE,
		};
	},
	computed: {
		appendParams() {
			return {
				predicates: 'State!=Deleted',
			};
		},
	},
	methods: {
		rowClickHandler(row) {
			this.$router.push({ name: 'storeSingle', params: { storeId: row.Id } });
		},

		addStore() {
			this.$_openCreateStoreModal({
				// onSuccess: this.onUpdateSuccess,
			});
		},

		// TODO: Uncomment these function when needed
		// onUpdateSuccess() {
		// 	console.log('OnUpdateSuccess!');
		// },
	},
};
</script>

<style lang="scss" scoped>
.title-icon {
	color: $color-stores;
}
</style>
