import { getFileTypeByURL } from './Helpers';

class CreativeImage {
	constructor(key, url) {
		this.key = key;
		this.imageURL = url;
		this.width = 0;
		this.height = 0;
		this.imageLoaded = false;
	}

	get type() {
		return getFileTypeByURL(this.imageURL);
	}

	async getImage() {
		const image = new Image();
		image.src = this.imageURL;

		return new Promise((resolve) => {
			image.addEventListener('load', () => {
				this.imageLoaded = true;
				resolve(image);
			});
		});
	}

	async getImageDimensions() {
		if (!(this.width && this.height)) {
			const image = await this.getImage();
			this.width = image.width;
			this.height = image.height;
		}

		return [this.width, this.height];
	}
}

export default CreativeImage;

export const fileToDWH = async (file) => {
	if (file instanceof CreativeImage) {
		return Promise.resolve(file.imageURL);
	}

	return file.getByteArray()
		.then((byteArray) => Array.from(byteArray));
};
