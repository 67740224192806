import axios from 'axios';

axios.defaults.headers.app_token = process.env.VUE_APP_API_APP_TOKEN;

class ApiCaller {
	constructor(baseUrl) {
		this.baseUrl = baseUrl;
	}

	async call({
		method = 'GET',
		path,
		params = {},
		data,
		silent = false,
	}) {
		try {
			const timestamp = Date.now();
			const response = await axios({
				method,
				url: `${this.baseUrl}${path}`,
				params: silent ? params : { ...params, timestamp },
				data,
			});

			return response;
		} catch (error) {
			if (error.response.status === 404) {
				this.vue.notify({
					duration: -1,
					type: 'error',
					title: 'Server Error',
					text: 'The requested ressource has not been found.',
				});
			}

			throw new Error(error);
		}
	}
}

export default {
	callDWH(...args) {
		const DWHCaller = new ApiCaller(process.env.VUE_APP_DWH_API_BASE_URL);
		return DWHCaller.call(...args);
	},
	callX2X(...args) {
		const X2XCaller = new ApiCaller(process.env.VUE_APP_X2X_API_BASE_URL);
		return X2XCaller.call(...args);
	},
};
