import { gcd } from './Helpers';

const ImageChecks = {
	sizes: {
		processor(recommandation, [width, height]) {
			if (width === recommandation[0] && height === recommandation[1]) {
				return recommandation;
			}

			return false;
		},
	},

	minSizes: {
		processor(recommandation, [width, height]) {
			if (width >= recommandation[0] && height >= recommandation[1]) {
				return recommandation;
			}

			return false;
		},
	},

	maxSizes: {
		processor(recommendation, [width, height]) {
			if (width <= recommendation[0] && height <= recommendation[1]) {
				return recommendation;
			}

			return false;
		},
	},

	ratio: {
		processor(recommandation, [width, height]) {
			const ratio = gcd(width, height);

			if (width / ratio === recommandation[0] && height / ratio === recommandation[1]) {
				return recommandation;
			}

			return false;
		},
	},
};

export default ImageChecks;
