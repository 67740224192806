import i18n from '@/plugins/i18n';
import callToActionOptions from '@/utilities/FacebookCallToActions';
import FacebookLogo from '@/assets/facebook-logo.svg';
import GoogleLogo from '@/assets/google-logo.svg';

class CreativeType {
	constructor({
		id, name, icon, label, fields,
	}) {
		this.id = id;
		this.name = name;
		this.icon = icon;
		this.label = label;
		this.fields = fields;
	}

	getSlug() {
		return this.id.split(/(?=[A-Z])/).join('-').toLowerCase();
	}
}

const creativeTypes = {
	LinkAd: new CreativeType({
		id: 'LinkAd',
		label: i18n.t('creatives.types.facebook.label'),
		icon: FacebookLogo,
		name: 'facebookLinkAdCreatives',
		fields: [
			{
				id: 'Text1',
				label: i18n.t('creatives.types.facebook.primaryCopy'),
			},
			{
				id: 'Text4',
				label: i18n.t('creatives.types.facebook.displayLink'),
			},
			{
				id: 'Title1',
				label: i18n.t('creatives.types.facebook.headline'),
			},
			{
				id: 'Text2',
				label: i18n.t('creatives.types.facebook.description'),
			},
			{
				id: 'Text3',
				label: i18n.t('creatives.types.facebook.callToAction'),
				formatter: (value) => {
					if (value) {
						const callToActionOption = callToActionOptions.find((option) => option.id === value);
						return callToActionOption ? callToActionOption.label : value;
					}

					return '';
				},
			},
		],
	}),
	VideoAd: new CreativeType({
		id: 'VideoAd',
		label: 'Facebook / Instagram Video Ad',
		icon: FacebookLogo,
		name: 'videoAd',
		fields: [
			{
				id: 'Name',
				label: i18n.t('creatives.types.displayAd.name'),
			},
			{
				id: 'Text1',
				label: i18n.t('creatives.types.displayAd.displayLink'),
			},
		],
	}),
	CarouselAd: new CreativeType({
		id: 'CarouselAd',
		label: 'Facebook / Instagram Carousel Ad',
		icon: FacebookLogo,
		name: 'carouselAd',
		fields: [
			{
				id: 'Name',
				label: i18n.t('creatives.types.carouselAd.name'),
			},
			{
				id: 'Message',
				label: i18n.t('creatives.types.facebook.primaryCopy'),
			},
		],
	}),
	DisplayAd: new CreativeType({
		id: 'DisplayAd',
		label: 'Google Display Ad Creatives',
		icon: GoogleLogo,
		name: 'googleDisplayAdCreatives',
		fields: [
			{
				id: 'Name',
				label: i18n.t('creatives.types.displayAd.name'),
			},
			{
				id: 'Text1',
				label: i18n.t('creatives.types.displayAd.displayLink'),
			},
		],
	}),
};

export default creativeTypes;
