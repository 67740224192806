<template>
	<div class="breadcrumbs">
		<p class="breadcrumbs-title">
			{{ $t('button.bookACampaign') }}
		</p>
		<div class="breadcrumbs-wrapper">
			<div class="breadcrumbs-timeline">
				<div
					class="timeline-progress"
					:style="{ height: progressInPercent }"
				/>
			</div>
			<div class="breadcrumbs-list">
				<div
					v-for="(step, $index) in steps"
					:key="step.id"
					class="list-item"
					:class="listItemClasses($index)"
					@click="goToStep($index)"
				>
					<span class="item-label">
						{{ $index + 1 }}. {{ step.name }}
					</span>
					<div
						v-if="isStepDone(step)"
						class="item-checkmark"
					>
						<FontAwesomeIcon :icon="['far', 'check']" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'SidebarBreadcrumbs',

	computed: {
		...mapGetters('book', [
			'currentStepIndex',
			'currentStepCount',
			'isInPreviewMode',
			'steps',
		]),
		progressInPercent() {
			if (this.currentStepIndex > this.steps.length) {
				return '100%';
			}

			return `${(this.currentStepIndex / (this.steps.length - 1)) * 100}%`;
		},
	},

	methods: {
		...mapActions('book', [
			'setStep',
		]),
		listItemClasses(stepIndex) {
			const isActive = this.isInPreviewMode ? false : stepIndex === this.currentStepIndex;
			const isDone = this.isStepDone(this.steps[stepIndex]);

			return {
				'is-active': isActive,
				'is-done': isDone,
				'is-clickable': isDone && !this.isInPreviewMode,
			};
		},
		isStepDone(step) {
			return step.state === 'done';
		},
		goToStep(stepIndex) {
			if (!this.isInPreviewMode && this.isStepDone(this.steps[stepIndex])) {
				this.setStep(stepIndex + 1);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.breadcrumbs-title {
	text-transform: uppercase;
	color: $color-white;
	opacity: 0.5;
	font-weight: 600;
	font-size: $size-8;
	margin-bottom: $gap-3;
}

.breadcrumbs-wrapper {
	display: flex;

	.breadcrumbs-list {
		flex: 1;
	}
}

.breadcrumbs-timeline {
	width: 4px;
	background-color: color('secondary', 800);
	border-radius: $border-radius;

	.timeline-progress {
		width: 100%;
		background-color: color('secondary', 400);
		transition: $transition-default;
		border-radius: $border-radius;
	}
}

.list-item {
	display: flex;
	padding: $gap-15 $gap-3;
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;

	.item-label {
		flex: 1;
		color: $color-white;
		opacity: 0.5;
		font-weight: 600;
	}

	.item-checkmark {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: color('primary', 400);
		animation: checkmark-in 375ms ease;
		color: $color-white;
		font-size: $size-9;
		padding-top: 1px;
		box-sizing: border-box;
	}

	&.is-active {
		background-color: color('secondary', 800);

		.item-label {
			opacity: 1;
		}
	}

	&.is-done {
		.item-label {
			opacity: 1;
		}
	}

	&.is-clickable {
		cursor: pointer;
	}
}

@keyframes checkmark-in {
	0% {
		opacity: 0;
		transform: translateX(10px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
</style>
