export default {
	methods: {
		onAfterClose() {
			this.$emit('afterClose');
		},

		cancel() {
			this.$refs.modal.cancel();
		},

		close() {
			this.$refs.modal.close();
		},

		cancelByBackdrop() {
			if (this.$refs.modal.allowBackdropClose) {
				this.$refs.modal.cancel();
			}
		},
	},
};
