<template>
	<div>
		<AppModal
			ref="modal"
			:allow-backdrop-close="false"
			@afterClose="onAfterClose"
		>
			>
			<template #title>
				{{ title }}
			</template>
			<template #body>
				<div v-html="text" />
				<slot />

				<div class="buttons is-row is-right has-gap-top-6">
					<SunButton
						color="gray"
						appearance="borderless"
						width="condensed"
						@click="cancel"
					>
						{{ $t('button.cancel') }}
					</SunButton>
					<SunButton
						width="condensed"
						@click="confirm"
					>
						{{ $t('button.confirm') }}
					</SunButton>
				</div>
			</template>
		</AppModal>
	</div>
</template>

<script>
import CustomModalMixin from '@/plugins/CustomModals/mixin';

export default {
	name: 'ModalConfirm',

	mixins: [CustomModalMixin],

	props: {
		title: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		onConfirm: {
			type: Function,
			required: false,
			default: () => {},
		},
		onCancel: {
			type: Function,
			required: false,
			default: () => {},
		},
	},

	methods: {
		async confirm() {
			try {
				await this.onConfirm();
			} catch (error) {
				this.$notify({
					type: 'error',
					text: error,
				});
			} finally {
				this.$refs.modal.close();
			}
		},

		async cancel() {
			try {
				await this.onCancel();
			} catch (error) {
				this.$notify({
					type: 'error',
					text: error,
				});
			} finally {
				this.$refs.modal.close();
			}
		},
	},
};
</script>

<style>

</style>
