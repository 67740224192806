<template>
	<div :class="classObject">
		<div
			class="checkbox"
			@click="onClick"
		>
			<FontAwesomeIcon
				:icon="['far', 'check']"
				class="checkbox-mark"
			/>
		</div>
		<label
			class="checkbox-label"
			@click="onClick"
		>
			<slot />
		</label>
	</div>
</template>

<script>
export default {
	name: 'AppCheckbox',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		classObject() {
			return {
				'checkbox-wrapper': true,
				'is-checked': this.value,
			};
		},
	},
	methods: {
		onClick() {
			this.$emit('input', !this.value);
			this.$emit('change', !this.value);
		},
	},
};
</script>

<style lang="scss" scoped>
.checkbox-group {
	.checkbox-wrapper:not(:last-child) {
		margin-bottom: $gap-2;
	}
}

.checkbox-wrapper {
	display: flex;
	align-items: center;

	&.is-checked {
		.checkbox {
			border-color: color('secondary', 500);
			background-color: color('secondary', 500);

			.checkbox-mark {
				opacity: 1;
				transform: translateY(0);
			}
		}

		.checkbox-label {
			color: color('gray', 700);
		}
	}
}

.checkbox-label {
	font-size: $size-8;
	color: color('gray', 500);
}

.checkbox-mark {
	opacity: 0;
	transform: translateY(5px);
	color: $color-white;
	height: 16px;
	width: 16px;
	transition: 375ms $transition-bounce;
}

.checkbox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: $border-radius;
	border: 1px solid color('gray', 200);
	margin-right: $gap-15;
	transition: $transition-default;
	flex-shrink: 0;

	&:hover {
		cursor: pointer;
		border-color: color('gray', 400);
		border-width: 2px;
	}
}
</style>
