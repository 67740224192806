<template>
	<AppCheckbox
		:value="isSelected"
		@change="onClick"
	/>
</template>

<script>
import TableFieldMixin from '@/mixins/TableFieldMixin';

export default {
	name: 'TableFieldCheckbox',

	mixins: [TableFieldMixin],

	computed: {
		isSelected() {
			return this.table.isRowSelected(this.row);
		},
	},

	methods: {
		onClick() {
			this.table.toggleRowSelection(this.row);
		},
	},
};
</script>

<style>

</style>
