<template>
	<div class="view">
		<div class="view-header">
			<div class="container">
				<p class="view-title">
					{{ $t('view.login.title') }}
				</p>
				<p class="view-subtitle">
					{{ $t('view.login.subtitle') }}
				</p>
			</div>
		</div>
		<div class="view-body">
			<div class="container">
				<form @submit.prevent="loginUser">
					<div class="form-body">
						<div class="input-fields is-small">
							<AppInput
								v-model="form.email"
								:placeholder="$t('input.placeholder.enterYourEmail')"
								type="text"
								size="large"
							/>
							<AppInput
								v-model="form.password"
								:placeholder="$t('input.placeholder.enterYourPassword')"
								type="password"
								size="large"
							/>
						</div>
						<div class="box-subactions">
							<AppCheckbox v-model="form.saveToken">
								{{ $t('input.label.stayLoggedIn') }}
							</AppCheckbox>
							<router-link
								v-if="FEATURE_PASSWORD_RESET_PROCESS"
								:to="{ name: 'passwordRequestReset' }"
								class="link"
							>
								{{ $t('link.forgotPassword') }}
							</router-link>
						</div>
					</div>
					<div class="form-footer">
						<SunButton
							:state="{ loading: waitingForAuthServer }"
							size="large"
							width="fullwidth"
						>
							{{ $t('button.login') }}
						</SunButton>
						<p
							v-show="formMessage"
							class="form-message"
						>
							{{ formMessage }}
						</p>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { FEATURE_PASSWORD_RESET_PROCESS } from '@/utilities/FeatureFlags';

export default {
	name: 'Login',

	data() {
		return {
			form: {
				email: '',
				password: '',
				saveToken: false,
			},
			waitingForAuthServer: false,
			formMessage: '',
			FEATURE_PASSWORD_RESET_PROCESS,
		};
	},

	watch: {
		form: {
			handler() {
				this.formMessage = '';
			},
			deep: true,
		},
	},

	methods: {
		...mapActions('auth', [
			'requestAuthentication',
		]),
		async loginUser() {
			try {
				this.waitingForAuthServer = true;
				this.formMessage = '';

				await this.requestAuthentication({
					userName: this.form.email,
					password: this.form.password,
					saveToken: this.form.saveToken,
				});

				this.$router.push({ name: 'dashboard' });
			} catch (error) {
				if (error.response) {
					this.formMessage = error.response.data.Message;
				} else {
					this.formMessage = this.$t('error.wrongLoginCredentials');
				}
			} finally {
				this.waitingForAuthServer = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.container {
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
}

.form-footer {
	margin-top: $gap-3;
}

.form-message {
	font-size: $size-8;
	color: $color-error;
	text-align: center;
	margin-top: $gap-1;
}

.box-subactions {
	display: flex;

	.link {
		margin-left: auto;
		color: color('secondary', 600);
		font-weight: 600;
		font-size: $size-8;
	}
}
</style>
