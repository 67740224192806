<template>
	<ValidationProvider
		ref="validationProvider"
		v-slot="{ errors, classes }"
		:mode="mode"
		:rules="{ required: required }"
		:vid="vid"
		:name="labelObject.name"
		slim
	>
		<div
			class="input-wrapper"
			:class="{ ...wrapperClasses, ...classes }"
		>
			<label
				v-if="label"
				for=""
				class="input-label"
				:class="labelClasses"
			>
				{{ labelObject.name }}
			</label>
			<v-select
				ref="select"
				v-model="selectValue"
				:class="inputClasses"
				:components="{ OpenIndicator }"
				:clearable="clearable"
				:style="{ width: width ? `${width}px` : '' }"
				:multiple="true"
				:clear-search-on-select="!closeOnSelect"
				:close-on-select="closeOnSelect"
				:label="optionLabel"
				:options="filteredOptions"
				v-bind="$attrs"
				@search:blur="onSearchBlur"
				@search:focus="onSearchFocus"
			>
				<template #selected-option-container>
					<div />
				</template>
				<template #search="{ attributes, events }">
					<input
						class="vs__search"
						v-bind="attributes"
						v-on="events"
					>
				</template>
			</v-select>
			<div
				v-if="$_showErrors(errors, classes)"
				class="input-message"
			>
				<span data-cy="error-message">{{ errors[0] }}</span>
			</div>
			<div
				v-show="selectValue.length > 0"
				class="tags"
			>
				<div
					v-for="$value in selectValue"
					:key="getTagName($value)"
					class="tag"
				>
					<slot
						:label="getTagName($value)"
						:remove="() => remove($value)"
						name="value"
					>
						{{ getTagName($value) }}
						<div class="tag-delete">
							<FontAwesomeIcon
								:icon="['far', 'chevron-right']"
								@click="remove($value)"
							/>
						</div>
					</slot>
				</div>
			</div>
		</div>
	</ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import InputMixin from '@/mixins/InputMixin';

export default {
	name: 'AppMultipleSelect',

	components: {
		ValidationProvider,
	},

	mixins: [InputMixin],

	inheritAttrs: false,

	props: {
		value: {
			type: Array,
			default: () => ([]),
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		closeOnSelect: {
			type: Boolean,
			default: true,
		},
		countLabel: {
			type: String,
			default: 'selected',
		},
		optionLabel: {
			type: String,
			default: 'label',
		},
		options: {
			type: Array,
			required: true,
		},
		vid: {
			type: String,
			default: '',
		},
		mode: {
			type: String,
			default: 'eager',
		},
		required: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			selectValue: '',
			OpenIndicator: {
				render: (createElement) => createElement('span', {
					class: { toggle: true },
					domProps: {
						innerHTML: `
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 7.4" style="enable-background:new 0 0 12 7.4;" xml:space="preserve">
							<path d="M10.6,0L6,4.6L1.4,0L0,1.4l6,6l6-6L10.6,0z"/>
						</svg>
						`,
					},
				}),
			},
		};
	},

	computed: {
		filteredOptions() {
			const selectedIds = this.value.map((option) => option.Id);
			const filteredOptions = this.options.filter((option) => !selectedIds.includes(option.Id));

			return filteredOptions;
		},
	},

	watch: {
		value: {
			immediate: true,
			handler(value) {
				this.selectValue = value;
			},
		},
		selectValue(selectValue) {
			if (!this.$refs.select.open) {
				this.setSearchValue();
			}

			this.$emit('input', selectValue);
		},
	},

	mounted() {
		this.setSearchValue();
	},

	methods: {
		remove(selectValue) {
			const index = this.selectValue.indexOf(selectValue);
			this.selectValue.splice(index, 1);
		},
		setSearchValue() {
			const selectedCount = this.selectValue.length;

			if (selectedCount > 0) {
				this.$refs.select.search = `${selectedCount} ${this.countLabel}`;
			} else {
				this.$refs.select.search = '';
			}
		},
		onSearchBlur() {
			this.setSearchValue();

			this.$refs.validationProvider.setFlags({
				untouched: false,
				touched: true,
			});

			this.$refs.validationProvider.validate();
		},
		onSearchFocus() {
			this.$refs.select.search = '';
		},
		getTagName(tag) {
			if (typeof tag === 'object') {
				return tag[this.optionLabel];
			}

			return tag;
		},
	},
};
</script>

<style lang="scss">
.tags {
	margin-top: $gap-2;
}
</style>
