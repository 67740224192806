<template>
	<div class="widget">
		<div class="widget-header">
			<div class="header-item">
				<p class="widget-title">
					<FontAwesomeIcon
						:icon="ICON_CAMPAIGN"
						class="title-icon"
					/>
					{{ $t('widget.activeCampaigns.title') }}
				</p>
			</div>
			<div class="header-item is-right">
				<AppInput
					v-model="searchByName"
					:placeholder="$t('input.placeholder.searchByName')"
					size="small"
					color="white-shadow"
					:width="200"
					:icon-after="{
						src: ['far', 'search']
					}"
					@input="loadTableData(500)"
				/>
			</div>
		</div>
		<div class="widget-body">
			<AppTable
				ref="table"
				:columns="tableColumns"
				:api-path="ENDPOINTS.Campaigns"
				data-path="Objects"
				pagination-path="Pagination"
				api-caller="dwh"
				:show-pagination="true"
				:row-click-handler="rowClickHandler"
				:append-params="appendParams"
				layout="fixed"
			>
				<template #table-footer>
					<div class="footer-item has-no-padding is-fullwidth is-centered">
						<SunButton
							color="gray"
							size="small"
							appearance="borderless"
							@click="$router.push({ name: 'campaigns' })"
						>
							{{ $t('button.seeAll') }}
						</SunButton>
					</div>
				</template>
			</AppTable>
		</div>
	</div>
</template>

<script>
import ENDPOINTS from '@/utilities/Endpoints';
import ReducedCampaignsColumns from '@/utilities/ReducedCampaignsColumns';
import { ICON_CAMPAIGN } from '@/utilities/Icons';

export default {
	name: 'WidgetActiveCampaigns',

	data() {
		return {
			tableColumns: ReducedCampaignsColumns,
			searchByName: '',
			loadDataTimeout: null,
			ENDPOINTS,
			ICON_CAMPAIGN,
		};
	},

	computed: {
		appendParams() {
			const appendParams = {
				order: 'CreationDate desc',
				EffectiveState: 'Active',
			};

			if (this.searchByName) appendParams.name = `%${this.searchByName}%`;

			return appendParams;
		},
	},

	methods: {
		rowClickHandler(row) {
			this.$router.push({ name: 'campaignSingle', params: { campaignId: row.Id } });
		},
		loadTableData(timeout = 0) {
			clearTimeout(this.loadDataTimeout);

			this.loadDataTimeout = setTimeout(() => {
				this.$refs.table.loadData();
			}, timeout);
		},
	},
};
</script>

<style lang="scss" scoped>
.title-icon {
	color: $color-campaign;
}
</style>
