import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import auth from './modules/auth';
import load from './modules/load';
import user from './modules/user';
import book from './modules/book';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		layout: 'default',
	},
	mutations: {
		SET_LAYOUT(state, payload) {
			state.layout = payload;
		},
	},
	actions: {
		setLayout({ commit }, layout) {
			if (!layout) {
				commit('SET_LAYOUT', 'default');
				return;
			}

			commit('SET_LAYOUT', layout);
		},
	},
	getters: {
		layout: (state) => (state.layout ? `layout-${state.layout}` : ''),
	},
	modules: {
		auth,
		load,
		user,
		book,
	},
});
