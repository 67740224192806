import { ValidationObserver } from 'vee-validate';
import BookCampaignHeader from '@/components/BookCampaignHeader.vue';
import BookCampaignFooter from '@/components/BookCampaignFooter.vue';

export default {
	props: {
		step: {
			type: Object,
			required: true,
		},
	},

	components: {
		ValidationObserver,
		BookCampaignHeader,
		BookCampaignFooter,
	},
};
