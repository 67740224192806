<template>
	<VDatePicker
		v-model="inputValue"
		:popover="{
			visibility: 'focus'
		}"
		:masks="{
			L: 'DD.MM.YYYY',
		}"
		:update-on-input="false"
		:min-date="minDate"
		:first-day-of-week="2"
		:mode="mode"
		@popoverWillShow="onPopoverWillShow"
		@popoverWillHide="onPopoverWillHide"
	>
		<AppInput
			ref="input"
			slot-scope="{ inputProps, inputEvents }"
			:label="label"
			v-bind="inputProps"
			color="gray"
			:placeholder="placeholder"
			class="has-glow"
			:rules="{ required }"
			mode="change"
			emit-type="event"
			:disabled="disabled"
			v-on="inputEvents"
			@blur="onBlur"
		/>
	</VDatePicker>
</template>

<script>
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
	name: 'DateInput',

	components: {
		VDatePicker,
	},

	props: {
		value: {
			type: [Date, String, Object],
			required: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: 'Date',
		},
		minDate: {
			type: Date,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: String,
			default: 'single',
		},
	},

	data() {
		return {
			inputValue: '',
			popoverVisible: false,
		};
	},

	computed: {
		placeholder() {
			if (this.mode === 'range') {
				return 'dd.mm.yyyy - dd.mm.yyyy';
			}

			return 'dd.mm.yyyy';
		},
	},

	watch: {
		value: {
			immediate: true,
			handler(value) {
				this.inputValue = value;
			},
		},
		inputValue(inputValue) {
			this.$emit('input', inputValue);
		},
	},

	methods: {
		onPopoverWillShow() {
			this.popoverVisible = true;
		},
		onPopoverWillHide() {
			this.popoverVisible = false;
			this.$refs.input.$refs.validationProvider.validate();
		},
		onBlur() {
			if (!this.popoverVisible) {
				this.$refs.input.$refs.validationProvider.validate();
			}
		},
	},
};
</script>

<style>

</style>
