var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDatePicker',{attrs:{"popover":{
		visibility: 'focus'
	},"masks":{
		L: 'DD.MM.YYYY',
	},"update-on-input":false,"min-date":_vm.minDate,"first-day-of-week":2,"mode":_vm.mode},on:{"popoverWillShow":_vm.onPopoverWillShow,"popoverWillHide":_vm.onPopoverWillHide},scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var inputProps = ref.inputProps;
	var inputEvents = ref.inputEvents;
return _c('AppInput',_vm._g(_vm._b({ref:"input",staticClass:"has-glow",attrs:{"label":_vm.label,"color":"gray","placeholder":_vm.placeholder,"rules":{ required: _vm.required },"mode":"change","emit-type":"event","disabled":_vm.disabled},on:{"blur":_vm.onBlur}},'AppInput',inputProps,false),inputEvents))}}]),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }