export default {
	props: {
		label: {
			type: [String, Object],
			default: '',
			validator: (value) => {
				if (typeof value === 'string') {
					return true;
				}

				if (value.name) {
					return true;
				}

				return false;
			},
		},
		size: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: '',
		},
		width: {
			type: Number,
			default: 0,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: '',
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: Object,
			default: () => ({}),
		},
	},

	computed: {
		wrapperClasses() {
			return {
				'has-label-left': this.labelObject.position === 'left',
				'is-small': this.size === 'small',
				'is-large': this.size === 'large',
				'has-fixed-width': this.width > 0,
			};
		},
		inputClasses() {
			return {
				'is-small': this.size === 'small',
				'is-large': this.size === 'large',
				'is-white': this.color === 'white' || this.color === 'white-shadow',
				'has-shadow': this.color === 'white-shadow',
			};
		},
		labelClasses() {
			return {
			};
		},
		labelObject() {
			if (typeof this.label === 'string') {
				return {
					name: this.label,
				};
			}

			return this.label;
		},
		hasTooltip() {
			return Object.keys(this.tooltip).length > 0;
		},
	},

	methods: {
		$_showErrors(errors, classes) {
			const hasErrors = errors.length > 0;

			if (this.mode === 'aggressive-touch') {
				return hasErrors && classes.touched;
			}

			return hasErrors;
		},
	},
};
