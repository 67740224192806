<template>
	<div class="field-wrapper">
		<ProgressiveImage
			class="creative-type"
			:src="icon"
			width="18"
			height="18"
		/>
		<strong v-placeholder-loader>{{ name }}</strong>
	</div>
</template>

<script>
import TableFieldMixin from '@/mixins/TableFieldMixin';
import CreativeTypes from '@/utilities/CreativeTypes';
import ProgressiveImage from '@/components/ProgressiveImage.vue';

export default {
	name: 'TableFieldCampaignNameWithCreativeType',

	components: {
		ProgressiveImage,
	},

	mixins: [TableFieldMixin],

	computed: {
		creativeType() {
			return CreativeTypes[this.row.CreativeType] || null;
		},
		icon() {
			return this.creativeType ? this.creativeType.icon : '';
		},
		name() {
			return this.row.Name || '';
		},
	},
};
</script>

<style lang="scss" scoped>
.field-wrapper {
	display: flex;
	align-items: center;

	.creative-type {
		width: 18px;
		height: 18px;
		margin-right: $gap-1;
	}
}
</style>
