<template>
	<ValidationProvider
		ref="validationProvider"
		v-slot="{ errors, classes }"
		:mode="mode"
		:rules="{ required: required }"
		:vid="vid"
		:name="labelObject.name"
		slim
	>
		<div
			class="input-wrapper"
			:class="{ ...wrapperClasses, ...classes }"
		>
			<label
				v-if="label"
				for=""
				class="input-label"
				:class="labelClasses"
			>
				{{ labelObject.name }}
			</label>
			<v-select
				v-model="selectValue"
				:class="inputClasses"
				:components="{ OpenIndicator }"
				:searchable="searchable"
				:clearable="clearable"
				:style="{ width: width ? `${width}px` : '' }"
				:reduce="reduce"
				:label="optionLabel"
				v-bind="$attrs"
				@search:blur="onBlur"
			/>
			<div
				v-if="$_showErrors(errors, classes)"
				class="input-message"
			>
				<span data-cy="error-message">{{ errors[0] }}</span>
			</div>
		</div>
	</ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import InputMixin from '@/mixins/InputMixin';

export default {
	name: 'AppSelect',

	components: {
		ValidationProvider,
	},

	mixins: [InputMixin],

	inheritAttrs: false,

	props: {
		value: {
			type: [String, Number],
			required: false,
			default: '',
		},
		searchable: {
			type: Boolean,
			required: false,
			default: false,
		},
		clearable: {
			type: Boolean,
			required: false,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		vid: {
			type: String,
			default: '',
		},
		mode: {
			type: String,
			default: 'eager',
		},
		optionLabel: {
			type: String,
			default: 'name',
		},
		optionValue: {
			type: String,
			default: 'value',
		},
	},

	data() {
		return {
			selectValue: '',
			OpenIndicator: {
				render: (createElement) => createElement('span', {
					class: { toggle: true },
					domProps: {
						innerHTML: `
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 7.4" style="enable-background:new 0 0 12 7.4;" xml:space="preserve">
							<path d="M10.6,0L6,4.6L1.4,0L0,1.4l6,6l6-6L10.6,0z"/>
						</svg>
						`,
					},
				}),
			},
		};
	},

	watch: {
		value: {
			immediate: true,
			handler(value) {
				this.selectValue = value;
			},
		},
		selectValue(selectValue) {
			this.$emit('input', selectValue);
		},
	},

	methods: {
		reduce(option) {
			if (typeof option === 'object') {
				return this.optionValue ? option[this.optionValue] : option;
			}

			return option;
		},
		onBlur() {
			this.$refs.validationProvider.setFlags({
				untouched: false,
				touched: true,
			});

			this.$refs.validationProvider.validate();
		},
	},
};
</script>

<style lang="scss">

</style>
