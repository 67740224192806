<template>
	<div class="wrapper">
		<div class="sidebar">
			<div class="top-box">
				<img src="@/assets/logo-localup-dark.svg">
			</div>
			<div class="middle-box">
				<p class="sidebar-title">
					<span class="highlight">Lokales Online Marketing</span> - skalierbar und automatisiert
				</p>
			</div>
			<div class="bottom-box">
				<p class="copyright">
					Copyright LocalUp 2020
				</p>
			</div>
		</div>
		<div class="main">
			<slot />
		</div>
	</div>
</template>

<script>

export default {
	name: 'LayoutBlankAside',
	components: {
	},
};
</script>

<style lang="scss" scoped>
.main {
	padding-top: $gap-6 + 64px;
	padding-right: $gap-8;
	padding-bottom: $gap-8;
	padding-left: $gap-8 + 460px;
	min-height: 100vh;
	width: 100%;
	box-sizing: border-box;
}

.sidebar {
	position: fixed;
	height: 100vh;
	width: 460px;
	background-color: color('secondary', 900);
	padding: $gap-3 $gap-5;
	box-sizing: border-box;
	color: $color-white;
	display: flex;
	flex-direction: column;
	background-image: url('../assets/sidebar-background.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	.top-box {
		flex: 1;
	}

	.bottom-box {
		margin-top: $gap-1 * 12.5;
	}

	.copyright {
		font-size: $size-9;
	}

	.sidebar-title {
		font-size: $size-4;
		font-weight: 700;
		line-height: 1.2;

		.highlight {
			color: color('primary', 300);
		}
	}
}
</style>
